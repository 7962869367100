/* eslint-disable react-hooks/exhaustive-deps */
import { ICashWithdrawalRequest } from '@cr/general/dist/interfaces'
import * as React from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { Row, Button } from 'antd'

import MainLayout from '../../components/layout'
import Actions from '../../store/actions'
import { ReduxState } from '../../store/reducers'
import IWithdrawalState from '../../interfaces/states/withdrawal'
import WithdrawalRequest from './withdrawal-request'
import WithdrawalRequestDetail from './withdrawal-request-detail'
import IBreadcrumbItem from '../../interfaces/breadcrumb-item'
import OptionStatus from '../../components/option-status'
import SUMMARY_WITHDRAWAL_STATUS from '../../constants/summary-withdrawal-status'

interface IMainProps {
  GetWithdrawalList: (status: string, page?: number) => void
  GetWithdrawalDetail: (id: string) => void
  VerifyWithdrawal: (id: number, data: ICashWithdrawalRequest) => Promise<any>
  withdrawal: IWithdrawalState
}

interface IParams {
  id?: string | undefined
}

const WithdrawalListPage = (props: IMainProps) => {
  const {
    GetWithdrawalList,
    GetWithdrawalDetail,
    VerifyWithdrawal,
    withdrawal
  } = props
  const history = useHistory()
  const qs: any = parse(useLocation().search.toString())
  const params: IParams = useParams()

  const [status, setStatus] = React.useState<string | undefined>(undefined)

  React.useEffect(() => {
    setStatus(undefined)
  }, [])

  React.useEffect(() => {
    setStatus(qs.status)
  }, [qs.status])

  React.useEffect(() => {
    GetWithdrawalList(status || SUMMARY_WITHDRAWAL_STATUS.PENDING)
  }, [status])

  React.useEffect(() => {
    if (params.id) GetWithdrawalDetail(params.id)
  }, [])

  const breadcrumbs: Array<IBreadcrumbItem> = [
    {
      label: 'Withdrawal Request',
      href: '/withdrawals'
    },
    {
      label: 'Withdrawal Details'
    }
  ]

  if (params.id)
    return (
      <MainLayout breadcrumbs={breadcrumbs}>
        <WithdrawalRequestDetail
          withdrawal={withdrawal}
          onVerify={(val) => {
            VerifyWithdrawal(val.id, {
              reason: val.reason,
              status: val.status
            } as ICashWithdrawalRequest).then(
              () => params.id && GetWithdrawalDetail(params.id)
            )
          }}
        />
      </MainLayout>
    )

  return (
    <MainLayout header='Withdrawal'>
      <div className='withdrawal'>
        <Row>
          <OptionStatus
            count={
              withdrawal.groupedList[SUMMARY_WITHDRAWAL_STATUS.PENDING]?.paging
                ?.totalData!
            }
            isPending={
              status &&
              ![
                SUMMARY_WITHDRAWAL_STATUS.PENDING,
                SUMMARY_WITHDRAWAL_STATUS.TRANSFERRING
              ].includes(status as any)
                ? undefined
                : (status || SUMMARY_WITHDRAWAL_STATUS.PENDING) ===
                  SUMMARY_WITHDRAWAL_STATUS.PENDING
            }
            onPending={() =>
              history.push(
                `/withdrawals/?status=${SUMMARY_WITHDRAWAL_STATUS.PENDING}`
              )
            }
            onComplete={() =>
              history.push(
                `/withdrawals/?status=${SUMMARY_WITHDRAWAL_STATUS.TRANSFERRING}`
              )
            }
            completeText='Transferring'
          />
          <Button
            className='ml-1'
            type={
              status === SUMMARY_WITHDRAWAL_STATUS.FAILED ? 'primary' : 'ghost'
            }
            onClick={() =>
              history.push(
                `/withdrawals/?status=${SUMMARY_WITHDRAWAL_STATUS.FAILED}`
              )
            }
          >
            Failed
          </Button>
          <Button
            className='ml-1'
            type={
              status === SUMMARY_WITHDRAWAL_STATUS.COMPLETED
                ? 'primary'
                : 'ghost'
            }
            onClick={() =>
              history.push(
                `/withdrawals/?status=${SUMMARY_WITHDRAWAL_STATUS.COMPLETED}`
              )
            }
          >
            Completed
          </Button>
        </Row>
        <WithdrawalRequest
          onVerify={(val) => {
            VerifyWithdrawal(val.id, {
              reason: val.reason,
              status: val.status
            } as ICashWithdrawalRequest).then(() =>
              GetWithdrawalList(status || SUMMARY_WITHDRAWAL_STATUS.PENDING)
            )
          }}
          withdrawal={withdrawal}
          status={status!}
          onChangePage={(pn) =>
            GetWithdrawalList(status || SUMMARY_WITHDRAWAL_STATUS.PENDING, pn)
          }
          withAction={
            (status || SUMMARY_WITHDRAWAL_STATUS.PENDING) ===
            SUMMARY_WITHDRAWAL_STATUS.PENDING
          }
        />
      </div>
    </MainLayout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  withdrawal: state.withdrawal
})

const mapDispatchToProps = (dispatch: any) => ({
  GetWithdrawalList: (status: string, page?: number) =>
    dispatch(Actions.Withdrawal.GetList(status, page)),
  GetWithdrawalDetail: (id: string) =>
    dispatch(Actions.Withdrawal.GetDetail(id)),
  VerifyWithdrawal: (id: number, data: ICashWithdrawalRequest) =>
    dispatch(Actions.Withdrawal.Action(id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalListPage)
