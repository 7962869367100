import {
  IAgencyFee,
  IPartnershipConfig,
  IPartnershipCreatorEmailPayload,
  IPartnership
} from '@cr/general/dist/interfaces'
import Base from './base'

export default {
  GetListPartnership: (page?: number, limit?: number): Promise<any> => {
    return Base('/account/v1').get(
      `admin/partnerships/?page=${page || 1}&limit=${limit || 10}`
    )
  },
  GetDetailPartnership: (id: number): Promise<any> => {
    return Base('/account/v1').get(`admin/partnerships/${id}`)
  },
  UpdatePartnership: (id: number, data: IPartnership): Promise<any> => {
    return Base('/account/v1').put(`admin/partnerships/${id}`, data)
  },
  Create: (data: IAgencyFee): Promise<any> => {
    return Base('/account/v1').post(`admin/agencies`, data)
  },
  CreatePartnerShipConfig: (
    data: Array<IPartnershipConfig>,
    id: string
  ): Promise<any> => {
    return Base('/account/v1').post(`admin/partnerships/${id}/configs`, data)
  },
  CreatePartnerShip: (data: IPartnership): Promise<any> => {
    return Base('/account/v1').post(`admin/partnerships`, data)
  },
  CreateCreatorEmail: (
    data: IPartnershipCreatorEmailPayload,
    id: number | undefined
  ): Promise<any> => {
    return Base('/account/v1').post(`admin/partnerships/${id}/users`, data)
  },
  Update: (id: string, data: IAgencyFee): Promise<any> => {
    return Base('/account/v1').put(`admin/agencies/${id}`, data)
  },
  DeletePartnership: (id: number | undefined): Promise<any> => {
    return Base('/account/v1').delete(`admin/partnerships/${id}`)
  }
}
