import React from 'react'
import { Row, Typography, TableColumnProps, Button, Col } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'

import GeneralParticle from '@attn/general'
import { IChallenge } from '@cr/general/dist/interfaces'
import { capitalize } from 'lodash'

const { Text } = Typography
const { Numberize } = GeneralParticle.Utils.Format

const ChallengeColumn = [
  {
    title: 'Hypes Name',
    dataIndex: 'name',
    key: 'name',
    render: (_text: any, c: IChallenge) => (
      <>
        <Row wrap={false} align='middle'>
          <Col>
            <Avatar size={48} shape='square' src={c.content?.image} />
          </Col>
          <Col>
            <Text className='ml-2 text-weight-bold text-size-16'>
              {c?.name}
            </Text>
          </Col>
        </Row>
      </>
    )
  },
  {
    title: 'Platform',
    dataIndex: 'challengeTypeId',
    key: 'challengeTypeId',
    render: (_text: any, c: IChallenge) => (
      <Text className='text-size-16'>{capitalize(c.platform?.name)}</Text>
    )
  },
  {
    title: 'Need Response',
    dataIndex: 'participant',
    key: 'participant',
    render: (_text: any, c: IChallenge) => (
      <Text className='text-size-16'>
        {Numberize(c?.totalNeedResponses || 0, '.')}
      </Text>
    )
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'action',
    render: (_text: any, c: IChallenge) => {
      return (
        <Button type='link' href={`/qc-hypes/${c.id}`}>
          <Text
            underline
            className='text-size-16 text-weight-medium text-color-primary-purple'
          >
            See Details
          </Text>
        </Button>
      )
    }
  }
] as Array<TableColumnProps<any>>

export default ChallengeColumn
