/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Col, Input, Row, Switch, Select, Form } from 'antd'
const { Option } = Select
const { Text } = Typography

import React from 'react'

export interface ISwitchInput {
  isActive: boolean
  name?: string
  type?: string
  initialValues?: any
  options?: Array<string>
  value: any
  onChangeValue: (index: string, val: any) => void
  onChangeSwitch: (index: string, isActive: boolean) => void
  code: string
  error?: boolean
  useSwitch?: boolean
}

const SwitchInput = ({
  isActive,
  name,
  type,
  initialValues,
  options = [],
  value,
  code,
  onChangeSwitch,
  onChangeValue,
  error,
  useSwitch
}: ISwitchInput) => {
  const renderInput = (type) => {
    switch (type) {
      case 'none':
        return (
          <Row className='mb--5' align='middle'>
            {name}
          </Row>
        )
      case 'dropdown':
        return (
          <Select
            disabled={!isActive}
            className='w-100'
            onChange={(val) => {
              onChangeValue(code, val)
            }}
            value={value}
            placeholder={'Pilih ' + name?.toLowerCase()}
            allowClear
          >
            {options.map((x, index) => (
              <Option key={index + ''} value={x}>
                {x}
              </Option>
            ))}
          </Select>
        )
      case 'free-text':
        return (
          <Input
            height={40}
            bordered={true}
            placeholder={'Tuliskan ' + name?.toLowerCase()}
            disabled={!isActive}
            defaultValue={initialValues}
            value={value}
            onChange={(event) => {
              onChangeValue(code, event.target.value || '')
            }}
          />
        )
      case 'link':
        return (
          <>
            {error ? (
              <Row>
                <Text className='text-color-danger-main'>
                  Mohon masukkan url yang valid
                </Text>
              </Row>
            ) : (
              <></>
            )}
            <Row>
              <Input
                height={40}
                bordered={true}
                placeholder={'Tuliskan ' + name?.toLowerCase()}
                disabled={!isActive}
                defaultValue={initialValues}
                value={value}
                onChange={(event) => {
                  onChangeValue(code, event.target.value || '')
                }}
              />
            </Row>
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <div className='pv-1'>
      <Row className='mb--5' align='middle' style={{ minHeight: 20 }}>
        <Col offset={6}>
          <Row className='ml-2' align='middle'>
            {type === 'none' ? <></> : name}
          </Row>
        </Col>
      </Row>
      <Row align='middle'>
        <Col span={6} xs={6}>
          <Row align='middle' justify='end'>
            {useSwitch ? (
              <>
                <Text className='mr-1'>{isActive ? 'Aktif' : 'Nonaktif'}</Text>
                <Switch
                  checked={isActive}
                  onChange={(newVal) => onChangeSwitch(code, newVal)}
                />
              </>
            ) : (
              <></>
            )}
          </Row>
        </Col>
        <Col span={17} xs={17}>
          <Row className='ml-2' align='middle'>
            <Form.Item
              className='w-100'
              style={{ height: 8 }}
              validateStatus={error ? 'error' : ''}
            >
              {renderInput(type)}
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default SwitchInput
