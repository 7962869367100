import { IResult } from '@attn/general/dist/interfaces/api'
import VERIFY_STATUS from '@cr/general/dist/constants/verify-status'
import { IUser } from '@cr/general/dist/interfaces'
import { IUserBank, IIdCardInfo } from '@cr/general/dist/interfaces/user'
import { message } from 'antd'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../constants/action-types'
import {
  USER_UPDATE_MODE,
  USER_MODE,
  USER_MODE_MAP
} from '../../constants/user-mode'
import Api from '../apis'
import Base from './base'

const GetList =
  (
    status?: string,
    mode?: USER_MODE,
    page?: number,
    limit?: number,
    search?: string
  ) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.User.GetList(status, mode, page, limit, search),
      () => {
        dispatch(
          action(ActionTypes.User.USER_GET_LIST_REQUEST, {
            status: `${mode}|${status}`
          })
        )
      },
      (res: IResult<Array<IUser>>) => {
        dispatch(
          action(ActionTypes.User.USER_GET_LIST_SUCCESS, {
            list: res.data,
            paging: res.paging,
            status: `${mode}|${status}`
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.User.USER_GET_LIST_FAILED, {
            error,
            status: `${mode}|${status}`
          })
        )
    )
  }

const Action =
  (id: number, data: IUserBank | IIdCardInfo, mode: USER_UPDATE_MODE) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.User.Action(id, data, mode),
      () => {
        dispatch(action(ActionTypes.User.USER_ACTION_REQUEST))
      },
      () => {
        dispatch(
          action(ActionTypes.User.USER_ACTION_SUCCESS, {
            id,
            status: `${USER_MODE_MAP[mode]}|${data.status}`
          })
        )
        message.info(
          `Application was ${VERIFY_STATUS[data.status].toLowerCase()}`
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.User.USER_ACTION_FAILED, {
            error
          })
        )
    )
  }

const GetDetailBalanceLog =
  (id: string, page?: number, limit?: number) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.User.GetDetailBalanceLog(id, page, limit),
      () => {
        dispatch(action(ActionTypes.User.USER_GET_LIST_BALANCE_LOG_REQUEST))
      },
      (res: IResult<Array<IUser>>) => {
        dispatch(
          action(ActionTypes.User.USER_GET_LIST_BALANCE_LOG_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.User.USER_GET_LIST_BALANCE_LOG_FAILED, {
            error
          })
        )
    )
  }

const GetDetailByUsername =
  (username: string) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.User.GetDetailByUsername(username),
      () => {
        dispatch(action(ActionTypes.User.USER_GET_DETAIL_REQUEST))
      },
      (res: IResult<Array<IUser>>) => {
        dispatch(
          action(ActionTypes.User.USER_GET_DETAIL_SUCCESS, {
            data: res.data
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.User.USER_GET_DETAIL_FAILED, {
            error
          })
        )
    )
  }

export default {
  GetList,
  GetDetailBalanceLog,
  GetDetailByUsername,
  Action
}
