import { Col, Form, Input, Row, Typography } from 'antd'
import React from 'react'

const { Text } = Typography

const AgencyDetail = () => {
  return (
    <div className={'mt-2'}>
      <div className={'mb-1'}>
        <Text className='text-size-24 text-weight-bold'>Agency Detail</Text>
      </div>
      <Row gutter={[16, 16]} className='mb-1'>
        <Col span={12}>
          <Form.Item
            label={'Agency Email'}
            name='email'
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Agency email is not valid email.'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Agency Bank Name'}
            name='bankName'
            rules={[
              {
                pattern: /^[a-zA-Z0-9\s]+$/,
                message:
                  'Agency bank name can only include letters and numbers.'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='mb-1'>
        <Col span={12}>
          <Form.Item
            label={'Agency Name'}
            name='name'
            rules={[
              {
                required: true,
                message: 'Agency name is required.'
              },
              {
                pattern: /^[a-zA-Z0-9\s]+$/,
                message: 'Agency name can only include letters and numbers.'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={'Agency Bank Account'}
            name='bankAccount'
            rules={[
              {
                pattern: /^[0-9]{1,20}$/,
                message:
                  'Agency bank name can only include numbers and maximum 20 character.'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default AgencyDetail
