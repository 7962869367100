import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import BaseStateDefault from '../../constants/state'
import IChallengeState from '../../interfaces/states/challenge'

const initialState: IChallengeState = {
  ...BaseStateDefault,
  challengeRules: [],
  groupedList: {}
}

const reducer: Reducer<any> = (
  state: IChallengeState = initialState,
  action
): IChallengeState => {
  switch (action.type) {
    case ActionTypes.Challenge.CHALLENGE_GET_LIST_REQUEST: {
      const { status } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: true }
      }
      state.groupedList[status] = {
        ...state.groupedList[status],
        requesting: true,
        error: undefined
      }
      return {
        ...state
      }
    }
    case ActionTypes.Challenge.CHALLENGE_GET_DETAIL_REQUEST:
    case ActionTypes.Challenge.CHALLENGE_CREATE_REQUEST:
    case ActionTypes.Challenge.CHALLENGE_RULES_REQUEST:
    case ActionTypes.Challenge.CHALLENGE_UPDATE_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.Challenge.CHALLENGE_GET_DETAIL_SUCCESS:
    case ActionTypes.Challenge.CHALLENGE_CREATE_SUCCESS:
    case ActionTypes.Challenge.CHALLENGE_UPDATE_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        requesting: false
      }
    }
    case ActionTypes.Challenge.CHALLENGE_GET_LIST_SUCCESS: {
      const { list, paging, status } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: false }
      }
      state.groupedList[status] = {
        ...state.groupedList[status],
        requesting: false,
        list:
          paging.currentPage === 1
            ? list
            : [...(state.groupedList[status].list || []), ...list],
        paging
      }

      return {
        ...state
      }
    }
    case ActionTypes.Challenge.CHALLENGE_GET_LIST_FAILED: {
      const { status, error } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: false }
      }
      state.groupedList[status] = {
        ...state.groupedList[status],
        requesting: false,
        error: error
      }
      return {
        ...state
      }
    }
    case ActionTypes.Challenge.CHALLENGE_RULES_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        requesting: false,
        challengeRules: data
      }
    }
    case ActionTypes.Challenge.CHALLENGE_GET_DETAIL_FAILED:
    case ActionTypes.Challenge.CHALLENGE_RULES_FAILED: {
      return {
        ...state,
        list: [],
        data: undefined,
        challengeRules: [],
        requesting: false,
        error: action.payload.error
      }
    }
    case ActionTypes.Challenge.CHALLENGE_CREATE_FAILED:
    case ActionTypes.Challenge.CHALLENGE_UPDATE_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
