const CHALLENGE_GET_LIST_REQUEST = 'CHALLENGE_GET_LIST_REQUEST'
const CHALLENGE_GET_LIST_SUCCESS = 'CHALLENGE_GET_LIST_SUCCESS'
const CHALLENGE_GET_LIST_FAILED = 'CHALLENGE_GET_LIST_FAILED'

const CHALLENGE_GET_DETAIL_REQUEST = 'CHALLENGE_GET_DETAIL_REQUEST'
const CHALLENGE_GET_DETAIL_SUCCESS = 'CHALLENGE_GET_DETAIL_SUCCESS'
const CHALLENGE_GET_DETAIL_FAILED = 'CHALLENGE_GET_DETAIL_FAILED'

const CHALLENGE_CREATE_REQUEST = 'CHALLENGE_CREATE_REQUEST'
const CHALLENGE_CREATE_SUCCESS = 'CHALLENGE_CREATE_SUCCESS'
const CHALLENGE_CREATE_FAILED = 'CHALLENGE_CREATE_FAILED'

const CHALLENGE_UPDATE_REQUEST = 'CHALLENGE_UPDATE_REQUEST'
const CHALLENGE_UPDATE_SUCCESS = 'CHALLENGE_UPDATE_SUCCESS'
const CHALLENGE_UPDATE_FAILED = 'CHALLENGE_UPDATE_FAILED'

const CHALLENGE_RULES_REQUEST = 'CHALLENGE_RULES_REQUEST'
const CHALLENGE_RULES_SUCCESS = 'CHALLENGE_RULES_SUCCESS'
const CHALLENGE_RULES_FAILED = 'CHALLENGE_RULES_FAILED'

export default {
  CHALLENGE_GET_LIST_REQUEST,
  CHALLENGE_GET_LIST_SUCCESS,
  CHALLENGE_GET_LIST_FAILED,

  CHALLENGE_GET_DETAIL_REQUEST,
  CHALLENGE_GET_DETAIL_SUCCESS,
  CHALLENGE_GET_DETAIL_FAILED,

  CHALLENGE_CREATE_REQUEST,
  CHALLENGE_CREATE_SUCCESS,
  CHALLENGE_CREATE_FAILED,

  CHALLENGE_UPDATE_REQUEST,
  CHALLENGE_UPDATE_SUCCESS,
  CHALLENGE_UPDATE_FAILED,

  CHALLENGE_RULES_REQUEST,
  CHALLENGE_RULES_SUCCESS,
  CHALLENGE_RULES_FAILED
}
