import VERIFY_STATUS from '@cr/general/dist/constants/verify-status'
import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import BaseStateDefault from '../../constants/state'
import IUserState from '../../interfaces/states/user'

const initialState: IUserState = {
  ...BaseStateDefault,
  groupedList: {}
}

const reducer: Reducer<any> = (
  state: IUserState = initialState,
  action
): IUserState => {
  switch (action.type) {
    case ActionTypes.User.USER_GET_LIST_REQUEST: {
      const { status } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: true }
      }
      state.groupedList[status] = {
        ...state.groupedList[status],
        requesting: true,
        error: undefined
      }
      return {
        ...state
      }
    }
    case ActionTypes.User.USER_GET_LIST_BALANCE_LOG_REQUEST:
    case ActionTypes.User.USER_ACTION_REQUEST:
    case ActionTypes.User.USER_GET_DETAIL_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.User.USER_ACTION_SUCCESS: {
      const { id, status } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: false }
      }
      const splittedCurrentStatus = status.split('|')
      const prevStatus = `${splittedCurrentStatus[0]}|${VERIFY_STATUS.PENDING}`

      state.groupedList[prevStatus] = {
        ...state.groupedList[prevStatus],
        list: state.groupedList[prevStatus].list?.filter((x) => x.id !== id)
      }

      return {
        ...state,
        requesting: false
      }
    }
    case ActionTypes.User.USER_GET_LIST_SUCCESS: {
      const { list, paging, status } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: false }
      }
      state.groupedList[status] = {
        ...state.groupedList[status],
        requesting: false,
        list,
        paging
      }

      return {
        ...state
      }
    }
    case ActionTypes.User.USER_GET_LIST_FAILED: {
      const { status, error } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: false }
      }
      state.groupedList[status] = {
        ...state.groupedList[status],
        requesting: false,
        error: error
      }
      return {
        ...state
      }
    }
    case ActionTypes.User.USER_GET_LIST_BALANCE_LOG_SUCCESS: {
      const { list, paging } = action.payload
      return {
        ...state,
        balanceLog: list,
        paging,
        requesting: false,
        error: undefined
      }
    }
    case ActionTypes.User.USER_GET_DETAIL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        requesting: false,
        error: undefined
      }
    }
    case ActionTypes.User.USER_GET_LIST_BALANCE_LOG_FAILED:
    case ActionTypes.User.USER_ACTION_FAILED:
    case ActionTypes.User.USER_GET_DETAIL_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
