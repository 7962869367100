import { IUserChallengeSubmission } from '@cr/general/dist/interfaces'
import Base from './base'

export default {
  GetListByChallengeId: (
    challengeId: string,
    status: string,
    page?: number,
    limit?: number,
    sort?: string,
    order?: 'asc' | 'desc',
    createdAtFrom?: number,
    createdAtUntil?: number
  ): Promise<any> => {
    return Base('/challenge/v1').get(
      `admin/challenges/${challengeId}/submissions/?page=${page || 1}&limit=${
        limit || 16
      }${status ? `&qcStatuses=${status}` : ''}${sort ? `&sort=${sort}` : ''}${
        order ? `&order=${order}` : ''
      }${
        createdAtFrom && createdAtUntil
          ? `&createdAtFrom=${createdAtFrom}&createdAtUntil=${createdAtUntil}`
          : ''
      }`
    )
  },
  Verify: (
    challengeId: string,
    submissionId: number,
    data: IUserChallengeSubmission
  ): Promise<any> => {
    return Base('/challenge/v1').put(
      `admin/challenges/${challengeId}/submissions/${submissionId}`,
      data
    )
  }
}
