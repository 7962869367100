/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { IChallenge } from '@cr/general/dist/interfaces'
import {
  PlusCircleFilled,
  CloseOutlined,
  DownOutlined,
  UpOutlined
} from '@ant-design/icons'
import {
  Row,
  Form,
  Input,
  Col,
  Typography,
  Button,
  InputNumber,
  Select,
  Divider,
  DatePicker,
  Switch,
  Upload,
  Image,
  Modal,
  Collapse
} from 'antd'
import General from '@attn/general'
import CHALLENGE_TYPE from '@cr/general/dist/constants/challenge-type'
import { capitalize } from 'lodash'
import moment from 'moment'

import IChallengeState from '../../interfaces/states/challenge'
import ENV from '../../utils/environment'
import { createObjectUrl, protocolRemover } from '../../utils'
import CustomEditor from '../../components/custom-editor'
import { CRAddImage, CRAddVideo } from '../../components/icons'
import { isDateLessThan, isZeroValue } from '../../utils/form-validator'
import PLATFORM_TYPE from '@cr/general/dist/constants/platform-type'
import MEDIA_TYPE from '@cr/general/dist/constants/media-type'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import SwitchInput from '../../components/form/switchInput'
import CHALLENGE_RULE_KIND from '@cr/general/dist/constants/challenge-rule-kind'
import IChallengeRule from '@cr/general/dist/interfaces/challenge-rule'
import CHALLENGE_RULE_TYPE from '@cr/general/dist/constants/challenge-rule-type'
import { makeId, isUrl } from '../../utils'

const { Text } = Typography
const { Numberize } = General.Utils.Format
const { Option } = Select

interface IProps {
  challenge: IChallengeState
  onSubmit: (values: IChallenge) => void
}

const isRuleAlwaysActive = (ruleCode: string) => {
  const ruleActive = ['platform']
  return !!ruleActive.find((rule) => rule === ruleCode)
}

const helperValuationType = {
  [CHALLENGE_TYPE.HASHTAG]:
    'Please type a special hashtag for the conditions to participate in Hypes',
  [CHALLENGE_TYPE.MUSIC]:
    'Please type a special music ID for the conditions to participate in Hypes',
  [CHALLENGE_TYPE.DUET]:
    'Please type a special hashtag and music ID for the conditions to participate in Hypes'
}

const HypeForm = (props: IProps) => {
  const { challenge, onSubmit } = props
  const [form] = Form.useForm()
  const [name, setName] = useState<string>(form.getFieldValue('name') || '')
  const [challengeRules, setChallengeRules] = useState<Array<IChallengeRule>>(
    []
  )
  const [openModalNewRule, setOpenModalNewRule] =
    useState<CHALLENGE_RULE_KIND | null>(null)
  const [formNewRule] = Form.useForm()
  const [challengeRuleError, setChallengeRuleError] = useState<Array<string>>(
    []
  )
  const [isActive, setIsActive] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)
  const [newRuleSubmitDisabled, setNewRuleSubmitDisabled] =
    useState<boolean>(true)

  useEffect(() => {
    form.setFieldsValue({
      ...challenge.data,
      challengeValue: challenge.data?.challengeValue?.replaceAll(';', ' '),
      startAt: challenge.data?.startAt
        ? moment.unix(challenge.data?.startAt)
        : null,
      endAt: challenge.data?.endAt ? moment.unix(challenge.data?.endAt) : null
    })
  }, [challenge.data])

  useEffect(() => {
    let newChallengeRules = challenge.challengeRules.map((cR) => {
      const challengeRule: IChallengeRule = {
        challengeId: challenge?.data?.id || 0,
        rule: cR,
        ruleId: cR.id,
        isActive: isRuleAlwaysActive(cR.code || '')
      } as IChallengeRule

      return challengeRule
    })

    // populate current rule setting of this hypes
    if (challenge?.data?.id && challenge?.data?.challengeRules?.length) {
      const mapCodeRule = {}
      for (let i = 0; i < challenge?.data?.challengeRules?.length; i++) {
        const challengeRule = challenge?.data?.challengeRules[i]
        mapCodeRule[challengeRule?.rule?.code || ''] = challengeRule
      }

      newChallengeRules = newChallengeRules.map(
        (nCR) => mapCodeRule[nCR?.rule?.code || '']
      )
    }

    setChallengeRules(newChallengeRules)
  }, [challenge.challengeRules, challenge.data])

  const GenerateChallengeType = () => {
    return Object.values(CHALLENGE_TYPE)
      .filter((x) => typeof x === 'number' && x > 0)
      .map((x) => ({ id: x, name: capitalize(CHALLENGE_TYPE[x]) }))
  }

  const GeneratePlatformType = () => {
    return Object.values(PLATFORM_TYPE)
      .filter((x) => typeof x === 'number' && x > 0)
      .map((x) => ({ id: x, name: capitalize(PLATFORM_TYPE[x]) }))
  }

  const GenerateMediaType = () => {
    return Object.values(MEDIA_TYPE)
      .filter((x) => typeof x === 'number' && x > 0)
      .map((x) => ({ id: x, name: capitalize(MEDIA_TYPE[x]) }))
  }

  const GenerateRuleType = () => {
    return Object.values(CHALLENGE_RULE_TYPE)
      .filter(
        (x) =>
          x !== CHALLENGE_RULE_TYPE.RULE_TYPE_NONE &&
          x !== CHALLENGE_RULE_TYPE.RULE_TYPE_DROPDOWN
      )
      .map((x) => ({ id: x, name: capitalize(x).replace('-', ' ') }))
  }

  const getContent = (field?: 'image' | 'video' | 'steps' | 'requirements') => {
    if (field) return form.getFieldValue(['content', field])
    return form.getFieldValue(['content'])
  }

  const onChangeSwitch = (code: string, isActive: boolean) => {
    const newChallengeRules = challengeRules.map((nCR) =>
      nCR.rule?.code === code
        ? {
            ...nCR,
            isActive: !!isActive
          }
        : nCR
    )
    setChallengeRules([...newChallengeRules])
  }

  const onChangeValue = (code: string, value: string) => {
    const newChallengeRules = challengeRules
      .filter((nCR) => nCR)
      .map((nCR) =>
        nCR.rule?.code === code
          ? {
              ...nCR,
              value
            }
          : nCR
      )
    setChallengeRules([...newChallengeRules])
  }

  const ChallengeRuleSwitches = (kind: CHALLENGE_RULE_KIND) => (
    <Row className='mb-2'>
      {(challengeRules.filter((cR) => cR?.rule?.kind === kind) || []).map(
        (cR, index) => {
          const code = cR.rule?.code || ''
          return (
            <Col
              key={index}
              span={24}
              sm={12}
              style={{
                marginRight: cR?.rule?.code === 'must-buy-product' ? '100%' : 0
              }}
              className='challenge-rule-section-switch-input-container'
            >
              <SwitchInput
                useSwitch={!isRuleAlwaysActive(cR?.rule?.code || '')}
                error={!!challengeRuleError.find((cRE) => cRE === code)}
                isActive={cR.isActive || false}
                name={cR?.rule?.content.name}
                type={cR?.rule?.type}
                value={cR.value}
                onChangeSwitch={onChangeSwitch}
                onChangeValue={onChangeValue}
                code={code}
                options={cR?.rule?.content.option?.map((opt) => opt.value)}
              />
            </Col>
          )
        }
      )}
      <Col
        span={24}
        sm={12}
        className='challenge-rule-section-switch-input-container'
      >
        <Row align='middle' className='h-100'>
          <Col offset={6} span={17} xs={17}>
            <Row className='ml-2' align='middle'>
              <Button
                onClick={() => setOpenModalNewRule(kind)}
                type='ghost'
                className='w-100 border-none text-size-16 text-weight-medium text-color-primary-purple bg-primary-surface'
              >
                <PlusCircleFilled color={'#0D43FB'} />
                <Text className='text-weight-medium text-color-primary-purple'>
                  Tambah Ketentuan
                </Text>
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )

  return (
    <Row className='hype-form' justify='center'>
      <Col span={18}>
        <Form
          onValuesChange={() => {
            let disableSubmitTmp = true
            if (
              !!form.getFieldValue('name') &&
              !!form.getFieldValue('challengeTypeId') &&
              !!getContent('video') &&
              !!getContent('image') &&
              !!getContent('steps') &&
              !!form.getFieldValue('slug') &&
              !!form.getFieldValue('budget') &&
              !!form.getFieldValue('platformId') &&
              !!form.getFieldValue('challengeTypeId') &&
              !!form.getFieldValue('mediaTypeId') &&
              !!form.getFieldValue('challengeValue') &&
              !!form.getFieldValue('startAt') &&
              !!form.getFieldValue('endAt') &&
              !!form.getFieldValue('sort')
            ) {
              disableSubmitTmp = false
            }
            setDisableSubmit(disableSubmitTmp)
          }}
          name='hype-form'
          form={form}
          onFinish={(values) => {
            Modal.confirm({
              title: 'Are you sure want to posting?',
              onOk: () => {
                setChallengeRuleError([])
                // validation rule
                const ruleCodeThatError: Array<string> = []
                const usedRule = challengeRules.filter((cR) => cR.isActive)
                if (usedRule.length) {
                  for (let i = 0; i < usedRule.length; i++) {
                    const cR = usedRule[i]
                    if (cR.rule?.type === CHALLENGE_RULE_TYPE.RULE_TYPE_LINK) {
                      if (!isUrl(cR.value)) {
                        ruleCodeThatError.push(cR.rule?.code || '')
                      }
                    }
                    if (
                      cR.rule?.type ===
                        CHALLENGE_RULE_TYPE.RULE_TYPE_DROPDOWN ||
                      cR.rule?.type === CHALLENGE_RULE_TYPE.RULE_TYPE_FREE_TEXT
                    ) {
                      if (!cR.value) {
                        ruleCodeThatError.push(cR.rule?.code || '')
                      }
                    }
                  }
                }

                if (ruleCodeThatError.length) {
                  setChallengeRuleError(ruleCodeThatError)
                  return
                }
                onSubmit({
                  ...challenge.data,
                  ...values,
                  remainingBudget:
                    challenge.data?.remainingBudget === challenge.data?.budget
                      ? values.budget
                      : challenge.data?.remainingBudget,
                  challengeValue: values.challengeValue?.replaceAll(' ', ';'),
                  startAt: moment(values.startAt).unix(),
                  endAt: moment(values.endAt).unix(),
                  challengeRules
                })
              },
              onCancel: () => null
            })
          }}
          requiredMark={false}
        >
          <Form.Item
            name='name'
            label='Hypes Name'
            className='input-hype-name'
            labelCol={{ span: 24 }}
            rules={[{ required: true }]}
          >
            <Input
              bordered={false}
              placeholder='Enter Hypes Name'
              maxLength={75}
              onChange={(event) => setName(event.target.value)}
            />
          </Form.Item>
          <Text>{`(${name.length}/75)`}</Text>
          <Row gutter={[32, 0]} className='mt-2'>
            <Col span={12}>
              <Row gutter={[32, 0]} align='middle'>
                <Col span={24} className='mb-2'>
                  <Text>Thumbnail Image</Text>
                </Col>
                <Col>
                  <Form.Item shouldUpdate noStyle>
                    {() => {
                      return getContent('image') ? (
                        <div className='media-container'>
                          <Button
                            size='small'
                            className='btn-delete-media'
                            shape='circle'
                            onClick={() => {
                              form.setFieldsValue({
                                content: {
                                  ...getContent(),
                                  image: undefined
                                }
                              })
                            }}
                          >
                            <CloseOutlined />
                          </Button>
                          <Image
                            src={createObjectUrl(getContent('image'))}
                            width={100}
                            height={100}
                            preview={false}
                          />
                        </div>
                      ) : (
                        <Upload
                          onChange={({ file }) =>
                            form.setFieldsValue({
                              content: {
                                ...getContent(),
                                image: file.originFileObj
                              }
                            })
                          }
                          multiple={false}
                          showUploadList={false}
                          accept='.jpg,.png'
                        >
                          <Row align='middle'>
                            <CRAddImage />
                            <Button
                              type='text'
                              className='cursor-pointer text-color-primary-purple text-weight-medium ph--25'
                            >
                              Browse Image
                            </Button>
                          </Row>
                        </Upload>
                      )
                    }}
                  </Form.Item>
                  <Form.Item
                    name={['content', 'image']}
                    rules={[{ required: true, message: 'image is required' }]}
                    className='input-hidden'
                  >
                    <Input style={{ display: 'none' }} />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item shouldUpdate noStyle>
                    {() => <Text>{getContent('image')?.name}</Text>}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={[32, 0]} align='middle'>
                <Col span={24} className='mb-2'>
                  <Text>Video Hypes</Text>
                </Col>
                <Col>
                  <Form.Item shouldUpdate noStyle>
                    {() => {
                      return getContent('video') ? (
                        <div className='media-container'>
                          <Button
                            size='small'
                            className='btn-delete-media'
                            shape='circle'
                            onClick={() => {
                              form.setFieldsValue({
                                content: {
                                  ...getContent(),
                                  video: undefined
                                }
                              })
                            }}
                          >
                            <CloseOutlined />
                          </Button>
                          <video
                            src={createObjectUrl(getContent('video'))}
                            width={100}
                            height={100}
                            controls
                          />
                        </div>
                      ) : (
                        <Row align='middle'>
                          <CRAddVideo />
                          <Upload
                            onChange={({ file }) =>
                              form.setFieldsValue({
                                content: {
                                  ...getContent(),
                                  video: file.originFileObj
                                }
                              })
                            }
                            multiple={false}
                            showUploadList={false}
                            accept='video/*'
                          >
                            <Button
                              type='text'
                              className='cursor-pointer text-color-primary-purple text-weight-medium ph--25'
                            >
                              Browse Video
                            </Button>
                          </Upload>
                        </Row>
                      )
                    }}
                  </Form.Item>
                  <Form.Item
                    name={['content', 'video']}
                    rules={[{ required: true, message: 'video is required' }]}
                    className='input-hidden'
                  >
                    <Input style={{ display: 'none' }} />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item shouldUpdate noStyle>
                    {() => <Text>{getContent('video')?.name}</Text>}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} md={12}>
              <Row align='middle' className='input-path mb-3'>
                <Col span={24}>
                  <Text>URL Path</Text>
                </Col>
                <Col span={24}>
                  <Row align='top' style={{ width: '100%' }}>
                    <Text
                      className='mr--5 text-color-second-dark-purple'
                      style={{ marginTop: 5 }}
                    >{`${protocolRemover(ENV.CR_FRAGMENT_URL)}hypes/`}</Text>
                    <Col flex='1 1'>
                      <Form.Item
                        name='slug'
                        rules={[
                          { required: true },
                          {
                            pattern: new RegExp('^[a-z0-9]+(?:-[a-z0-9]+)*$'),
                            message: 'wrong format'
                          }
                        ]}
                      >
                        <Input placeholder='hype-name' />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name='budget'
                label='Total Prize'
                labelCol={{ span: 24 }}
                rules={[isZeroValue()]}
              >
                <InputNumber
                  min={0}
                  style={{ width: '100%' }}
                  disabled={
                    challenge.data?.remainingBudget !== challenge.data?.budget
                  }
                  formatter={(value?: any) =>
                    `${value ? Numberize(value, '.', 'Rp ') : 0}`
                  }
                  parser={(value) =>
                    value
                      ? parseInt(
                          value.replace('Rp ', '').replaceAll('.', ''),
                          10
                        )
                      : 0
                  }
                  onKeyPress={(e) => {
                    if (isNaN(parseInt(e.key))) {
                      e.preventDefault()
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[32, 0]} className='mb-3'>
            <Col span={8}>
              <Form.Item
                name='platformId'
                label='Platform'
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: 'platform is required' }]}
              >
                <Select
                  placeholder='Select platform'
                  allowClear
                  disabled={
                    challenge.data?.remainingBudget !== challenge.data?.budget
                  }
                >
                  {GeneratePlatformType().map((x) => (
                    <Option key={x.id} value={x.id}>
                      {x.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='challengeTypeId'
                label='Hypes Type'
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: 'hypes type is required' }]}
              >
                <Select placeholder='Select hypes type' allowClear>
                  {GenerateChallengeType().map((x) => (
                    <Option key={x.id} value={x.id}>
                      {x.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name='mediaTypeId'
                label='Media Type'
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: 'media type is required' }]}
              >
                <Select placeholder='Select media type' allowClear>
                  {GenerateMediaType().map((x) => (
                    <Option key={x.id} value={x.id}>
                      {x.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item shouldUpdate>
                {() => (
                  <Form.Item
                    name='challengeValue'
                    label='Valuation Type'
                    labelCol={{ span: 24 }}
                    rules={[
                      { required: true, message: 'valuation type is required' }
                    ]}
                    help={`${
                      helperValuationType[form.getFieldValue('challengeTypeId')]
                    }. separate with spaces.`}
                  >
                    <Input placeholder='e.g: #cretory #creatoryhypes' />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition={'right'}
            ghost
            expandIcon={(panelProps) => {
              if (panelProps?.isActive) {
                return <UpOutlined style={{ fontSize: '24px' }} />
              }
              return (
                <DownOutlined color={'blue'} style={{ fontSize: '24px' }} />
              )
            }}
            className='pv-2'
          >
            <CollapsePanel
              header={
                <Text className='heading-s-medium text-color-dark-purple'>
                  Ketentuan Mengikuti Hypes
                </Text>
              }
              className='hypes-form-collapsible-container'
              key='1'
              extra={<div />}
            >
              {ChallengeRuleSwitches(CHALLENGE_RULE_KIND.RULE_KIND_HYPE_TERM)}
              <Form.Item
                name={['content', 'steps']}
                label='Ketentuan Lainnya'
                labelCol={{ span: 24 }}
              >
                <CustomEditor config={{ stepsParser: true }} />
              </Form.Item>
            </CollapsePanel>
            <CollapsePanel
              header={
                <Text className='heading-s-medium text-color-dark-purple'>
                  Ketentuan Konten
                </Text>
              }
              key='2'
              className='hypes-form-collapsible-container'
              extra={<div />}
            >
              {ChallengeRuleSwitches(
                CHALLENGE_RULE_KIND.RULE_KIND_CONTENT_TERM
              )}
              <Form.Item
                name={['content', 'requirements']}
                label='Ketentuan Lainnya'
                labelCol={{ span: 24 }}
              >
                <CustomEditor config={{ stepsParser: true }} />
              </Form.Item>
            </CollapsePanel>
          </Collapse>
          <Divider />
          <Row gutter={[32, 0]}>
            <Col span={7}>
              <Form.Item
                name='startAt'
                label={
                  <Text className='text-weight-medium'>
                    Mulai Dari<span className='text-color-danger-main'>*</span>
                  </Text>
                }
                labelCol={{ span: 24 }}
                requiredMark={true}
                className='hypes-form-etc-form'
                rules={[{ required: true }]}
              >
                <DatePicker
                  placeholder='DD/MM/YY'
                  style={{ width: '100%' }}
                  showTime
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name='endAt'
                label={
                  <Text className='text-weight-medium'>
                    Berakhir Pada
                    <span className='text-color-danger-main'>*</span>
                  </Text>
                }
                labelCol={{ span: 24 }}
                className='hypes-form-etc-form'
                rules={[
                  { required: true },
                  isDateLessThan(
                    'startAt',
                    `Can't be less than or same as start at`
                  )
                ]}
                dependencies={['startAt']}
              >
                <DatePicker
                  placeholder='DD/MM/YY'
                  style={{ width: '100%' }}
                  showTime
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='sort'
                className='hypes-form-etc-form'
                label={
                  <Text className='text-weight-medium'>
                    Sort<span className='text-color-danger-main'>*</span>
                  </Text>
                }
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  placeholder='Tentukan urutan'
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='isActive'
                label={isActive ? 'Aktif' : 'Nonaktif'}
                className='hypes-isactive-switch hypes-form-etc-form'
                valuePropName='checked'
                style={{ alignItems: 'center', paddingTop: '20px' }}
              >
                <Switch onChange={(isActive) => setIsActive(isActive)} />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row justify='end'>
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type='primary'
                style={{ height: 48 }}
                htmlType='submit'
                loading={challenge.requesting}
                className='button-submit'
                disabled={disableSubmit}
              >
                Posting Hypes Baru
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Col>
      <Modal
        title='Tambah Ketentuan'
        onOk={() => {
          formNewRule.submit()
        }}
        onCancel={() => {
          setOpenModalNewRule(null)
        }}
        visible={!!openModalNewRule}
        okText='Tambah'
        okButtonProps={{
          className: 'hypes-form-add-rule-submit',
          disabled: newRuleSubmitDisabled
        }}
        cancelText='Batalkan'
        cancelButtonProps={{
          type: 'text',
          className: 'hypes-form-add-rule-cancel'
        }}
      >
        <Form
          form={formNewRule}
          requiredMark={false}
          onValuesChange={() => {
            setNewRuleSubmitDisabled(
              !formNewRule.getFieldValue('name') ||
                !formNewRule.getFieldValue('type')
            )
          }}
          onFinish={(values) => {
            const newChallengeRule: IChallengeRule = {
              challengeId: challenge.data?.id,
              isActive: true,
              value: '',
              rule: {
                code: makeId(5),
                content: {
                  name: values.name
                },
                isDefault: false,
                kind:
                  openModalNewRule ||
                  CHALLENGE_RULE_KIND.RULE_KIND_CONTENT_TERM,
                type: values.type as CHALLENGE_RULE_TYPE
              }
            } as IChallengeRule
            setChallengeRules([...challengeRules, newChallengeRule])
            formNewRule.resetFields()
            setOpenModalNewRule(null)
          }}
        >
          <Form.Item
            name='name'
            label='Judul Ketentuan'
            className=''
            labelCol={{ span: 24 }}
            rules={[{ required: true }]}
          >
            <Input placeholder='Tuliskan Judul Ketentuan' maxLength={75} />
          </Form.Item>
          <Form.Item
            name='type'
            label='Jenis Field'
            labelCol={{ span: 24 }}
            rules={[{ required: true, message: 'Mohon masukkan jenis field' }]}
          >
            <Select placeholder='Pilih salah satu' allowClear>
              {GenerateRuleType().map((x) => (
                <Option key={x.id} value={x.id}>
                  {x.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  )
}

export default HypeForm
