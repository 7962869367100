import { IMetadata } from '@cr/general/dist/interfaces'
import Base from './base'

export default {
  GetList: (types?: string, page?: number, limit?: number): Promise<any> => {
    return Base('/general/v1').get(
      `admin/metadata/?${types ? `&types=${types}` : ''}${
        page ? `&page=${page}` : ''
      }${limit ? `&limit=${limit}` : ''}`
    )
  },
  Create: (data: IMetadata): Promise<any> => {
    return Base('/general/v1').post(`admin/metadata`, data)
  },
  Update: (data: IMetadata): Promise<any> => {
    return Base('/general/v1').put(`admin/metadata/${data.id}`, data)
  },
  Delete: (id: number): Promise<any> => {
    return Base('/general/v1').delete(`admin/metadata/${id}`)
  }
}
