import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import BaseStateDefault from '../../constants/state'
import IChallengeTierState from '../../interfaces/states/challenge-tier'

const initialState: IChallengeTierState = {
  ...BaseStateDefault
}

const reducer: Reducer<any> = (
  state: IChallengeTierState = initialState,
  action
): IChallengeTierState => {
  switch (action.type) {
    case ActionTypes.ChallengeTier.CHALLENGE_TIER_GET_LIST_REQUEST:
    case ActionTypes.ChallengeTier.CHALLENGE_TIER_CREATE_REQUEST:
    case ActionTypes.ChallengeTier.CHALLENGE_TIER_UPDATE_REQUEST:
    case ActionTypes.ChallengeTier.CHALLENGE_TIER_DELETE_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.ChallengeTier.CHALLENGE_TIER_GET_LIST_SUCCESS: {
      const { list, paging } = action.payload
      return {
        ...state,
        list,
        paging,
        requesting: false
      }
    }
    case ActionTypes.ChallengeTier.CHALLENGE_TIER_CREATE_SUCCESS:
    case ActionTypes.ChallengeTier.CHALLENGE_TIER_UPDATE_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        requesting: false
      }
    }
    case ActionTypes.ChallengeTier.CHALLENGE_TIER_DELETE_SUCCESS: {
      const { id } = action.payload
      return {
        ...state,
        list: state.list?.filter((x) => x.id !== parseInt(id, 10)),
        requesting: false
      }
    }
    case ActionTypes.ChallengeTier.CHALLENGE_TIER_GET_LIST_FAILED:
    case ActionTypes.ChallengeTier.CHALLENGE_TIER_CREATE_FAILED:
    case ActionTypes.ChallengeTier.CHALLENGE_TIER_UPDATE_FAILED:
    case ActionTypes.ChallengeTier.CHALLENGE_TIER_DELETE_FAILED: {
      return {
        ...state,
        list: [],
        data: undefined,
        requesting: false,
        error: action.payload.error
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
