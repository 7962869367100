const isDateLessThan = (field: string, message?: string) => {
  return ({ getFieldValue }) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue(field) < value) {
        return Promise.resolve()
      }
      return Promise.reject(
        new Error(message || `Date can't be less than ${field}`)
      )
    }
  })
}

const isMatchValue = (field: string, message?: string) => {
  return ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue(field) === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(message || `Your input doesn't match`))
    }
  })
}

const isZeroValue = (message?: string) => {
  return () => ({
    validator(_, value) {
      if (value > 0) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(message || `must be greater than zero`))
    }
  })
}

export { isDateLessThan, isMatchValue, isZeroValue }
