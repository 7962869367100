import { IResult } from '@attn/general/dist/interfaces/api'
import { IPaymentMethod } from '@cr/general/dist/interfaces'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../constants/action-types'
import Api from '../apis'
import Base from './base'

const GetList = () => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.PaymentMethod.GetList(),
    () => {
      dispatch(
        action(ActionTypes.PaymentMethod.PAYMENT_METHOD_GET_LIST_REQUEST)
      )
    },
    (res: IResult<Array<IPaymentMethod>>) => {
      dispatch(
        action(ActionTypes.PaymentMethod.PAYMENT_METHOD_GET_LIST_SUCCESS, {
          list: res.data
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.PaymentMethod.PAYMENT_METHOD_GET_LIST_FAILED, {
          error
        })
      )
  )
}

export default {
  GetList
}
