const TRANSACTION_GET_LIST_REQUEST = 'TRANSACTION_GET_LIST_REQUEST'
const TRANSACTION_GET_LIST_SUCCESS = 'TRANSACTION_GET_LIST_SUCCESS'
const TRANSACTION_GET_LIST_FAILED = 'TRANSACTION_GET_LIST_FAILED'

const TRANSACTION_GET_DETAIL_REQUEST = 'TRANSACTION_GET_DETAIL_REQUEST'
const TRANSACTION_GET_DETAIL_SUCCESS = 'TRANSACTION_GET_DETAIL_SUCCESS'
const TRANSACTION_GET_DETAIL_FAILED = 'TRANSACTION_GET_DETAIL_FAILED'

const TRANSACTION_GET_STATUS_COUNT_REQUEST =
  'TRANSACTION_GET_STATUS_COUNT_REQUEST'
const TRANSACTION_GET_STATUS_COUNT_SUCCESS =
  'TRANSACTION_GET_STATUS_COUNT_SUCCESS'
const TRANSACTION_GET_STATUS_COUNT_FAILED =
  'TRANSACTION_GET_STATUS_COUNT_FAILED'

export default {
  TRANSACTION_GET_LIST_REQUEST,
  TRANSACTION_GET_LIST_SUCCESS,
  TRANSACTION_GET_LIST_FAILED,

  TRANSACTION_GET_DETAIL_REQUEST,
  TRANSACTION_GET_DETAIL_SUCCESS,
  TRANSACTION_GET_DETAIL_FAILED,

  TRANSACTION_GET_STATUS_COUNT_REQUEST,
  TRANSACTION_GET_STATUS_COUNT_SUCCESS,
  TRANSACTION_GET_STATUS_COUNT_FAILED
}
