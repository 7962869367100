import { IResult } from '@attn/general/dist/interfaces/api'
import SERVICE_ID from '@cr/general/dist/constants/service'
import { ITransaction } from '@cr/general/dist/interfaces'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../constants/action-types'
import API_TRANSACTION_TYPE from '../../constants/transaction-type'
import { IFilters } from '../../pages/transactions'
import Api from '../apis'
import Base from './base'

const GetList =
  (page?: number, limit?: number, filter?: IFilters) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Transaction.GetList(
        filter?.service === SERVICE_ID.SUBSCRIPTION.toString()
          ? API_TRANSACTION_TYPE.SUBSCRIPTION
          : API_TRANSACTION_TYPE.DONATION,
        page,
        limit,
        filter
      ),
      () => {
        dispatch(action(ActionTypes.Transaction.TRANSACTION_GET_LIST_REQUEST))
      },
      (res: IResult<Array<ITransaction>>) => {
        dispatch(
          action(ActionTypes.Transaction.TRANSACTION_GET_LIST_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Transaction.TRANSACTION_GET_LIST_FAILED, {
            error
          })
        )
    )
  }

const GetCountStatus =
  (filter?: IFilters) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Transaction.GetCountStatus(
        filter?.service === SERVICE_ID.SUBSCRIPTION.toString()
          ? API_TRANSACTION_TYPE.SUBSCRIPTION
          : API_TRANSACTION_TYPE.DONATION,
        filter
      ),
      () => {
        dispatch(
          action(ActionTypes.Transaction.TRANSACTION_GET_STATUS_COUNT_REQUEST)
        )
      },
      (res: IResult<Array<ITransaction>>) => {
        dispatch(
          action(ActionTypes.Transaction.TRANSACTION_GET_STATUS_COUNT_SUCCESS, {
            data: res.data
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Transaction.TRANSACTION_GET_STATUS_COUNT_FAILED, {
            error
          })
        )
    )
  }

const GetDetail =
  (type: API_TRANSACTION_TYPE, receiptId: string) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Transaction.GetDetail(type, receiptId),
      () => {
        dispatch(action(ActionTypes.Transaction.TRANSACTION_GET_DETAIL_REQUEST))
      },
      (res: IResult<ITransaction>) => {
        dispatch(
          action(ActionTypes.Transaction.TRANSACTION_GET_DETAIL_SUCCESS, {
            data: res.data
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Transaction.TRANSACTION_GET_DETAIL_FAILED, {
            error
          })
        )
    )
  }
export default {
  GetList,
  GetCountStatus,
  GetDetail
}
