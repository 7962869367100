import { IResult } from '@attn/general/dist/interfaces/api'
import { IUserChallengeSubmission } from '@cr/general/dist/interfaces'
import { message } from 'antd'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../constants/action-types'
import Api from '../apis'
import Base from './base'

const GetListByChallengeId =
  (
    challengeId: string,
    status: string,
    page?: number,
    limit?: number,
    sort?: string,
    order?: 'asc' | 'desc',
    createdAtFrom?: number,
    createdAtUntil?: number
  ) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.UserChallengeSubmission.GetListByChallengeId(
        challengeId,
        status,
        page,
        limit,
        sort,
        order,
        createdAtFrom,
        createdAtUntil
      ),
      () => {
        dispatch(
          action(
            ActionTypes.UserChallengeSubmission
              .USER_CHALLENGE_SUBMISSION_GET_LIST_REQUEST,
            { status }
          )
        )
      },
      (res: IResult<Array<IUserChallengeSubmission>>) => {
        dispatch(
          action(
            ActionTypes.UserChallengeSubmission
              .USER_CHALLENGE_SUBMISSION_GET_LIST_SUCCESS,
            {
              list: res.data,
              paging: res.paging,
              status
            }
          )
        )
      },
      (error: string) =>
        dispatch(
          action(
            ActionTypes.UserChallengeSubmission
              .USER_CHALLENGE_SUBMISSION_GET_LIST_FAILED,
            {
              error,
              status
            }
          )
        )
    )
  }

const Verify =
  (challengeId: string, submissionId: number, data: IUserChallengeSubmission) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.UserChallengeSubmission.Verify(challengeId, submissionId, data),
      () => {
        dispatch(
          action(
            ActionTypes.UserChallengeSubmission
              .USER_CHALLENGE_SUBMISSION_ACTION_REQUEST
          )
        )
      },
      () => {
        dispatch(
          action(
            ActionTypes.UserChallengeSubmission
              .USER_CHALLENGE_SUBMISSION_ACTION_SUCCESS,
            {
              submissionId
            }
          )
        )
        message.info(`The submission was ${data.qcStatus}`)
      },
      (error: string) =>
        dispatch(
          action(
            ActionTypes.UserChallengeSubmission
              .USER_CHALLENGE_SUBMISSION_ACTION_FAILED,
            {
              error
            }
          )
        )
    )
  }

export default {
  GetListByChallengeId,
  Verify
}
