import { Col, Input, Row, Typography, Form, InputNumber } from 'antd'
import React from 'react'
import { fieldPartnershipFee, fieldServiceFee, productTypeFee } from './fields'
const { Text } = Typography

const CommercialDetail = () => {
  return (
    <div className={'mt-2'}>
      <div className={'mb-1'}>
        <Text className='text-size-24 text-weight-bold'>Commercial Detail</Text>
      </div>
      <Row className='mb-1'>
        <Col span={8}>
          <Form.Item
            label={'Partnership Name'}
            name={'partnership_name'}
            rules={[
              {
                required: true,
                message: 'Partnership name is required.'
              },
              {
                pattern: /^[a-zA-Z0-9\s]+$/,
                message:
                  'Partnership name can only include letters and numbers.'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row className='mb-1' gutter={[16, 16]}>
        {productTypeFee.map((value, index) => {
          return (
            <Col key={index} span={value.col}>
              <Form.Item
                label={value.label}
                name={value.name}
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          )
        })}
      </Row>
      <Row gutter={[16, 16]} className='mb-1'>
        {fieldPartnershipFee.map((value, index) => {
          return (
            <Col key={index} span={value.col}>
              <Form.Item
                label={value.label}
                name={value.name}
                rules={[
                  {
                    required: true,
                    message: 'Agency fee is required.'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder={value.placeholder}
                  min={value.min}
                  max={value.max}
                />
              </Form.Item>
            </Col>
          )
        })}
      </Row>
      <Row className='mb-1' gutter={[16, 16]}>
        {fieldServiceFee.map((value, index) => {
          return (
            <Col key={index} span={value.col}>
              <Form.Item
                label={value.label}
                name={value.name}
                rules={[
                  {
                    required: true,
                    message: 'Creatory fee is required.'
                  }
                ]}
              >
                <InputNumber
                  style={{
                    width: '100%'
                  }}
                  placeholder={value.placeholder}
                  min={value.min}
                  max={value.max}
                />
              </Form.Item>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default CommercialDetail
