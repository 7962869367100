import { Button, Col, Input, InputNumber, Modal, Row, Switch, Typography } from 'antd'
import * as React from 'react'
import IMetadata from '@cr/general/dist/interfaces/metadata'
import { useState } from 'react'
import { useEffect } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { isEqual } from 'lodash'

const { Text } = Typography

interface IItemProps {
  item: any
  label?: any
  onChange: (value) => void
}
const ItemEditor = (props: IItemProps) => {
  const { item, label, onChange } = props
  const [value, setValue] = useState<any>()
  const [originalType, setOriginalType] = useState<any>()

  useEffect(() => {
    setValue(item)
  }, [item])

  const GetInput = (item) => {
    switch (typeof item) {
      case 'number':
        return (
          <InputNumber
            value={value}
            size='large'
            style={{ width: '100%' }}
            onChange={(val) => setValue(val)}
            onBlur={() => {
              if (value !== item) onChange(value)
            }}
          />
        )
      case 'boolean':
        return <Switch checked={value} onChange={onChange} />
      default:
        return (
          <Input
            value={value}
            size='large'
            style={{ width: '100%' }}
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => {
              if (value !== item) onChange(value)
            }}
          />
        )
    }
  }

  if (Array.isArray(item)) {
    return (
      <Row key={`array-${item}`}>
        <Col span={24}>
          <Row justify='space-between' align='middle'>
            <Text className='text-color-second-dark-purple mb--5'>{label}</Text>
            <Button
              type='primary'
              size='large'
              className='ph-4'
              onClick={() => {
                const base = originalType || item[0]
                const newItem = typeof base === 'object' ? {} : undefined
                if (typeof newItem === 'object') {
                  Object.keys(base).forEach((k) => {
                    newItem[k] = ''
                  })
                }

                const newArray = [newItem].concat(item)
                onChange(newArray)
              }}
            >
              Add Item
            </Button>
          </Row>
          <Row justify='center' className='mv-2'>
            {item.map((i, idx) => (
              <Col
                span={24}
                key={`array-${item}-${idx}`}
                className={typeof i === 'object' ? 'array-item' : 'mb-2'}
              >
                <Row align='middle'>
                  <Col flex='auto'>
                    <ItemEditor
                      item={i}
                      onChange={(dt) => {
                        const newItem = typeof i === 'object' ? { ...i, ...dt } : dt
                        const newArray = item.map((x, l) => l === idx ? newItem : x)
                        onChange(newArray)
                      }}
                    />
                  </Col>
                  <Col className='pl-2'>
                    <Button
                      type='primary'
                      danger
                      icon={<DeleteOutlined />}
                      size='large'
                      onClick={() => {
                        Modal.confirm({
                          title: 'Are you sure to delete this data?',
                          onOk: () => {
                            const newArray = item.filter((x) => x !== i)
                            if (newArray.length <= 0) {
                              setOriginalType(item[0])
                            }
                            onChange(newArray)
                          }
                        })
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            ))}
            {item.length <= 0 ? (
              <Text className='text-center'>No data</Text>
            ) : (
              <></>
            )}
          </Row>
        </Col>
      </Row>
    )
  }
  if (typeof item === 'object') {
    return (
      <Row key={`object-${item}`}>
        {Object.keys(item).map((i, idx) => (
          <Col span={24} className='pv-1' key={`object-${item}-${idx}`}>
            <ItemEditor
              item={item[i]}
              label={i}
              onChange={(dt) => {
                const newItem = { ...item, [i]: dt }
                onChange(newItem)
              }}
            />
          </Col>
        ))}
      </Row>
    )
  }

  return (
    <Row key={item} className='advance-meta-form'>
      <Col span={24}>
        {label ? (
          <Row>
            <Text className='text-color-second-dark-purple mb--5'>{label}</Text>
          </Row>
        ) : (
          <></>
        )}
        <Row>{GetInput(item)}</Row>
      </Col>
    </Row>
  )
}
interface IProps {
  metadata: IMetadata
  onUpdate: (value) => void
  loading: boolean
}

const AdvanceMetaEditor = (props: IProps) => {
  const { metadata, onUpdate, loading } = props
  const [data, setData] = useState<any>()

  useEffect(() => {
    if (!loading) setData(metadata)
  }, [loading, metadata])

  return (
    <Row>
      <Col span={24}>
        <ItemEditor
          item={data}
          onChange={(dt) => {
            if (!isEqual(data, dt))
              setData(Array.isArray(data) ? [...dt] : { ...dt })
          }}
        />
        <Row className='mt-2' justify='center'>
          <Button
            type='primary'
            size='large'
            className='ph-4'
            onClick={() => {
              Modal.confirm({
                title: 'Are you sure to save this updates?',
                onOk: () => {
                  onUpdate(data)
                }
              })
            }}
            loading={loading}
          >
            Update
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

export default AdvanceMetaEditor
