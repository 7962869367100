import React from 'react'
import {
  Row,
  Typography,
  TableColumnProps,
  Button,
  Col,
  Modal,
  Image
} from 'antd'
import * as moment from 'moment'
import Avatar from 'antd/lib/avatar/avatar'

import GeneralParticle from '@attn/general'
import { ICashWithdrawalRequest } from '@cr/general/dist/interfaces'
import CONST from '@cr/general/dist/constants'
import SUMMARY_WITHDRAWAL_STATUS from '../summary-withdrawal-status'
import VERIFY_STATUS from '@cr/general/dist/constants/verify-status'

const { Text } = Typography
const { Numberize } = GeneralParticle.Utils.Format
const { CASH_WITHDRAWAL_REQUEST_STATUS, DISBURSE_STATUS } = CONST

export const WITHDRAWAL_STATUS = (wr: ICashWithdrawalRequest) => {
  if (wr?.status === CASH_WITHDRAWAL_REQUEST_STATUS.PENDING)
    return SUMMARY_WITHDRAWAL_STATUS.PENDING
  if (
    wr?.status === CASH_WITHDRAWAL_REQUEST_STATUS.APPROVED &&
    wr?.disburseStatus === DISBURSE_STATUS.PENDING
  )
    return SUMMARY_WITHDRAWAL_STATUS.TRANSFERRING
  if (wr?.disburseStatus === DISBURSE_STATUS.COMPLETED)
    return SUMMARY_WITHDRAWAL_STATUS.COMPLETED
  if (wr?.disburseStatus === DISBURSE_STATUS.FAILED)
    return SUMMARY_WITHDRAWAL_STATUS.FAILED
  if (wr?.status === CASH_WITHDRAWAL_REQUEST_STATUS.DECLINED)
    return SUMMARY_WITHDRAWAL_STATUS.DECLINED
  if (
    wr.status === CASH_WITHDRAWAL_REQUEST_STATUS.SUCCESS &&
    !wr.disburseStatus
  )
    return SUMMARY_WITHDRAWAL_STATUS.SUCCESS
  return 'undefined'
}

const VERIFICATION_STATUS = (status) => {
  switch (status) {
    case VERIFY_STATUS.VERIFIED:
      return (
        <Text className='text-m-regular text-color-success-pressed'>
          Verified
        </Text>
      )
    case VERIFY_STATUS.PENDING:
      return <Text className='text-m-regular'>Requesting</Text>
    case VERIFY_STATUS.DECLINED:
      return (
        <Text className='text-m-regular text-color-danger-hover'>Invalid</Text>
      )
    default:
      return <Text className='text-m-regular'>-</Text>
  }
}

const WithdrawalRequestColumn = [
  {
    title: 'Talent Name',
    dataIndex: 'talentName',
    key: 'talentName',
    render: (_text: any, wr: ICashWithdrawalRequest) => (
      <>
        <Row wrap={false} align='middle'>
          <Avatar src={wr.user?.images?.profilePicture} />
          <Row>
            <Col>
              <Text className='ml-2 text-m-medium' ellipsis>
                {wr.user?.fullName}
              </Text>
              <Row>
                <Text className='ml-2 text-color-neutral-70 text-s-medium'>
                  @{wr.user.username}
                </Text>
              </Row>
            </Col>
          </Row>
        </Row>
      </>
    )
  },
  {
    title: 'Requested At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (_text: any, wr: any) => (
      <Text className='text-size-16'>
        {moment.unix(wr?.createdAt || 0).format('DD MMM YY, HH:mm')}
      </Text>
    )
  },
  {
    title: 'Bank Info',
    dataIndex: 'bankInfo',
    key: 'talentName',
    render: (_text: any, wr: ICashWithdrawalRequest) => (
      <>
        <Row>
          <Col span={24}>
            <Text className='text-m-medium'>
              {wr.user?.bankAccountInfo?.accountName}
            </Text>
          </Col>
          <Col span={24}>
            <Text className='text-color-neutral-70 text-s-medium'>{`${wr.user.bankAccountInfo?.bank.name} - ${wr.user.bankAccountInfo?.accountNumber}`}</Text>
          </Col>
        </Row>
      </>
    )
  },
  {
    title: 'Bank Acc Status',
    dataIndex: 'bankVerificationStatus',
    key: 'talentName',
    render: (_text: any, wr: ICashWithdrawalRequest) => (
      <>{VERIFICATION_STATUS(wr.user.bankAccountInfo?.status)}</>
    )
  },
  {
    title: 'ID Card Status',
    dataIndex: 'idCardStatus',
    key: 'talentName',
    render: (_text: any, wr: ICashWithdrawalRequest) => (
      <>
        {wr.user.idCardInfo?.status === VERIFY_STATUS.VERIFIED ? (
          <Button
            className='text-color-primary-main'
            type='link'
            onClick={() =>
              Modal.info({
                maskClosable: true,
                closable: true,
                width: 739,
                icon: <></>,
                content: (
                  <>
                    <Typography className='text-m-bold mb-2'>
                      Preview ID Card
                    </Typography>
                    <Image src={wr.user.idCardInfo?.images} />
                  </>
                ),
                className: 'disable-ant-modal-footer'
              })
            }
          >
            <Text underline className='text-color-primary-main text-m-regular'>
              Verified
            </Text>
          </Button>
        ) : (
          VERIFICATION_STATUS(wr.user.idCardInfo?.status)
        )}
      </>
    )
  },
  {
    title: 'Request Amount',
    render: (_text: any, wr: ICashWithdrawalRequest) => (
      <Text className='text-size-16'>
        {Numberize(wr?.amountRequested || 0, '.', 'Rp ')}
      </Text>
    )
  },
  {
    title: 'Amount to be Transferred',
    render: (_text: any, wr: ICashWithdrawalRequest) => (
      <a
        type='link'
        href={`/users/${wr.user.username}`}
        target='_blank'
        rel='noreferrer'
      >
        <Text className='text-color-primary-main' underline>
          Balance log
        </Text>
      </a>
    )
  }
] as Array<TableColumnProps<any>>

export default WithdrawalRequestColumn
