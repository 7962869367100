import React from 'react'
import { Table } from 'antd'
import { IChallenge } from '@cr/general/dist/interfaces'
import IChallengeState from '../../interfaces/states/challenge'
import HypesColumn from '../../constants/table/hypes'
import CHALLENGE_STATUS from '../../constants/challenge-status'

interface IProps {
  challenge: IChallengeState
  status: string
  onChangePage: (page: number) => void
}

const HypeList = (props: IProps) => {
  const { challenge, status, onChangePage } = props
  const data = challenge.groupedList[status || CHALLENGE_STATUS.ON_GOING]
  return (
    <Table
      loading={data?.requesting}
      columns={HypesColumn}
      dataSource={data?.list || []}
      rowKey={(wr: IChallenge) => wr.id}
      pagination={{
        hideOnSinglePage: true,
        showLessItems: true,
        current: data?.paging?.currentPage,
        pageSize: data?.paging?.limit || 0,
        total: data?.paging?.totalData,
        onChange: onChangePage
      }}
    />
  )
}

export default HypeList
