import { Col, Row, Avatar, Typography, Tag, Button } from 'antd'
import * as React from 'react'
import IUser from '@cr/general/dist/interfaces/user'
import moment from 'moment'

import ENV from '../../utils/environment'

const { Text, Link } = Typography

const UserTableColumns: Array<any> = [
  {
    title: 'Full Name',
    key: 'fullName',
    render: (record: IUser) => {
      return (
        <Row key={record.id}>
          <Col span={24}>
            <Row align='middle'>
              <Col flex='40px'>
                <Avatar src={record?.images?.profilePicture} size={32} />
              </Col>
              <Col flex='auto'>
                <Text className='text-size-16 text-height-20'>
                  {record?.fullName}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    }
  },
  {
    title: 'Username',
    key: 'username',
    render: (record: IUser) => {
      return (
        <Link
          key={record.id}
          className='text-size-16 text-height-20'
          href={`${ENV.CR_FRAGMENT_URL}${record.username}`}
        >
          {record?.username}
        </Link>
      )
    }
  },
  {
    title: 'Adult Content Creator',
    key: 'isAdultContent',
    render: (record: IUser) => {
      return (
        <Row key={record.id}>
          <Text className='text-size-16 text-height-20'>
            {record.isAdultContent ? (
              <Tag color='error'>True</Tag>
            ) : (
              <Tag color='purple'>False</Tag>
            )}
          </Text>
        </Row>
      )
    }
  },
  {
    title: 'Joined At',
    key: 'joinedAt',
    render: (record: IUser) => {
      return (
        <Row key={record.id}>
          <Text className='text-size-16 text-height-20'>
            {moment.unix(record?.createdAt || 0).format('DD MMM, YYYY')}
          </Text>
        </Row>
      )
    }
  },
  {
    title: 'Action',
    key: 'joinedAt',
    render: (record: IUser) => {
      return (
        <Row key={record.id}>
          <Button type='link' href={`/users/${record.username}`}>
            <Text
              underline
              className='text-size-16 text-weight-medium text-color-primary-purple'
            >
              See Balance Log
            </Text>
          </Button>
        </Row>
      )
    }
  }
]

export default UserTableColumns
