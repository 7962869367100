/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-empty-function: 0 */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { parse } from 'query-string'
import { Button, Col, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import Layout from '../../components/layout'
import Actions from '../../store/actions'
import { ReduxState } from '../../store/reducers'
import IChallengeState from '../../interfaces/states/challenge'
import CHALLENGE_STATUS from '../../constants/challenge-status'
import OptionStatus from '../../components/option-status'
import HypeList from './hypes-list'
import HypeForm from './hypes-form'
import IBreadcrumbItem from '../../interfaces/breadcrumb-item'
import { IChallenge } from '@cr/general/dist/interfaces'
import { uploadPromise } from '../../utils'
import UPLOAD_SERVICE from '../../constants/upload_service'
import UPLOAD_KIND from '../../constants/upload-kind'
import IUploadFile from '../../interfaces/models/upload-file'

interface IProps {
  GetListChallenge: (status: string, page?: number, limit?: number) => void
  GetDetailChallenge: (id: string) => void
  Create: (data: IChallenge) => void
  Update: (id: string, data: IChallenge) => Promise<any>
  UploadFile: (data) => Promise<any>
  GetChallengeRules: () => Promise<any>
  challenge: IChallengeState
}

const Hypes = (props: IProps) => {
  const history = useHistory()
  const qs: any = parse(useLocation().search.toString())
  const {
    GetListChallenge,
    GetDetailChallenge,
    challenge,
    Create,
    Update,
    UploadFile,
    GetChallengeRules
  } = props
  const [status, setStatus] = useState<string | undefined>(undefined)
  const params: { id: string } = useParams()

  React.useEffect(() => {
    setStatus(undefined)
  }, [])

  React.useEffect(() => {
    setStatus(qs.status)
  }, [qs.status])

  React.useEffect(() => {
    if (!params.id) {
      GetListChallenge(status || CHALLENGE_STATUS.ON_GOING.toString())
    }
    if (params.id) {
      GetChallengeRules()
    }
  }, [status])

  React.useEffect(() => {
    if (!isNaN(parseInt(params.id))) GetDetailChallenge(params.id)
  }, [])

  const breadcrumbs: Array<IBreadcrumbItem> = [
    {
      label: 'Hypes',
      href: '/hypes'
    },
    {
      label: isNaN(parseInt(params.id))
        ? 'Create New Hypes'
        : challenge.data?.name!
    }
  ]

  const onSubmit = async (values: IChallenge) => {
    const uploadPromises: Array<Promise<Array<IUploadFile>>> = []
    for (const field in values.content) {
      if (
        typeof values.content[field] !== 'string' &&
        ['image', 'video'].includes(field)
      ) {
        uploadPromises.push(
          uploadPromise(
            values?.content[field],
            UPLOAD_SERVICE.CR_CHALLENGE,
            field === 'image'
              ? UPLOAD_KIND.HYPES_IMAGE
              : UPLOAD_KIND.HYPES_VIDEO,
            UploadFile
          )
        )
      }
    }

    const uploadFileUrls =
      (await Promise.all(uploadPromises).catch(() => {})) || []
    for (const uploadFileUrl of uploadFileUrls) {
      const { url, serviceKindId } = uploadFileUrl[0]
      if (
        [UPLOAD_KIND.HYPES_IMAGE, UPLOAD_KIND.HYPES_VIDEO].includes(
          serviceKindId!
        )
      ) {
        values.content![
          serviceKindId === UPLOAD_KIND.HYPES_IMAGE ? 'image' : 'video'
        ] = url
      }
    }

    values.id
      ? Update(values.id.toString(), values).then(() =>
          GetDetailChallenge(values.id.toString())
        )
      : Create(values)
  }

  if (params.id)
    return (
      <Layout breadcrumbs={breadcrumbs}>
        <HypeForm challenge={challenge} onSubmit={onSubmit} />
      </Layout>
    )

  return (
    <Layout header='Hypes'>
      <Row>
        <Col>
          <Row>
            <Button
              className='mr-1'
              type={
                status === CHALLENGE_STATUS.COMING_SOON.toString()
                  ? 'primary'
                  : 'ghost'
              }
              onClick={() =>
                history.push(`/hypes/?status=${CHALLENGE_STATUS.COMING_SOON}`)
              }
            >
              Coming Soon
            </Button>
            <OptionStatus
              pendingText='On Going'
              count={
                challenge.groupedList[CHALLENGE_STATUS.ON_GOING]?.paging
                  ?.totalData!
              }
              isPending={
                status === CHALLENGE_STATUS.COMING_SOON.toString()
                  ? undefined
                  : (status || CHALLENGE_STATUS.ON_GOING.toString()) ===
                    CHALLENGE_STATUS.ON_GOING.toString()
              }
              onPending={() =>
                history.push(`/hypes/?status=${CHALLENGE_STATUS.ON_GOING}`)
              }
              onComplete={() =>
                history.push(`/hypes/?status=${CHALLENGE_STATUS.COMPLETED}`)
              }
            />
          </Row>
        </Col>
        <Col flex='1 1'>
          <Row justify='end'>
            <Button type='primary' href='/hypes/create' icon={<PlusOutlined />}>
              Create New Hypes
            </Button>
          </Row>
        </Col>
      </Row>
      <HypeList
        challenge={challenge}
        status={status!}
        onChangePage={(pn) =>
          GetListChallenge(status || CHALLENGE_STATUS.ON_GOING.toString(), pn)
        }
      />
    </Layout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  challenge: state.challenge
})

const mapDispatchToProps = (dispatch: any) => ({
  GetListChallenge: (status: string, page?: number, limit?: number) =>
    dispatch(Actions.Challenge.GetList(status, undefined, page, limit)),
  GetDetailChallenge: (id: string) => dispatch(Actions.Challenge.GetDetail(id)),
  Create: (data: IChallenge) => dispatch(Actions.Challenge.Create(data)),
  Update: (id: string, data: IChallenge) =>
    dispatch(Actions.Challenge.Update(id, data)),
  UploadFile: (data) => dispatch(Actions.Upload.Create(data)),
  GetChallengeRules: () => dispatch(Actions.Challenge.GetRules())
})

export default connect(mapStateToProps, mapDispatchToProps)(Hypes)
