import React from 'react'
import { Row, Badge, Button } from 'antd'

interface IOptionStatus {
  count: number
  isPending?: boolean
  onPending: () => void
  onComplete: () => void
  additionalElement?: React.ReactNode
  pendingText?: string
  completeText?: string
}

const OptionStatus = (props: IOptionStatus) => {
  const {
    count,
    isPending,
    onPending,
    onComplete,
    additionalElement,
    pendingText,
    completeText
  } = props
  return (
    <Row className='mb-3'>
      <Button
        type={isPending ? 'primary' : 'ghost'}
        className='mr-1 text-weight-medium'
        onClick={onPending}
      >
        {pendingText || 'Pending'}
        <Badge
          className={`ml-1 badge-white text-color-primary-purple ${
            !isPending ? 'badge-disable' : ''
          }`}
          count={count}
        />
      </Button>
      <Button
        type={!isPending && isPending !== undefined ? 'primary' : 'ghost'}
        className='text-weight-medium'
        onClick={onComplete}
      >
        {completeText || 'Completed'}
      </Button>
      {additionalElement}
    </Row>
  )
}

export default OptionStatus
