import { Col, Row, Typography } from 'antd'
import * as React from 'react'
import { connect } from 'react-redux'

import MainLayout from '../../components/layout'

const { Text } = Typography

const MainPage = () => {
  return (
    <MainLayout
      header={
        <Row>
          <Col span={12}>Dashboard</Col>
        </Row>
      }
    >
      <Text>Dashboard Content</Text>
    </MainLayout>
  )
}

const mapStateToProps = (state: any) => ({
  ...state,
  dashboard: state.dashboard
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MainPage)
