import { IResult } from '@attn/general/dist/interfaces/api'
import { ICashWithdrawalRequest } from '@cr/general/dist/interfaces'
import { message } from 'antd'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../constants/action-types'
import Api from '../apis'
import Base from './base'

const GetList =
  (status: string, page?: number, limit?: number) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Withdrawal.GetList(status, page, limit),
      () => {
        dispatch(
          action(ActionTypes.Withdrawal.WITHDRAWAL_GET_LIST_REQUEST, { status })
        )
      },
      (res: IResult<Array<ICashWithdrawalRequest>>) => {
        dispatch(
          action(ActionTypes.Withdrawal.WITHDRAWAL_GET_LIST_SUCCESS, {
            list: res.data,
            paging: res.paging,
            status
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Withdrawal.WITHDRAWAL_GET_LIST_FAILED, {
            error,
            status
          })
        )
    )
  }

const Action =
  (id: number, data: ICashWithdrawalRequest) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Withdrawal.Action(id, data),
      () => {
        dispatch(action(ActionTypes.Withdrawal.WITHDRAWAL_ACTION_REQUEST))
      },
      (res: IResult<Array<ICashWithdrawalRequest>>) => {
        dispatch(
          action(ActionTypes.Withdrawal.WITHDRAWAL_ACTION_SUCCESS, {
            id,
            data: res.data
          })
        )
        message.info(`Withdrawal request was ${data.status}`)
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Withdrawal.WITHDRAWAL_ACTION_FAILED, {
            error
          })
        )
    )
  }

const GetDetail = (id: string) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Withdrawal.GetDetail(id),
    () => {
      dispatch(action(ActionTypes.Withdrawal.WITHDRAWAL_GET_DETAIL_REQUEST))
    },
    (res: IResult<Array<ICashWithdrawalRequest>>) => {
      dispatch(
        action(ActionTypes.Withdrawal.WITHDRAWAL_GET_DETAIL_SUCCESS, {
          data: res.data
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Withdrawal.WITHDRAWAL_GET_DETAIL_FAILED, {
          error
        })
      )
  )
}

export default {
  GetList,
  Action,
  GetDetail
}
