/* eslint-disable react-hooks/exhaustive-deps */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row, Table, Typography } from 'antd'
import React, { useState } from 'react'
import IUserState from '../../interfaces/states/user'
import { USER_MODE } from '../../constants/user-mode'
import { IUser } from '@cr/general/dist/interfaces'
import VERIFY_STATUS from '@cr/general/dist/constants/verify-status'
import BankVerificationTableColumns, {
  VERIFICATION_STATUS
} from '../../constants/table/bank-verification'
import { IUserBank } from '@cr/general/dist/interfaces/user'
import Form from '../../components/form'
import { IField } from '../../interfaces/models/form'

const { Text } = Typography

const fields = [
  {
    name: 'reason',
    type: 'textarea',
    label: 'Reason',
    rules: [
      { required: true, message: 'Reason is required' },
      {
        transform: (val) => val && val.trim(),
        min: 10,
        message: 'Minimum 10 character'
      },
      {
        transform: (val) => val && val.trim(),
        max: 255,
        message: 'Maximum 255 character'
      }
    ]
  }
] as Array<IField>

interface IProps {
  user: IUserState
  status: string
  onChangePage: (page: number) => void
  onAction: (id: number, data: IUserBank) => void
}

const BankVerification = (props: IProps) => {
  const { user, status, onChangePage, onAction } = props
  const [visible, setVisible] = useState<boolean>(false)
  const [data, setData] = useState<IUser | undefined>(undefined)

  return (
    <>
      <Table
        columns={[
          ...BankVerificationTableColumns,
          {
            title: `${
              status === `${VERIFY_STATUS.VERIFIED},${VERIFY_STATUS.DECLINED}`
                ? 'Status'
                : 'Action'
            }`,
            key: `${
              status === `${VERIFY_STATUS.VERIFIED},${VERIFY_STATUS.DECLINED}`
                ? 'status'
                : 'action'
            }`,
            width: 250,
            render: (record: IUser) => {
              return (
                <Row key={record.id}>
                  <Col span={24}>
                    {status !==
                    `${VERIFY_STATUS.VERIFIED},${VERIFY_STATUS.DECLINED}` ? (
                      <Row align='middle' gutter={8}>
                        <Col span={12}>
                          <Button
                            type='text'
                            icon={<CloseOutlined />}
                            className='text-center text-color-danger-hover border-danger-hover'
                            onClick={() => {
                              setData(record)
                              setVisible(true)
                            }}
                          >
                            Decline
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Button
                            type='text'
                            icon={<CheckOutlined />}
                            className='text-center text-color-primary-main border-primary-main text-weight-medium'
                            onClick={() => {
                              Modal.confirm({
                                icon: <></>,
                                title: `Confirm Validation`,
                                content: `Are you sure to validate this bank info application`,
                                okText: 'Approve',
                                onOk: () => {
                                  onAction(record.id, {
                                    status: VERIFY_STATUS.VERIFIED,
                                    reason: ''
                                  } as IUserBank)
                                  setVisible(false)
                                },
                                okButtonProps: {
                                  className: 'bg-primary-main'
                                },
                                cancelButtonProps: {
                                  className:
                                    'bg-primary-surface text-color-primary-main border-primary-surface'
                                }
                              })
                            }}
                          >
                            Validate
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      VERIFICATION_STATUS(
                        record.bankAccountInfo?.status,
                        'bank-info'
                      )
                    )}
                  </Col>
                </Row>
              )
            }
          }
        ]}
        dataSource={
          user.groupedList[`${USER_MODE.BANK_VERIF}|${status}`]?.list || []
        }
        loading={
          user.groupedList[
            `${USER_MODE.BANK_VERIF}|${status || VERIFY_STATUS.PENDING}`
          ]?.requesting
        }
        rowKey={(user: IUser) => user.id}
        pagination={{
          hideOnSinglePage: true,
          showSizeChanger: false,
          pageSize:
            user.groupedList[`${USER_MODE.BANK_VERIF}|${status}`]?.paging
              ?.limit,
          showLessItems: true,
          current:
            user.groupedList[`${USER_MODE.BANK_VERIF}|${status}`]?.paging
              ?.currentPage,
          total:
            user.groupedList[`${USER_MODE.BANK_VERIF}|${status}`]?.paging
              ?.totalData,
          onChange: onChangePage
        }}
      />
      <Form
        additionalHeader={
          <>
            <Row className='mb-3'>
              <Text className='text-weight-bold text-size-20'>
                Update info bank status as “Declined”?
              </Text>
            </Row>
            <Row className='mb-3'>
              <Text className='text-size-14'>
                If the bank info application is invalid. please fill the form
                below, so the applicant can review and fix their application
                data.
              </Text>
            </Row>
          </>
        }
        formName='form-bank-verif-reject'
        fields={fields}
        initialValues={{}}
        visible={visible}
        onCancel={() => setVisible(false)}
        okText='Submit'
        cancelText='Cancel'
        onFinish={(values) => {
          onAction(data?.id!, {
            reason: values?.reason,
            status: VERIFY_STATUS.DECLINED
          } as IUserBank)
          setVisible(false)
        }}
        okButtonProps={{
          className: 'bg-primary-main'
        }}
        cancelButtonProps={{
          className:
            'bg-primary-surface text-color-primary-main border-primary-surface'
        }}
      />
    </>
  )
}

export default BankVerification
