import { Col, Row, Avatar, Typography, Button, Modal, Image } from 'antd'
import * as React from 'react'
import IUser from '@cr/general/dist/interfaces/user'
import moment from 'moment'
import AUTH_PROVIDER_TYPE from '@cr/general/dist/constants/auth-provider-type'
import VERIFY_STATUS from '@cr/general/dist/constants/verify-status'

const { Text } = Typography

export const VERIFICATION_STATUS = (status, type: 'id-card' | 'bank-info') => {
  switch (status) {
    case VERIFY_STATUS.VERIFIED:
      return (
        <Text className='text-m-bold text-color-success-pressed'>
          {type === 'id-card' ? 'Verified' : 'Approved'}
        </Text>
      )
    case VERIFY_STATUS.PENDING:
      return <Text className='text-m-bold'>Requesting</Text>
    case VERIFY_STATUS.DECLINED:
      return (
        <Text className='text-m-bold text-color-danger-hover'>
          {type === 'id-card' ? 'Invalid' : 'Rejected'}
        </Text>
      )
    default:
      return <Text className='text-m-bold'>-</Text>
  }
}

const BankVerificationTableColumns: Array<any> = [
  {
    title: 'Talent Name',
    key: 'talentName',
    render: (record: IUser) => {
      return (
        <Row key={record.id}>
          <Col span={24}>
            <Row align='middle'>
              <Col flex='40px'>
                <Avatar src={record?.images?.profilePicture} size={32} />
              </Col>
              <Col flex='auto'>
                <Typography className='text-size-16 text-height-20'>
                  {record?.fullName}
                </Typography>
                <Text className='text-color-neutral-70'>
                  @{record.username}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    }
  },
  {
    title: 'Requested At',
    key: 'requestedAt',
    render: (record: IUser) => {
      return (
        <Row key={record.id}>
          <Text className='text-size-16 text-height-20'>
            {record?.bankAccountInfo?.submittedAt === 0 ||
            record.bankAccountInfo?.submittedAt === undefined
              ? '-'
              : moment
                  .unix(record.bankAccountInfo?.submittedAt || 0)
                  .format('DD MMM YY, HH:mm')}
          </Text>
        </Row>
      )
    }
  },
  {
    title: 'Bank Info',
    key: 'bankInfo',
    render: (record: IUser) => {
      return (
        <Row key={record.id} justify='start'>
          <Col span={24}>
            <Row>
              <Text className='text-size-16 text-height-20 text-weight-medium'>
                {record?.bankAccountInfo?.accountName}
              </Text>
            </Row>
            <Row>
              {record?.bankAccountInfo?.bank?.name ? (
                <Text className='text-size-14 text-height-18'>{` ${record?.bankAccountInfo?.bank?.name} - ${record?.bankAccountInfo?.accountNumber}`}</Text>
              ) : (
                <Text className='text-size-14 text-height-18'>
                  {record?.bankAccountInfo?.accountNumber}
                </Text>
              )}
            </Row>
          </Col>
        </Row>
      )
    }
  },
  {
    title: 'Email',
    key: 'email',
    render: (record: IUser) => {
      return (
        <Row key={record.id}>
          <Text className='text-size-16 text-height-20'>
            {
              record.userAuthProviders?.find(
                (x) => x.authProviderId === AUTH_PROVIDER_TYPE.EMAIL
              )?.authAccount
            }
          </Text>
        </Row>
      )
    }
  },
  {
    title: 'ID Card Status',
    key: 'idCardStatus',
    render: (record: IUser) => {
      return (
        <>
          {record.idCardInfo?.status === VERIFY_STATUS.VERIFIED ? (
            <Button
              type='link'
              className='p-0'
              onClick={() => {
                Modal.info({
                  icon: <></>,
                  title: 'Preview ID Card',
                  content: (
                    <Row>
                      <Image src={record.idCardInfo?.images} />
                    </Row>
                  ),
                  maskClosable: true,
                  closable: true,
                  className: 'disable-ant-modal-footer'
                })
              }}
            >
              <Text underline className='text-color-primary-main text-m-medium'>
                Verified
              </Text>
            </Button>
          ) : (
            VERIFICATION_STATUS(record.idCardInfo?.status, 'id-card')
          )}
        </>
      )
    }
  }
]

export default BankVerificationTableColumns
