import { IResult } from '@attn/general/dist/interfaces/api'
import { IChallenge } from '@cr/general/dist/interfaces'
import IRule from '@cr/general/dist/interfaces/rule'
import { message } from 'antd'
import moment from 'moment'

import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'
import ActionTypes from '../../constants/action-types'
import CHALLENGE_STATUS from '../../constants/challenge-status'
import Api from '../apis'
import Base from './base'

const GetList =
  (status: string, platformIds?: string, page?: number, limit?: number) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Challenge.GetList(status, platformIds, page, limit),
      () => {
        dispatch(
          action(ActionTypes.Challenge.CHALLENGE_GET_LIST_REQUEST, { status })
        )
      },
      (res: IResult<Array<IChallenge>>) => {
        dispatch(
          action(ActionTypes.Challenge.CHALLENGE_GET_LIST_SUCCESS, {
            list: res.data,
            paging: res.paging,
            status
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Challenge.CHALLENGE_GET_LIST_FAILED, {
            error,
            status
          })
        )
    )
  }

const GetDetail = (id: string) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Challenge.GetDetail(id),
    () => {
      dispatch(action(ActionTypes.Challenge.CHALLENGE_GET_DETAIL_REQUEST))
    },
    (res: IResult<Array<IChallenge>>) => {
      dispatch(
        action(ActionTypes.Challenge.CHALLENGE_GET_DETAIL_SUCCESS, {
          data: res.data
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Challenge.CHALLENGE_GET_DETAIL_FAILED, {
          error
        })
      )
  )
}

const Create = (data: IChallenge) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Challenge.Create(data),
    () => {
      dispatch(action(ActionTypes.Challenge.CHALLENGE_CREATE_REQUEST))
    },
    (res: IResult<Array<IChallenge>>) => {
      dispatch(
        action(ActionTypes.Challenge.CHALLENGE_CREATE_SUCCESS, {
          data: res.data
        })
      )
      message.success('Hypes successfully posted')
      setTimeout(
        () =>
          (window.location.href = `/hypes/?status=${
            data.startAt > moment(moment.now()).unix()
              ? CHALLENGE_STATUS.COMING_SOON
              : CHALLENGE_STATUS.ON_GOING
          }`),
        1000
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Challenge.CHALLENGE_CREATE_FAILED, {
          error
        })
      )
  )
}

const Update =
  (id: string, data: IChallenge) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Challenge.Update(id, data),
      () => {
        dispatch(action(ActionTypes.Challenge.CHALLENGE_UPDATE_REQUEST))
      },
      (res: IResult<Array<IChallenge>>) => {
        dispatch(
          action(ActionTypes.Challenge.CHALLENGE_UPDATE_SUCCESS, {
            data: res.data
          })
        )
        message.success('Hypes was updated')
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Challenge.CHALLENGE_UPDATE_FAILED, {
            error
          })
        )
    )
  }

const GetRules =
  (page?: number, limit?: number, isDefault?: boolean) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Challenge.GetRules(page, limit, isDefault),
      () => {
        dispatch(action(ActionTypes.Challenge.CHALLENGE_RULES_REQUEST))
      },
      (res: IResult<Array<IRule>>) => {
        dispatch(
          action(ActionTypes.Challenge.CHALLENGE_RULES_SUCCESS, {
            data: res.data
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Challenge.CHALLENGE_RULES_FAILED, {
            error
          })
        )
    )
  }

export default {
  GetList,
  GetDetail,
  Create,
  Update,
  GetRules
}
