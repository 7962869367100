export enum USER_MODE {
  UNDEFINED = 'undefined',
  ID_VERIF = 'idCardInfoStatus',
  BANK_VERIF = 'bankAccountInfoStatus'
}

export enum USER_UPDATE_MODE {
  ID_CARD_INFO = 'id-card-info',
  BANK_INFO = 'bank-account-info'
}

export const USER_MODE_MAP = {
  [USER_MODE.UNDEFINED]: USER_MODE.UNDEFINED,
  [USER_UPDATE_MODE.ID_CARD_INFO]: USER_MODE.ID_VERIF,
  [USER_UPDATE_MODE.BANK_INFO]: USER_MODE.BANK_VERIF
}
