import { combineReducers } from 'redux'

import IAuthState from '../../interfaces/states/auth'
import IWithdrawalState from '../../interfaces/states/withdrawal'
import IUserState from '../../interfaces/states/user'
import IChallengeState from '../../interfaces/states/challenge'
import IUploadState from '../../interfaces/states/upload'
import IUserChallengeSubmissionState from '../../interfaces/states/user-challenge-submission'
import IChallengeTierState from '../../interfaces/states/challenge-tier'
import IMetadataState from '../../interfaces/states/metadata'
import IAgencyFee from '../../interfaces/states/agency-fee'
import ITransactionState from '../../interfaces/states/transaction'

import auth from './auth'
import user from './user'
import withdrawal from './withdrawal'
import challenge from './challenge'
import userChallengeSubmission from './user-challenge-submission'
import upload from './upload'
import challengeTier from './challenge-tier'
import metadata from './metadata'
import agencyFee from './agency-fee'
import transaction from './transaction'
import paymentMethod from './payment-method'
import IPaymentMethodState from '../../interfaces/states/payment-method'
export interface ReduxState {
  auth: IAuthState
  user: IUserState
  withdrawal: IWithdrawalState
  challenge: IChallengeState
  userChallengeSubmission: IUserChallengeSubmissionState
  upload: IUploadState
  challengeTier: IChallengeTierState
  metadata: IMetadataState
  agencyFee: IAgencyFee
  transaction: ITransactionState
  paymentMethod: IPaymentMethodState
}

export const rootReducer = combineReducers<ReduxState>({
  auth,
  user,
  withdrawal,
  challenge,
  userChallengeSubmission,
  upload,
  challengeTier,
  metadata,
  agencyFee,
  transaction,
  paymentMethod
})
