/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Col, Input, Row, Typography } from 'antd'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import MainLayout from '../../components/layout'
import Actions from '../../store/actions'
import { ReduxState } from '../../store/reducers'
import IUserState from '../../interfaces/states/user'
import { USER_MODE } from '../../constants/user-mode'
import Users from './users'
import IBreadcrumbItem from '../../interfaces/breadcrumb-item'
import TableBalanceLog from './balance-log'

const { Search } = Input
const { Text } = Typography

interface IProps {
  GetUsers: (page?: number, limit?: number, search?: string) => void
  GetDetailBalanceLog: (id: string, page?: number, limit?: number) => void
  GetDetailByUsername: (username: string) => void
  user: IUserState
}

const UserPage = (props: IProps) => {
  const { GetUsers, GetDetailBalanceLog, GetDetailByUsername, user } = props
  const params: { id: string } = useParams()
  const [searchValue, setSearchValue] = React.useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    if (!params.id) GetUsers()
  }, [])

  useEffect(() => {
    if (params.id) GetDetailByUsername(params.id)
  }, [params.id])

  useEffect(() => {
    if (user.data?.id) GetDetailBalanceLog(user.data.id.toString())
  }, [user.data?.id])

  const LabelShowResult = () => {
    if (searchValue)
      return (
        <Row className='mv-1'>
          <Text className='text-color-second-dark-purple'>
            {`Showing ${
              user.groupedList[`${USER_MODE.UNDEFINED}|${USER_MODE.UNDEFINED}`]
                ?.paging?.totalData
            } result for `}
            <strong>{searchValue}</strong>
          </Text>
        </Row>
      )
    return <></>
  }

  const breadcrumbs: Array<IBreadcrumbItem> = [
    {
      label: 'Users',
      href: '/users'
    },
    {
      label: 'Balance Log'
    }
  ]

  return (
    <MainLayout
      header={!params.id ? 'Users' : undefined}
      breadcrumbs={params.id ? breadcrumbs : undefined}
    >
      {!params.id ? (
        <Row justify='end' className='mb-2'>
          <Col xs={24} md={6}>
            <Search
              placeholder='Search by name, username, or email'
              loading={
                !!searchValue &&
                user.groupedList[
                  `${USER_MODE.UNDEFINED}|${USER_MODE.UNDEFINED}`
                ]?.requesting
              }
              allowClear
              onSearch={(value) => {
                setSearchValue(value || undefined)
                GetUsers(undefined, undefined, value)
              }}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row>
        <Col span={24}>
          {params.id ? (
            <TableBalanceLog
              user={user}
              onChangePage={(pn) => {
                if (user.data?.id)
                  GetDetailBalanceLog(user.data?.id.toString(), pn)
              }}
            />
          ) : (
            <>
              <Row>
                <Col span={24}>{LabelShowResult()}</Col>
              </Row>
              <Users
                user={user}
                onChangePage={(pn) => {
                  GetUsers(
                    pn,
                    user.groupedList[
                      `${USER_MODE.UNDEFINED}|${USER_MODE.UNDEFINED}`
                    ]?.paging?.limit,
                    searchValue
                  )
                }}
              />
            </>
          )}
        </Col>
      </Row>
    </MainLayout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch: any) => ({
  GetUsers: (page?: number, limit?: number, search?: string) =>
    dispatch(Actions.User.GetList(undefined, undefined, page, limit, search)),
  GetDetailBalanceLog: (id: string, page?: number, limit?: number) =>
    dispatch(Actions.User.GetDetailBalanceLog(id, page, limit)),
  GetDetailByUsername: (username: string) =>
    dispatch(Actions.User.GetDetailByUsername(username))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPage)
