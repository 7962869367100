const SIGN_IN_WITH_EMAIL_REQUEST = 'SIGN_IN_WITH_EMAIL_REQUEST'
const SIGN_IN_WITH_EMAIL_SUCCESS = 'SIGN_IN_WITH_EMAIL_SUCCESS'
const SIGN_IN_WITH_EMAIL_FAILED = 'SIGN_IN_WITH_EMAIL_FAILED'

const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'

const SET_MENU_SUCCESS = 'SET_MENU_SUCCESS'

export default {
  SIGN_IN_WITH_EMAIL_REQUEST,
  SIGN_IN_WITH_EMAIL_SUCCESS,
  SIGN_IN_WITH_EMAIL_FAILED,

  SIGN_OUT_SUCCESS,

  SET_MENU_SUCCESS
}
