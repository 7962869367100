import { IUserBank, IIdCardInfo } from '@cr/general/dist/interfaces/user'
import { USER_UPDATE_MODE, USER_MODE } from '../../constants/user-mode'
import Base from './base'

export default {
  GetList: (
    status?: string,
    mode?: USER_MODE,
    page?: number,
    limit?: number,
    search?: string
  ): Promise<any> => {
    return Base('/account/v1').get(
      `admin/users/?page=${page || 1}&limit=${limit || 12}${
        status ? `&${mode}=${status}` : ''
      }${search ? `&search=${search}` : ''}`
    )
  },
  GetDetailBalanceLog: (id: string, page = 1, limit = 12): Promise<any> => {
    return Base('/account/v1').get(
      `admin/users/${id}/balance-log?page=${page}&limit=${limit}`
    )
  },
  GetDetailByUsername: (username: string): Promise<any> => {
    return Base('/account/v1').get(`public/users/${username}`)
  },
  Action: (
    id: number,
    data: IUserBank | IIdCardInfo,
    mode: USER_UPDATE_MODE
  ): Promise<any> => {
    return Base('/account/v1').put(`admin/users/${id}/${mode}`, data)
  }
}
