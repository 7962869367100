const env = process.env.REACT_APP_ADMIN_STAGE || 'local'

const CONFIGS = {
  LOCAL: {
    API_URL: 'https://creatify.dev.evos.gg/api',
    SENTRY_ENABLE: false,
    IS_DEBUG: true,
    STORAGE_SUFFIX: '_local',
    STORAGE_DOMAIN: 'localhost',
    CR_FRAGMENT_URL: 'https://creatify.dev.evos.gg/'
  },
  DEV: {
    API_URL: 'https://creatify.dev.evos.gg/api',
    SENTRY_ENABLE: true,
    IS_DEBUG: false,
    STORAGE_SUFFIX: '_dev',
    STORAGE_DOMAIN: '.dev.evos.gg',
    CR_FRAGMENT_URL: 'https://creatify.dev.evos.gg/'
  },
  STAG: {
    API_URL: 'https://creatify.stag.evos.gg/api',
    SENTRY_ENABLE: true,
    IS_DEBUG: false,
    STORAGE_SUFFIX: '_stag',
    STORAGE_DOMAIN: '.stag.evos.gg',
    CR_FRAGMENT_URL: 'https://creatify.stag.evos.gg/'
  },
  PROD: {
    API_URL: 'https://www.creatory.id/api',
    SENTRY_ENABLE: true,
    IS_DEBUG: false,
    STORAGE_SUFFIX: '',
    STORAGE_DOMAIN: 'creatory.id',
    CR_FRAGMENT_URL: 'https://creatory.id/'
  }
}

let CONFIG = CONFIGS.LOCAL
if (env === 'development') {
  CONFIG = CONFIGS.DEV
}
if (env === 'staging') {
  CONFIG = CONFIGS.STAG
}
if (env === 'production') {
  CONFIG = CONFIGS.PROD
}

export default CONFIG
