import React from 'react'
import { Row, Typography, TableColumnProps, Button, Col } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'

import { IChallenge } from '@cr/general/dist/interfaces'
import CHALLENGE_TYPE from '@cr/general/dist/constants/challenge-type'
import { capitalize } from 'lodash'
import General from '@attn/general'

const { Text } = Typography
const { Numberize } = General.Utils.Format

const ChallengeColumn = [
  {
    title: 'Hypes Name',
    dataIndex: 'name',
    key: 'name',
    render: (_text: any, c: IChallenge) => (
      <>
        <Row wrap={false} align='middle'>
          <Col>
            <Avatar size={48} shape='square' src={c.content?.image} />
          </Col>
          <Col>
            <Text className='ml-2 text-weight-bold text-size-16'>
              {c?.name}
            </Text>
          </Col>
        </Row>
      </>
    )
  },
  {
    title: 'Platform',
    dataIndex: 'challengeTypeId',
    key: 'challengeTypeId',
    render: (_text: any, c: IChallenge) => (
      <Text className='text-size-16'>{capitalize(c.platform?.name)}</Text>
    )
  },
  {
    title: 'Type',
    dataIndex: 'challengeTypeId',
    key: 'challengeTypeId',
    render: (_text: any, c: IChallenge) => (
      <Text className='text-size-16'>
        {capitalize(CHALLENGE_TYPE[c.challengeTypeId!]?.toString() || '')}
      </Text>
    )
  },
  {
    title: 'Total Prize',
    dataIndex: 'challengeTypeId',
    key: 'challengeTypeId',
    render: (_text: any, c: IChallenge) => (
      <Text className='text-size-16'>{Numberize(c.budget, '.', 'Rp ')}</Text>
    )
  },
  {
    title: 'Reward Claimed',
    dataIndex: 'rewardClaimed',
    key: 'rewardClaimed',
    render: (_text: any, c: IChallenge) => (
      <Text className='text-size-16'>
        {`${Math.ceil(((c.budget - c.remainingBudget) / c.budget) * 100)}%`}
      </Text>
    )
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'action',
    render: (_text: any, c: IChallenge) => {
      return (
        <Row gutter={[8, 0]}>
          <Col>
            <Button type='link' href={`/hypes/${c.id}`}>
              <Text
                underline
                className='text-size-16 text-weight-medium text-color-primary-purple'
              >
                Edit Hypes
              </Text>
            </Button>
          </Col>
          <Col>
            <Button type='link' href={`/hypes/${c.id}/milestones`}>
              <Text
                underline
                className='text-size-16 text-weight-medium text-color-primary-purple'
              >
                Edit Milestones
              </Text>
            </Button>
          </Col>
        </Row>
      )
    }
  }
] as Array<TableColumnProps<any>>

export default ChallengeColumn
