const WITHDRAWAL_ACTION_REQUEST = 'WITHDRAWAL_ACTION_REQUEST'
const WITHDRAWAL_ACTION_SUCCESS = 'WITHDRAWAL_ACTION_SUCCESS'
const WITHDRAWAL_ACTION_FAILED = 'WITHDRAWAL_ACTION_FAILED'

const WITHDRAWAL_GET_LIST_REQUEST = 'WITHDRAWAL_GET_LIST_REQUEST'
const WITHDRAWAL_GET_LIST_SUCCESS = 'WITHDRAWAL_GET_LIST_SUCCESS'
const WITHDRAWAL_GET_LIST_FAILED = 'WITHDRAWAL_GET_LIST_FAILED'

const WITHDRAWAL_GET_DETAIL_REQUEST = 'WITHDRAWAL_GET_DETAIL_REQUEST'
const WITHDRAWAL_GET_DETAIL_SUCCESS = 'WITHDRAWAL_GET_DETAIL_SUCCESS'
const WITHDRAWAL_GET_DETAIL_FAILED = 'WITHDRAWAL_GET_DETAIL_FAILED'

export default {
  WITHDRAWAL_ACTION_REQUEST,
  WITHDRAWAL_ACTION_SUCCESS,
  WITHDRAWAL_ACTION_FAILED,

  WITHDRAWAL_GET_LIST_REQUEST,
  WITHDRAWAL_GET_LIST_SUCCESS,
  WITHDRAWAL_GET_LIST_FAILED,

  WITHDRAWAL_GET_DETAIL_REQUEST,
  WITHDRAWAL_GET_DETAIL_SUCCESS,
  WITHDRAWAL_GET_DETAIL_FAILED
}
