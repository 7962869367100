import Auth from './auth'
import User from './user'
import Withdrawal from './withdrawal'
import Challenge from './challenge'
import UserChallengeSubmission from './user-challenge-submission'
import Upload from './upload'
import ChallengeTier from './challenge-tier'
import Metadata from './metadata'
import AgencyFee from './angency-fee'
import Transaction from './transaction'
import PaymentMethod from './payment-method'

export default {
  Auth,
  User,
  Withdrawal,
  Challenge,
  UserChallengeSubmission,
  Upload,
  ChallengeTier,
  Metadata,
  AgencyFee,
  Transaction,
  PaymentMethod
}
