import { IChallenge } from '@cr/general/dist/interfaces'
import Base from './base'

export default {
  GetList: (
    status: string,
    platformIds?: string,
    page?: number,
    limit?: number
  ): Promise<any> => {
    return Base('/challenge/v1').get(
      `admin/challenges/?page=${page || 1}&limit=${limit || 10}${
        status ? `&status=${status}` : ''
      }${platformIds ? `&platformIds=${platformIds}` : ''}`
    )
  },
  GetDetail: (id: string): Promise<any> => {
    return Base('/challenge/v1').get(`admin/challenges/${id}`)
  },
  Create: (data: IChallenge): Promise<any> => {
    return Base('/challenge/v1').post(`admin/challenges`, data)
  },
  Update: (id: string, data: IChallenge): Promise<any> => {
    return Base('/challenge/v1').put(`admin/challenges/${id}`, data)
  },
  GetRules: (page = 1, limit = 30, isDefault = true): Promise<any> => {
    return Base('/challenge/v1').get(
      `admin/rules?page=${page}&limit=${limit}&isDefault=${isDefault}`
    )
  }
}
