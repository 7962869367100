import React from 'react'
import ReactQuill from 'react-quill'
import CRGeneral from '@cr/general'

const { GenerateListHTML } = CRGeneral.Utils

interface IProps {
  value?: any
  id?: string
  onChange?: (value) => void
  placeholder?: string
  config?: {
    stepsParser: boolean
  }
}

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['clean']
  ]
}

const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'link']

const CustomEditor = (props: IProps) => {
  const { value, id, placeholder, onChange, config } = props

  const onEditorChange = (value: string) => {
    if (onChange) {
      onChange(value)
    }
  }

  if (value !== null) {
    return (
      <ReactQuill
        id={id}
        value={
          config?.stepsParser ? GenerateListHTML(value, 'ol') : value || ''
        }
        onChange={onEditorChange}
        theme='snow'
        modules={modules}
        formats={formats}
        placeholder={placeholder}
      />
    )
  }
  return null
}

export default CustomEditor
