const CHALLENGE_TIER_GET_LIST_REQUEST = 'CHALLENGE_TIER_GET_LIST_REQUEST'
const CHALLENGE_TIER_GET_LIST_SUCCESS = 'CHALLENGE_TIER_GET_LIST_SUCCESS'
const CHALLENGE_TIER_GET_LIST_FAILED = 'CHALLENGE_TIER_GET_LIST_FAILED'

const CHALLENGE_TIER_CREATE_REQUEST = 'CHALLENGE_TIER_CREATE_REQUEST'
const CHALLENGE_TIER_CREATE_SUCCESS = 'CHALLENGE_TIER_CREATE_SUCCESS'
const CHALLENGE_TIER_CREATE_FAILED = 'CHALLENGE_TIER_CREATE_FAILED'

const CHALLENGE_TIER_UPDATE_REQUEST = 'CHALLENGE_TIER_UPDATE_REQUEST'
const CHALLENGE_TIER_UPDATE_SUCCESS = 'CHALLENGE_TIER_UPDATE_SUCCESS'
const CHALLENGE_TIER_UPDATE_FAILED = 'CHALLENGE_TIER_UPDATE_FAILED'

const CHALLENGE_TIER_DELETE_REQUEST = 'CHALLENGE_TIERS_DELETE_REQUEST'
const CHALLENGE_TIER_DELETE_SUCCESS = 'CHALLENGE_TIERS_DELETE_SUCCESS'
const CHALLENGE_TIER_DELETE_FAILED = 'CHALLENGE_TIERS_DELETE_FAILED'

export default {
  CHALLENGE_TIER_GET_LIST_REQUEST,
  CHALLENGE_TIER_GET_LIST_SUCCESS,
  CHALLENGE_TIER_GET_LIST_FAILED,

  CHALLENGE_TIER_CREATE_REQUEST,
  CHALLENGE_TIER_CREATE_SUCCESS,
  CHALLENGE_TIER_CREATE_FAILED,

  CHALLENGE_TIER_UPDATE_REQUEST,
  CHALLENGE_TIER_UPDATE_SUCCESS,
  CHALLENGE_TIER_UPDATE_FAILED,

  CHALLENGE_TIER_DELETE_REQUEST,
  CHALLENGE_TIER_DELETE_SUCCESS,
  CHALLENGE_TIER_DELETE_FAILED
}
