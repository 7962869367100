import React, { useState } from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import {
  Row,
  Col,
  Image,
  Typography,
  List,
  Button,
  Avatar,
  Modal,
  Select,
  DatePicker,
  Divider
} from 'antd'
import PLATFORM_TYPE from '@cr/general/dist/constants/platform-type'
import CRGeneral from '@cr/general'
import ATTNGeneral from '@attn/general'

import IUserChallengeSubmissionState from '../../interfaces/states/user-challenge-submission'
import OptionStatus from '../../components/option-status'
import { useHistory, useParams } from 'react-router-dom'
import CHALLENGE_QC_STATUS from '@cr/general/dist/constants/challenge-qc-status'
import { IField } from '../../interfaces/models/form'
import DefaultForm from '../../components/form'
import { IUserChallengeSubmission } from '@cr/general/dist/interfaces'
import IChallengeState from '../../interfaces/states/challenge'
import { CRPlay } from '../../components/icons'
import EmbedTiktok from '../../components/embed-tiktok'
import EmbedInstagram from '../../components/embed-instagram'
import moment from 'moment'
import VERIFY_STATUS from '@cr/general/dist/constants/verify-status'
import ENV from '../../utils/environment'

const { GenerateListHTML } = CRGeneral.Utils
const { NumberUnit } = ATTNGeneral.Utils.Format
const { Text } = Typography

interface IProps {
  userChallengeSubmission: IUserChallengeSubmissionState
  status: string
  onVerify: (value: IUserChallengeSubmission) => void
  challenge: IChallengeState
  onChangePage: (page: number) => void
  onChangeOrder?: (sort: string) => void
  onChangeDate?: (e: any, string: any) => void
}

const fields = [
  {
    name: 'reason',
    label: 'Message',
    type: 'textarea',
    rules: [
      { required: true, message: 'Reason is required' },
      {
        transform: (val) => val && val.trim(),
        max: 160,
        message: 'Maximum 160 character'
      }
    ]
  }
] as Array<IField>

const HypeDetail = (props: IProps) => {
  const {
    userChallengeSubmission,
    status,
    onVerify,
    challenge,
    onChangePage,
    onChangeOrder,
    onChangeDate
  } = props
  const history = useHistory()
  const params: { id: string } = useParams()
  const [visible, setVisible] = useState<boolean>(false)
  const [data, setData] = useState<IUserChallengeSubmission | undefined>()
  const datas =
    userChallengeSubmission.groupedList[status || CHALLENGE_QC_STATUS.PENDING]

  const VERIFICATION_STATUS = (status) => {
    switch (status) {
      case VERIFY_STATUS.VERIFIED:
        return (
          <Text className='text-m-regular text-color-success-pressed'>
            Verified
          </Text>
        )
      case VERIFY_STATUS.PENDING:
        return <Text className='text-m-regular'>Requesting</Text>
      case VERIFY_STATUS.DECLINED:
        return (
          <Text className='text-m-regular text-color-danger-hover'>
            Invalid
          </Text>
        )
      default:
        return <Text className='text-m-regular'>-</Text>
    }
  }

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[16, 16]} className='mb-9'>
          <Col>
            <Image
              width={166}
              height={166}
              className='object-fit-cover border-radius-4'
              src={challenge.data?.content?.image}
              preview={false}
            />
          </Col>
          <Col flex='1 1'>
            <Row className='mb-2'>
              <Text className='text-size-20 text-weight-bold'>
                {challenge.data?.name}
              </Text>
            </Row>
            <Row>
              <Text className='text-color-second-dark-purple text-weight-medium'>
                Validation Steps:
              </Text>
            </Row>
            <Row>
              <span
                className='text-size-14 text-weight-medium challenge-steps'
                dangerouslySetInnerHTML={{
                  __html: GenerateListHTML(
                    challenge.data?.content?.steps || [],
                    'ol'
                  )
                }}
              />
            </Row>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Col>
            <OptionStatus
              count={
                userChallengeSubmission.groupedList[CHALLENGE_QC_STATUS.PENDING]
                  ?.paging?.totalData!
              }
              isPending={
                (status || CHALLENGE_QC_STATUS.PENDING) ===
                CHALLENGE_QC_STATUS.PENDING
              }
              onPending={() =>
                history.push(
                  `/qc-hypes/${params.id}/?status=${CHALLENGE_QC_STATUS.PENDING}`
                )
              }
              onComplete={() =>
                history.push(
                  `/qc-hypes/${params.id}/?status=${CHALLENGE_QC_STATUS.APPROVED},${CHALLENGE_QC_STATUS.DECLINED}`
                )
              }
              pendingText={'Need Response'}
              completeText={'Responded'}
            />
          </Col>
          <Col>
            <Row gutter={[8, 8]}>
              <Col>
                <DatePicker.RangePicker onChange={onChangeDate} allowClear />
              </Col>
              <Col>
                <Select
                  style={{ minWidth: 150 }}
                  placeholder='Sort by'
                  allowClear
                  onChange={onChangeOrder}
                >
                  <Select.Option value={'desc'}>Newest</Select.Option>
                  <Select.Option value={'asc'}>Oldest</Select.Option>
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <List
          className='mt-4'
          grid={{
            xs: 1,
            md: 4,
            gutter: 16
          }}
          pagination={{
            hideOnSinglePage: true,
            showLessItems: true,
            current: datas?.paging?.currentPage,
            pageSize: datas?.paging?.limit || 16,
            total: datas?.paging?.totalData,
            onChange: onChangePage
          }}
          dataSource={
            userChallengeSubmission.groupedList[
              status || CHALLENGE_QC_STATUS.PENDING
            ]?.list || []
          }
          renderItem={(cs) => {
            const UAP = cs.user?.userAuthProviders?.find(
              (uap) =>
                uap.authProvider?.externalCode?.toLowerCase() ===
                cs.challenge?.platform?.externalCode?.toLowerCase()
            )
            const stat = cs.viewCount / (UAP?.followersCount || 0)
            const SUBMISSION_INFO = [
              {
                label: 'Content Viewers',
                value: cs.viewCount,
                colorDots:
                  stat < 1.5
                    ? 'success-pressed'
                    : stat >= 1.5 && stat < 2
                    ? 'warning-main'
                    : 'danger-hover'
              },
              {
                label: 'Followers',
                value: NumberUnit(UAP?.followersCount || 0)
              },
              {
                label: 'Engagement Rate',
                value: `${
                  Math.round((cs.engagementRatePctg || 0) * 100) / 100
                }%`
              },
              {
                label: 'Requested at',
                value: moment
                  .unix((cs.createdAt as any) || '0')
                  .format('DD MMM YY, HH:mm')
              },
              {
                label: 'Last Crawling',
                value: cs.lastCrawledAt
                  ? moment
                      .unix((cs.lastCrawledAt as any) || '0')
                      .format('DD MMM YY, HH:mm')
                  : '-'
              }
            ]

            const USER_INFO = [
              {
                label: 'ID Card',
                value: VERIFICATION_STATUS(cs.user?.idCardInfo?.status)
              },
              {
                label: 'Bank Account',
                value: VERIFICATION_STATUS(cs.user?.bankAccountInfo?.status)
              }
            ]

            return (
              <List.Item>
                <Row className='box-shadow'>
                  <Col span={24}>
                    <Row className='challenge-submission-thumbnail'>
                      <span
                        className='icon-play-submission'
                        onClick={() => {
                          Modal.info({
                            icon: '',
                            content:
                              cs.challenge?.platform?.id ===
                              PLATFORM_TYPE.TIKTOK ? (
                                <EmbedTiktok mediaValue={cs.mediaValue || ''} />
                              ) : cs.challenge?.platform?.id ===
                                PLATFORM_TYPE.INSTAGRAM ? (
                                <EmbedInstagram
                                  mediaValue={cs.mediaValue || ''}
                                  username={cs.user?.username}
                                />
                              ) : (
                                <Row justify='center'>
                                  <Text>
                                    {cs.challenge?.platform?.name} media player
                                    currently not available.
                                  </Text>
                                </Row>
                              ),
                            okText: 'Close',
                            width: '650px',
                            onCancel: () => null
                          })
                        }}
                      >
                        <CRPlay />
                      </span>
                      <Image
                        width='100%'
                        height='100%'
                        src={cs?.mediaDetail?.thumbnailImage}
                        preview={false}
                        className='object-fit-cover'
                        style={{
                          borderTopLeftRadius: 4,
                          borderTopRightRadius: 4
                        }}
                      />
                    </Row>
                    <Col className='p-2'>
                      <Row
                        gutter={[16, 16]}
                        align='middle'
                        className='mb-1'
                        wrap={false}
                      >
                        <Col flex='32px'>
                          <Avatar
                            size={32}
                            src={cs?.user?.images?.profilePicture}
                          />
                        </Col>
                        <Col flex='1 1'>
                          <Row>
                            <Text className='text-size-20 text-weight-bold'>
                              {cs?.user?.fullName}
                            </Text>
                          </Row>
                          <Row>
                            <Text className='text-size-12 text-color-second-dark-purple'>
                              @{cs.user?.username}
                            </Text>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        align='middle'
                        className='use-pointer'
                        gutter={[8, 8]}
                        onClick={() =>
                          window.open(
                            `${ENV.CR_FRAGMENT_URL}${cs.user?.username}`
                          )
                        }
                      >
                        <Image
                          src={cs.challenge?.platform?.image}
                          preview={false}
                          height={18}
                          width={18}
                        />
                        <Col>
                          <Text className='text-color-primary-main text-s-medium'>
                            {UAP?.authDisplayName}
                          </Text>
                        </Col>
                      </Row>
                      <Divider className='mv-1' />
                      {SUBMISSION_INFO.map((si) => {
                        return (
                          <Row
                            key={si.label}
                            justify='space-between'
                            align='middle'
                          >
                            <Col>
                              <Text className='text-color-neutral-70 text-m-regular'>
                                {si.label}
                              </Text>
                            </Col>
                            <Col>
                              <Row align='middle'>
                                {si.colorDots && (
                                  <div
                                    className={`circle-stat bg-${si.colorDots} mr--5`}
                                  />
                                )}
                                <Text className='text-color-neutral-100 text-m-regular'>
                                  {si.value}
                                </Text>
                              </Row>
                            </Col>
                          </Row>
                        )
                      })}
                      <Divider className='mv-1' />
                      {USER_INFO.map((ui) => {
                        return (
                          <Row
                            key={ui.label}
                            justify='space-between'
                            align='middle'
                          >
                            <Col>
                              <Text className='text-color-neutral-70 text-m-regular'>
                                {ui.label}
                              </Text>
                            </Col>
                            <Col>
                              <Row align='middle'>{ui.value}</Row>
                            </Col>
                          </Row>
                        )
                      })}
                      <Divider className='mv-1' />
                      {cs.qcStatus === CHALLENGE_QC_STATUS.PENDING ? (
                        <Row gutter={[8, 8]}>
                          <Col flex='1 1'>
                            <Button
                              type='text'
                              block
                              className='border-danger-hover text-color-danger-hover'
                              icon={<CloseOutlined />}
                              onClick={() => {
                                setVisible(true)
                                setData(cs)
                              }}
                            >
                              Reject
                            </Button>
                          </Col>
                          <Col flex='1 1'>
                            <Button
                              type='text'
                              block
                              className='text-color-primary-main border-primary-main'
                              icon={<CheckOutlined />}
                              onClick={() => {
                                Modal.confirm({
                                  width: 560,
                                  icon: '',
                                  okText: 'Approve',
                                  okButtonProps: {
                                    type: 'primary',
                                    className: 'bg-primary-main'
                                  },
                                  cancelButtonProps: {
                                    className:
                                      'text-color-primary-main bg-primary-surface border-primary-surface'
                                  },
                                  content: (
                                    <>
                                      <Row className='mb-3'>
                                        <Text className='text-size-20 text-weight-bold'>{`Approved video of ${cs.user?.fullName}?`}</Text>
                                      </Row>
                                      <Row className='mb-3'>
                                        <Text className='text-size-16'>
                                          Hereby, I declare the video has
                                          followed the required steps to
                                          participate in the{' '}
                                          <strong>{cs.challenge?.name}</strong>{' '}
                                          hype.
                                        </Text>
                                      </Row>
                                    </>
                                  ),
                                  onOk: () => {
                                    const values = {
                                      ...({} as IUserChallengeSubmission),
                                      id: cs.id,
                                      reason: '',
                                      qcStatus: CHALLENGE_QC_STATUS.APPROVED
                                    }
                                    Modal.confirm({
                                      title:
                                        'Are you sure you want to proceed?',
                                      onOk: () => onVerify(values),
                                      onCancel: () => null
                                    })
                                  },
                                  onCancel: () => null
                                })
                              }}
                            >
                              Approve
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                      {cs.qcStatus &&
                      cs.qcStatus !== CHALLENGE_QC_STATUS.PENDING ? (
                        <Row justify='center'>
                          <Text
                            className={`text-size-16 text-weight-medium ${
                              cs.qcStatus === CHALLENGE_QC_STATUS.APPROVED
                                ? 'text-color-primary-purple'
                                : 'text-color-third-dark-purple'
                            }`}
                          >
                            {cs.qcStatus === CHALLENGE_QC_STATUS.APPROVED
                              ? 'Approved'
                              : 'Marked as Invalid'}
                          </Text>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Col>
                </Row>
              </List.Item>
            )
          }}
        />
      </Col>
      <DefaultForm
        visible={visible}
        formName='qc-hype'
        fields={fields}
        initialValues={{}}
        additionalHeader={
          <>
            <Row className='mb-3'>
              <Text className='text-size-20 text-weight-bold'>{`Mark as invalid submission?`}</Text>
            </Row>
            <Row className='mb-3'>
              <Text className='text-size-16'>
                If the data is invalid. Please fill out the form below, so the
                submitter can correct the data.
              </Text>
            </Row>
          </>
        }
        onCancel={() => setVisible(false)}
        onFinish={(val) => {
          const values = {
            ...({} as IUserChallengeSubmission),
            id: data?.id,
            reason: val.reason,
            qcStatus: CHALLENGE_QC_STATUS.DECLINED
          } as IUserChallengeSubmission
          onVerify(values)
          setVisible(false)
        }}
        okText='Reject'
        okButtonProps={{
          type: 'primary'
        }}
        cancelButtonProps={{
          className:
            'text-color-primary-main bg-primary-surface border-primary-surface'
        }}
      />
    </Row>
  )
}

export default HypeDetail
