import React, { Component } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { IS_COMMON, KEY } from '../../constants'

import { isAuthenticated } from '../../utils'

interface IProps extends RouteProps {
  auth?: number
}

const PrivateRoute = (props: IProps) => {
  const roleString = localStorage.getItem(KEY.ROLE) || '0'
  const role = parseInt(roleString, 10)

  if (!isAuthenticated()) return <Redirect to='/login' />

  if (props.auth! >= role)
    return <Route {...props} render={(rest) => <Component {...rest} />} />

  return <Redirect to={{ pathname: '/' }} />
}

PrivateRoute.defaultProps = {
  auth: IS_COMMON
}

export default PrivateRoute
