const USER_CHALLENGE_SUBMISSION_GET_LIST_REQUEST =
  'USER_CHALLENGE_SUBMISSION_GET_LIST_REQUEST'
const USER_CHALLENGE_SUBMISSION_GET_LIST_SUCCESS =
  'USER_CHALLENGE_SUBMISSION_GET_LIST_SUCCESS'
const USER_CHALLENGE_SUBMISSION_GET_LIST_FAILED =
  'USER_CHALLENGE_SUBMISSION_GET_LIST_FAILED'

const USER_CHALLENGE_SUBMISSION_ACTION_REQUEST =
  'USER_CHALLENGE_SUBMISSION_ACTION_REQUEST'
const USER_CHALLENGE_SUBMISSION_ACTION_SUCCESS =
  'USER_CHALLENGE_SUBMISSION_ACTION_SUCCESS'
const USER_CHALLENGE_SUBMISSION_ACTION_FAILED =
  'USER_CHALLENGE_SUBMISSION_ACTION_FAILED'

export default {
  USER_CHALLENGE_SUBMISSION_GET_LIST_REQUEST,
  USER_CHALLENGE_SUBMISSION_GET_LIST_SUCCESS,
  USER_CHALLENGE_SUBMISSION_GET_LIST_FAILED,

  USER_CHALLENGE_SUBMISSION_ACTION_REQUEST,
  USER_CHALLENGE_SUBMISSION_ACTION_SUCCESS,
  USER_CHALLENGE_SUBMISSION_ACTION_FAILED
}
