import { Input, Row, Col, Form } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { useCSVReader } from 'react-papaparse'
import { flatten } from 'lodash'
const Creator = ({ handleBulkUpload, handleEmailChange }) => {
  const { CSVReader } = useCSVReader()
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={14}>
          <Form.Item
            label={'Creator Email'}
            name='creator_email'
            rules={[
              {
                type: 'email',
                message: 'Creator email is not valid email.'
              }
            ]}
          >
            <Input
              onChange={handleEmailChange}
              style={{
                width: '100%'
              }}
            />
            <div className={'mt-1'}>
              <Link
                to='/template/creatory_add_email_sheet1_sample.csv'
                target='_blank'
                download
              >
                Download Sample
              </Link>
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Col span={10}>
        <CSVReader
          onUploadAccepted={(results: any) => {
            const resultData = results.data
            resultData.shift()
            handleBulkUpload(flatten(resultData))
          }}
        >
          {({
            getRootProps,
            acceptedFile,
            ProgressBar,
            getRemoveFileProps
          }: any) => (
            <>
              <Row>
                <Row>
                  <Col span={24} className={'mb-2'}>
                    <button type='button' {...getRootProps()}>
                      Browse file
                    </button>
                    <div>{acceptedFile && acceptedFile.name}</div>
                  </Col>
                  <Col span={24}>
                    <button {...getRemoveFileProps()}>Remove</button>
                  </Col>
                </Row>
              </Row>
              <ProgressBar />
            </>
          )}
        </CSVReader>
      </Col>
    </div>
  )
}
export default Creator
