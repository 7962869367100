/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Input, Row, Tabs, Typography } from 'antd'
import VERIFY_STATUS from '@cr/general/dist/constants/verify-status'
import { IUserBank, IIdCardInfo } from '@cr/general/dist/interfaces/user'
import * as React from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { parse } from 'query-string'

import MainLayout from '../../components/layout'
import Actions from '../../store/actions'
import { ReduxState } from '../../store/reducers'
import IUserState from '../../interfaces/states/user'
import IdCardVerification from './id-card-verification'
import BankVerification from './bank-account-verification'
import {
  USER_UPDATE_MODE,
  USER_MODE,
  USER_MODE_MAP
} from '../../constants/user-mode'
import OptionStatus from '../../components/option-status'

const { TabPane } = Tabs
const { Search } = Input
const { Text } = Typography

interface IMainProps {
  Verify: (
    id: number,
    data: IUserBank | IIdCardInfo,
    mode: USER_UPDATE_MODE
  ) => void
  GetVerificationList: (
    status: string,
    mode: USER_MODE,
    page?: number,
    limit?: number,
    search?: string
  ) => void
  user: IUserState
}

interface IParams {
  view?: string | undefined
  id?: string | undefined
}

const VerificationPage = (props: IMainProps) => {
  const { GetVerificationList, user, Verify } = props
  const history = useHistory()
  const qs: any = parse(useLocation().search.toString())
  const params: IParams = useParams()
  const [view, setView] = React.useState<string | undefined>(
    params.view || USER_UPDATE_MODE.ID_CARD_INFO
  )
  const [status, setStatus] = React.useState<string | undefined>(undefined)
  const [searchValue, setSearchValue] = React.useState<string | undefined>(
    undefined
  )
  const [isSearching, setIsSearching] = React.useState<boolean>(false)

  React.useEffect(() => {
    setView(params.view || USER_UPDATE_MODE.ID_CARD_INFO)
    setSearchValue(undefined)
    setIsSearching(false)
  }, [params.view])

  React.useEffect(() => {
    setStatus(undefined)
  }, [view])

  React.useEffect(() => {
    setStatus(qs.status)
  }, [qs.status])

  React.useEffect(() => {
    GetVerificationList(
      status || VERIFY_STATUS.PENDING.toString(),
      USER_MODE_MAP[view!],
      undefined,
      undefined,
      searchValue
    )
  }, [status, view])

  const onChange = (value) => {
    history.push(`/verifications/${value}`)
  }

  const SearchInput = (mode: USER_UPDATE_MODE) => {
    const useMode =
      mode === USER_UPDATE_MODE.ID_CARD_INFO
        ? USER_MODE.ID_VERIF
        : USER_MODE.BANK_VERIF

    const fetching = (value: string) => {
      GetVerificationList(
        status! || VERIFY_STATUS.PENDING.toString(),
        mode === USER_UPDATE_MODE.ID_CARD_INFO
          ? USER_MODE.ID_VERIF
          : USER_MODE.BANK_VERIF,
        undefined,
        undefined,
        value
      )
      setIsSearching(false)
    }
    return (
      <Search
        placeholder='Search by name, username, or email'
        value={searchValue}
        loading={
          !!searchValue &&
          user.groupedList[`${useMode}|${status || VERIFY_STATUS.PENDING}`]
            ?.requesting
        }
        allowClear
        onChange={(e) => {
          setIsSearching(true)
          setSearchValue(e.target.value)
          if (!e.target.value && !isSearching) fetching(e.target.value)
        }}
        onSearch={(value) => {
          fetching(value)
        }}
      />
    )
  }

  const LabelShowResult = (mode: USER_UPDATE_MODE) => {
    const useMode =
      mode === USER_UPDATE_MODE.ID_CARD_INFO
        ? USER_MODE.ID_VERIF
        : USER_MODE.BANK_VERIF
    if (searchValue && !isSearching)
      return (
        <Row className='mv-2'>
          <Text className='text-color-second-dark-purple'>
            {`Showing ${
              user.groupedList[`${useMode}|${status || VERIFY_STATUS.PENDING}`]
                ?.paging?.totalData
            } result for `}
            <strong>{searchValue}</strong>
          </Text>
        </Row>
      )
    return <></>
  }

  return (
    <MainLayout className='p-0'>
      <Tabs className='verification' activeKey={view} onChange={onChange}>
        <TabPane tab='ID Verification' key={USER_UPDATE_MODE.ID_CARD_INFO}>
          <Row gutter={[8, 0]} justify='space-between'>
            <Col>
              <OptionStatus
                count={
                  user.groupedList[
                    `${USER_MODE.ID_VERIF}|${VERIFY_STATUS.PENDING}`
                  ]?.paging?.totalData!
                }
                isPending={
                  (parseInt(status!, 10) || VERIFY_STATUS.PENDING) ===
                  VERIFY_STATUS.PENDING
                }
                onPending={() =>
                  history.push(
                    `/verifications/${view}/?status=${VERIFY_STATUS.PENDING}`
                  )
                }
                onComplete={() =>
                  history.push(
                    `/verifications/${view}/?status=${VERIFY_STATUS.VERIFIED},${VERIFY_STATUS.DECLINED}`
                  )
                }
              />
            </Col>
            <Col span={6}>{SearchInput(USER_UPDATE_MODE.ID_CARD_INFO)}</Col>
          </Row>
          {LabelShowResult(USER_UPDATE_MODE.ID_CARD_INFO)}
          <IdCardVerification
            user={user}
            status={status! || VERIFY_STATUS.PENDING.toString()}
            onAction={(id, value) =>
              Verify(id, value, USER_UPDATE_MODE.ID_CARD_INFO)
            }
            onChangePage={(pn) =>
              GetVerificationList(
                status || VERIFY_STATUS.PENDING.toString(),
                USER_MODE.ID_VERIF,
                pn,
                user.groupedList[
                  `${USER_MODE.ID_VERIF}|${VERIFY_STATUS.PENDING}`
                ]?.paging?.limit,
                searchValue
              )
            }
          />
        </TabPane>
        <TabPane tab='Bank Verification' key={USER_UPDATE_MODE.BANK_INFO}>
          <Row gutter={[8, 0]} justify='space-between'>
            <Col>
              <OptionStatus
                count={
                  user.groupedList[
                    `${USER_MODE.BANK_VERIF}|${VERIFY_STATUS.PENDING}`
                  ]?.paging?.totalData!
                }
                isPending={
                  (parseInt(status!, 10) || VERIFY_STATUS.PENDING) ===
                  VERIFY_STATUS.PENDING
                }
                onPending={() =>
                  history.push(
                    `/verifications/${view}/?status=${VERIFY_STATUS.PENDING}`
                  )
                }
                onComplete={() =>
                  history.push(
                    `/verifications/${view}/?status=${VERIFY_STATUS.VERIFIED},${VERIFY_STATUS.DECLINED}`
                  )
                }
              />
            </Col>
            <Col span={6}>{SearchInput(USER_UPDATE_MODE.BANK_INFO)}</Col>
          </Row>
          {LabelShowResult(USER_UPDATE_MODE.BANK_INFO)}
          <BankVerification
            user={user}
            status={status! || VERIFY_STATUS.PENDING.toString()}
            onChangePage={(pn) =>
              GetVerificationList(
                status! || VERIFY_STATUS.PENDING.toString(),
                USER_MODE.BANK_VERIF,
                pn,
                user.groupedList[
                  `${USER_MODE.BANK_VERIF}|${VERIFY_STATUS.PENDING}`
                ]?.paging?.limit,
                searchValue
              )
            }
            onAction={(id, value) =>
              Verify(id, value, USER_UPDATE_MODE.BANK_INFO)
            }
          />
        </TabPane>
      </Tabs>
    </MainLayout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch: any) => ({
  GetVerificationList: (
    status: string,
    mode: USER_MODE,
    page?: number,
    limit?: number,
    search?: string
  ) => dispatch(Actions.User.GetList(status, mode, page, limit, search)),
  Verify: (id: number, data: IUserBank | IIdCardInfo, mode: USER_UPDATE_MODE) =>
    dispatch(Actions.User.Action(id, data, mode))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerificationPage)
