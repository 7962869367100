import React, { useCallback, useEffect, useState } from 'react'
import IPartnershipState from '../../interfaces/states/IPartnership'
import { IPartnership } from '@cr/general/dist/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Row, Col, Typography, Modal, Form } from 'antd'
const { Text } = Typography
import Actions from '../../store/actions'
import SERVICE_ID_MAP from '../../constants/service'
import { RootState } from '../../store'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import Creator from './creator'

const AgencyFeeList = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false)
  const [visibleAddCreatorModal, setVisibleAddCreatorModal] =
    useState<boolean>(false)
  const [emailCreator, setEmailCreator] = useState<Array<string>>([])
  const [selected, setSelected] = useState<{
    id: number | undefined
    name: string | undefined
  } | null>(null)
  const [dataUpload, setDataUpload] = useState<Array<string>>([])
  const agencyFee = useSelector<RootState, Array<IPartnershipState>>(
    (state) => {
      return state?.agencyFee?.list
    }
  )
  const agencyFeeLoading = useSelector<RootState, boolean>((state) => {
    return state?.agencyFee?.requesting
  })
  const agencyFeePaging = useSelector<RootState, IPartnershipState>(
    (state) => state?.agencyFee
  )
  const dispatch = useDispatch()
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Agency Name',
      dataIndex: 'agency',
      key: 'agency',
      render: (data) => {
        return <div>{data.name}</div>
      }
    },
    {
      title: 'Partnership Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (data) => {
        if (data) {
          return (
            <div>{moment.unix(data || 0).format('DD MMM YYYY, HH:mm')}</div>
          )
        }
        return <div>-</div>
      }
    },
    {
      title: 'Commercial',
      dataIndex: 'partnershipConfigs',
      key: 'partnershipConfigs',
      render: (data) => {
        return (
          <div>
            {data.map((value, index) => {
              const { partnershipFeePctg, serviceFeePctg, serviceId } = value
              return (
                <Row key={index}>
                  <Col>
                    <Text>{SERVICE_ID_MAP[serviceId]?.label}</Text>
                  </Col>
                  <Col>
                    <Text>Agency Fee Pctg: {partnershipFeePctg}%</Text>
                  </Col>
                  <Col>
                    <Text>Creatory Fee Pctg :{serviceFeePctg}%</Text>
                  </Col>
                </Row>
              )
            })}
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: IPartnership) => {
        return (
          <div>
            <div>
              <a
                onClick={() => {
                  setSelected({
                    id: record.id,
                    name: record.name
                  })
                  setVisibleDeleteModal(true)
                }}
              >
                Delete Partnership
              </a>
            </div>
            <div>
              <a
                onClick={() => {
                  history.push(
                    `/agency-fee?tab=add&action=edit&id=${record.id}&agency_id=${record.agency.id}`
                  )
                }}
              >
                Edit Partnership
              </a>
            </div>
            <div>
              <a
                onClick={() => {
                  setSelected({
                    id: record.id,
                    name: record.name
                  })
                  setVisibleAddCreatorModal(true)
                }}
              >
                Add creator email
              </a>
            </div>
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    dispatch(Actions.AgencyFee.GetListPartnership())
  }, [dispatch])

  const onChangePage = (fn) => {
    dispatch(Actions.AgencyFee.GetListPartnership(fn.current))
  }

  const handleBulkUploadCreatorEmail = (data: Array<string>): void => {
    setDataUpload(data)
  }

  const handleEmailChange = useCallback((e) => {
    setEmailCreator([e.target.value])
  }, [])

  return (
    <div>
      <Modal
        title='Delete partnership'
        onOk={async () => {
          await dispatch(Actions.AgencyFee.DeletePartnership(selected?.id))
          await dispatch(Actions.AgencyFee.GetListPartnership())
          setVisibleDeleteModal(false)
        }}
        onCancel={() => {
          setVisibleDeleteModal(false)
        }}
        visible={visibleDeleteModal}
        okText='Ok'
        cancelText='Cancel'
      >
        <Text>Are you sure want to delete {selected?.name} ? </Text>
      </Modal>
      <Modal
        title='Add creator email'
        onOk={async () => {
          const whichEmail = dataUpload.length > 0 ? dataUpload : emailCreator
          dispatch(
            Actions.AgencyFee.CreateCreatorEmail(
              { creatorEmails: whichEmail },
              selected?.id
            )
          )
          setVisibleAddCreatorModal(false)
        }}
        onCancel={() => {
          setVisibleAddCreatorModal(false)
        }}
        visible={visibleAddCreatorModal}
        okText='Ok'
        cancelText='Cancel'
      >
        <Form form={form}>
          <Creator
            handleEmailChange={handleEmailChange}
            handleBulkUpload={handleBulkUploadCreatorEmail}
          />
        </Form>
      </Modal>
      <Table
        loading={agencyFeeLoading}
        pagination={{
          hideOnSinglePage: true,
          showLessItems: true,
          current: agencyFeePaging?.paging?.currentPage,
          pageSize: agencyFeePaging?.paging?.limit || 0,
          total: agencyFeePaging?.paging?.totalData
        }}
        columns={columns}
        dataSource={agencyFee || []}
        onChange={onChangePage}
      />
    </div>
  )
}

export default AgencyFeeList
