import { Row, Layout, Menu, Breadcrumb, Typography } from 'antd'
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons'
import * as React from 'react'
import { connect } from 'react-redux'
import CRGeneral from '@cr/general'
import METADATA from '@cr/general/dist/constants/metadata'
import { IMetadataAdvanceEditor } from '@cr/general/dist/interfaces/metadata'

import { IS_SUPER_ADMIN, KEY } from '../../constants/index'
import Menus from '../../constants/menu'
import IBreadcrumbItem from '../../interfaces/breadcrumb-item'
import IMenuItem from '../../interfaces/menu-item'
import Actions from '../../store/actions'
import history from '../../utils/history'
import { ReduxState } from '../../store/reducers'
import IMetadataState from '../../interfaces/states/metadata'

const { Header, Content, Footer, Sider } = Layout
const { SubMenu } = Menu
const { Text } = Typography
const { GetMetadataValue } = CRGeneral.Utils

export interface MainProps {
  metadata: IMetadataState
  children?: React.ReactNode
  header?: React.ReactNode
  SignOut: () => void
  SetMenu: (menu: Array<string>) => void
  activeSub: Array<string>
  className?: string
  breadcrumbs?: Array<IBreadcrumbItem>
  GetMetadata: () => void
}

export interface MainState {
  collapsed: boolean
  menus: Array<IMenuItem>
  activeMenu: string
}

class MainLayout extends React.Component<MainProps, MainState> {
  constructor(props: any) {
    super(props)
    const activeMenu = history.location.pathname
    this.state = {
      collapsed: false,
      menus: Menus,
      activeMenu
    }
  }

  toggle = () => {
    const { collapsed } = this.state
    this.setState({
      collapsed: !collapsed
    })
  }

  componentDidMount = async () => {
    await this.props.GetMetadata()
    const AdvanceMetadataEditor = GetMetadataValue<
      Array<IMetadataAdvanceEditor>
    >(METADATA.ADVANCE_METADATA_EDITOR, this.props.metadata.list || [])
    const updatedMenus = [
      ...this.state.menus,
      {
        title: 'Metadata',
        icon: <SettingOutlined />,
        auth: IS_SUPER_ADMIN,
        sub: [
          ...(AdvanceMetadataEditor?.map((ame) => ({
            title: ame.title,
            auth: IS_SUPER_ADMIN,
            link: `/advance-meta/${ame.key}`
          })) || []),
          {
            title: 'JSON (Dev Only)',
            auth: IS_SUPER_ADMIN,
            link: `/metadata`
          }
        ]
      }
    ]
    await this.setState({
      menus: updatedMenus
    })

    const path = history.location.pathname.split('/')
    if (path) {
      this.onOpenChange([`sub-${path[1]}`])
      this.setState({ activeMenu: `/${path[1]}` })
    }
  }

  onMenuClick = (menu: any) => {
    const { SignOut } = this.props

    if (menu.key === 'signout') {
      SignOut()
    } else {
      this.setState({
        activeMenu: menu.key
      })
      history.push(menu.key)
    }
  }

  onOpenChange = (openKeys: any) => {
    const { SetMenu, activeSub } = this.props
    const latestOpenKey = openKeys?.find(
      (key: string) => activeSub.indexOf(key) === -1
    )

    const matchedMenuKey = this.state.menus.find(
      (m) =>
        `sub-${(m.link || m.title).replace('/', '')}`.includes(latestOpenKey) ||
        m.sub?.find((sm) =>
          `sub-${(sm.link || sm.title).replace('/', '')}`.includes(
            latestOpenKey
          )
        )
    )
    SetMenu(
      matchedMenuKey
        ? [`sub-${matchedMenuKey.link || matchedMenuKey.title}`]
        : []
    )
  }

  renderMenu = (menus: Array<IMenuItem>) =>
    menus.map((m) => {
      const roleString = localStorage.getItem(KEY.ROLE) || '0'
      const role = parseInt(roleString, 10)
      if (m.auth >= role && !m.hidden) {
        if (!m.sub) {
          return (
            <Menu.Item key={`${m.link || m.title}`}>
              <a href={m.link}>
                <Row align='middle' wrap={false}>
                  {m.icon}
                  <Text className='text-size-16' ellipsis>
                    {m.title}
                  </Text>
                </Row>
              </a>
            </Menu.Item>
          )
        }
        return (
          <SubMenu
            key={`sub-${m.link || m.title}`}
            title={
              <Row align='middle' wrap={false}>
                {m.icon}
                <Text className='text-size-16' ellipsis>
                  {m.title}
                </Text>
              </Row>
            }
          >
            {this.renderMenu(m.sub)}
          </SubMenu>
        )
      }
      return null
    })

  render() {
    const { collapsed, menus, activeMenu } = this.state
    const { children, header, className, breadcrumbs, activeSub } = this.props
    return (
      <Layout className={`layout ${collapsed ? 'collapsed' : ''}`}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={this.toggle}
          width={240}
        >
          <div className='logo'>Creatory Admin</div>
          <Menu
            theme='dark'
            selectedKeys={[activeMenu]}
            openKeys={activeSub}
            mode='inline'
            onClick={this.onMenuClick}
            className='sider-menu mt-4'
            onOpenChange={this.onOpenChange}
          >
            {this.renderMenu(menus)}
            <Menu.Item key='signout'>
              <Row align='middle' wrap={false}>
                <LogoutOutlined />
                <Text className='text-size-16' ellipsis>
                  Signout
                </Text>
              </Row>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          {header || breadcrumbs ? (
            <Header className='header'>
              {header}
              {breadcrumbs ? (
                <Breadcrumb className='pv-2'>
                  {breadcrumbs &&
                    breadcrumbs.map((x, i) => (
                      <Breadcrumb.Item key={i.toString()} {...x}>
                        {x.label}
                      </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
              ) : (
                <></>
              )}
            </Header>
          ) : (
            <></>
          )}
          <Content className={`content ${className || ''}`}>{children}</Content>
          <Footer className='text-center'>
            Creatory Admin ©2021 created by ATTNTech
          </Footer>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = (state: ReduxState) => ({
  activeSub: state.auth.activeSub,
  metadata: state.metadata
})

const mapDispatchToProps = (dispatch: any) => ({
  SignOut: () => dispatch(Actions.Auth.Signout()),
  SetMenu: (menu: Array<string>) => dispatch(Actions.Auth.SetMenu(menu)),
  GetMetadata: () =>
    dispatch(Actions.Metadata.GetList(METADATA.ADVANCE_METADATA_EDITOR))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
