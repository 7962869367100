import CHALLENGE_QC_STATUS from '@cr/general/dist/constants/challenge-qc-status'
import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import BaseStateDefault from '../../constants/state'
import IUserChallengeSubmissionState from '../../interfaces/states/user-challenge-submission'

const initialState: IUserChallengeSubmissionState = {
  ...BaseStateDefault,
  groupedList: {}
}

const reducer: Reducer<any> = (
  state: IUserChallengeSubmissionState = initialState,
  action
): IUserChallengeSubmissionState => {
  switch (action.type) {
    case ActionTypes.UserChallengeSubmission
      .USER_CHALLENGE_SUBMISSION_GET_LIST_REQUEST: {
      const { status } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: true }
      }
      state.groupedList[status] = {
        ...state.groupedList[status],
        requesting: true,
        error: undefined
      }
      return {
        ...state
      }
    }
    case ActionTypes.UserChallengeSubmission
      .USER_CHALLENGE_SUBMISSION_ACTION_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.UserChallengeSubmission
      .USER_CHALLENGE_SUBMISSION_ACTION_SUCCESS: {
      const { submissionId, status } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: false }
      }

      state.groupedList[CHALLENGE_QC_STATUS.PENDING] = {
        ...state.groupedList[CHALLENGE_QC_STATUS.PENDING],
        list: state.groupedList[CHALLENGE_QC_STATUS.PENDING].list?.filter(
          (x) => x.id !== submissionId
        )
      }

      return {
        ...state,
        requesting: false
      }
    }
    case ActionTypes.UserChallengeSubmission
      .USER_CHALLENGE_SUBMISSION_GET_LIST_SUCCESS: {
      const { list, paging, status } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: false }
      }
      state.groupedList[status] = {
        ...state.groupedList[status],
        requesting: false,
        list,
        paging
      }

      return {
        ...state
      }
    }
    case ActionTypes.UserChallengeSubmission
      .USER_CHALLENGE_SUBMISSION_GET_LIST_FAILED: {
      const { status, error } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: false }
      }
      state.groupedList[status] = {
        ...state.groupedList[status],
        requesting: false,
        error: error
      }
      return {
        ...state
      }
    }
    case ActionTypes.UserChallengeSubmission
      .USER_CHALLENGE_SUBMISSION_ACTION_FAILED: {
      return {
        ...state,
        list: [],
        data: undefined,
        requesting: false,
        error: action.payload.error
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
