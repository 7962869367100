import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../constants/action-types'
import Api from '../apis'
import Base from './base'

const Create = (data: any) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Upload.Create(data),
    () => {
      dispatch(action(ActionTypes.Upload.UPLOAD_REQUEST))
    },
    () => {
      dispatch(action(ActionTypes.Upload.UPLOAD_SUCCESS))
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Upload.UPLOAD_FAILED, {
          error
        })
      )
  )
}

export default {
  Create
}
