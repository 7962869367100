const fieldServiceFee: Array<{
  label: string
  name: string
  col: number
  placeholder: string
  min: number
  max: number
}> = [
  {
    label: 'Creatory Fee 1 (%)',
    name: 'service_fee_1',
    col: 8,
    placeholder: 'Min 0 max 100',
    min: 0,
    max: 100
  },
  {
    label: 'Creatory Fee 2 (%)',
    name: 'service_fee_2',
    col: 8,
    placeholder: 'Min 0 max 100',
    min: 0,
    max: 100
  },
  {
    label: 'Creatory Fee 3 (%)',
    name: 'service_fee_3',
    col: 8,
    placeholder: 'Min 0 max 100',
    min: 0,
    max: 100
  }
]
const fieldPartnershipFee: Array<{
  label: string
  name: string
  col: number
  placeholder: string
  min: number
  max: number
}> = [
  {
    label: 'Agency Fee 1 (%)',
    name: 'partnership_fee_1',
    col: 8,
    placeholder: 'Min 0 max 100',
    min: 0,
    max: 100
  },
  {
    label: 'Agency Fee 2 (%)',
    name: 'partnership_fee_2',
    placeholder: 'Min 0 max 100',
    col: 8,
    min: 0,
    max: 100
  },
  {
    label: 'Agency Fee 3 (%)',
    name: 'partnership_fee_3',
    col: 8,
    placeholder: 'Min 0 max 100',
    min: 0,
    max: 100
  }
]

const productTypeFee: Array<{ label: string; name: string; col: number }> = [
  {
    label: 'Product Type 1',
    name: 'product_type_1',
    col: 8
  },
  {
    label: 'Product Type 2',
    name: 'product_type_2',
    col: 8
  },
  {
    label: 'Product Type 3',
    name: 'product_type_3',
    col: 8
  }
]

export { fieldPartnershipFee, fieldServiceFee, productTypeFee }
