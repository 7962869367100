import SERVICE_ID from '@cr/general/dist/constants/service'

const SERVICE_ID_MAP = {
  [SERVICE_ID.CHALLENGE]: {
    label: 'Hypes'
  },
  [SERVICE_ID.DONATION]: {
    label: 'Tipping'
  },
  [SERVICE_ID.SUBSCRIPTION]: {
    label: 'Subscription'
  },
  [SERVICE_ID.UNDEFINED]: {
    label: 'Withdrawal'
  }
}

export default SERVICE_ID_MAP
