import React, { useState } from 'react'
import { Button, Col, Modal, Row, Table, Typography } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import IWithdrawalState from '../../interfaces/states/withdrawal'
import WithdrawalRequestColumn, {
  WITHDRAWAL_STATUS
} from '../../constants/table/withdrawal-request'
import CASH_WITHDRAWAL_REQUEST_STATUS from '@cr/general/dist/constants/cash-withdrawal-request-status'
import { ICashWithdrawalRequest } from '@cr/general/dist/interfaces'
import { capitalize } from 'lodash'
import DefaultForm from '../../components/form'
import { IField } from '../../interfaces/models/form'
import SUMMARY_WITHDRAWAL_STATUS from '../../constants/summary-withdrawal-status'

const { Text } = Typography
interface IProps {
  withdrawal: IWithdrawalState
  status: string
  onChangePage: (page: number) => void
  onVerify: (data: ICashWithdrawalRequest) => void
  withAction?: boolean
}

const fields = [
  {
    name: 'reason',
    label: 'Message',
    type: 'textarea',
    rules: [
      { required: true, message: 'Reason is required' },
      {
        transform: (val) => val && val.trim(),
        max: 160,
        message: 'Maximum 160 character'
      }
    ]
  }
] as Array<IField>

const WithdrawalRequest = (props: IProps) => {
  const { withdrawal, status, onChangePage, onVerify, withAction } = props
  const [visible, setVisible] = useState<boolean>(false)
  const [tempWithdraw, setTempWithdraw] = useState<ICashWithdrawalRequest>()
  const data =
    withdrawal.groupedList[status || CASH_WITHDRAWAL_REQUEST_STATUS.PENDING]

  return (
    <>
      <Table
        loading={data?.requesting}
        columns={[
          ...WithdrawalRequestColumn,
          ...(withAction
            ? [
                {
                  title: 'Action',
                  dataIndex: 'action',
                  key: 'createdAt',
                  render: (_text: any, wr: ICashWithdrawalRequest) => (
                    <Row gutter={[8, 8]} wrap={false} align='middle'>
                      <Col>
                        <Button
                          type='ghost'
                          className='text-color-danger-hover border-danger-main'
                          icon={<CloseOutlined />}
                          onClick={() => {
                            setTempWithdraw(wr)
                            setVisible(true)
                          }}
                        />
                      </Col>
                      <Col>
                        <Button
                          type='ghost'
                          className='text-color-primary-main border-primary-main'
                          icon={<CheckOutlined />}
                          onClick={() => {
                            Modal.confirm({
                              width: 400,
                              icon: '',
                              okText: 'Approve',
                              okButtonProps: {
                                className: 'bg-primary-main'
                              },
                              cancelButtonProps: {
                                className:
                                  'text-color-primary-main bg-primary-surface border-primary-surface'
                              },
                              content: (
                                <>
                                  <Row className='mb-1'>
                                    <Text className='text-size-20 text-weight-bold'>
                                      Are you sure?
                                    </Text>
                                  </Row>
                                  <Row className='mb-1'>
                                    <Text className='text-size-16 text-color-neutral-80'>
                                      We will transfer the amount of money to
                                      the bank account below.
                                    </Text>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col flex='1 1'>
                                      <Row className='mb--5'>
                                        <Text className='text-color-neutral-70'>
                                          Talent Name
                                        </Text>
                                      </Row>
                                      <Row
                                        gutter={16}
                                        align='middle'
                                        className='mb-4'
                                      >
                                        <Col flex='1 1'>
                                          <Text className='text-size-16 text-m-bold text-color-dark-purple'>
                                            {wr?.user?.fullName}
                                          </Text>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col flex='1 1'>
                                      <Row className='mb--5'>
                                        <Text className='text-color-second-dark-purple'>
                                          Bank Info
                                        </Text>
                                      </Row>
                                      <Row>
                                        <Text className='text-size-16 text-m-bold text-color-dark-purple'>
                                          {`${wr?.userBankInfo?.accountName} - ${wr?.userBankInfo?.bank.name}`}
                                        </Text>
                                      </Row>
                                      <Row>
                                        <Text className='text-color-dark-purple text-m-bold'>{`${wr?.userBankInfo?.accountNumber}`}</Text>
                                      </Row>
                                    </Col>
                                  </Row>
                                </>
                              ),
                              onOk: () => {
                                const data = {
                                  ...wr,
                                  status:
                                    CASH_WITHDRAWAL_REQUEST_STATUS.APPROVED
                                } as ICashWithdrawalRequest
                                Modal.confirm({
                                  title:
                                    'Are you sure you want to proceed this request?',
                                  onOk: () => onVerify(data),
                                  onCancel: () => null
                                })
                              },
                              onCancel: () => null
                            })
                          }}
                        >
                          Approved
                        </Button>
                      </Col>
                    </Row>
                  )
                }
              ]
            : [
                {
                  title: 'Status',
                  render: (_text: any, wr: ICashWithdrawalRequest) => {
                    const Status = (status) => {
                      switch (status) {
                        case SUMMARY_WITHDRAWAL_STATUS.FAILED:
                          return `${capitalize(status)}: ${
                            wr.disburseFailureCodeFromGateway
                          }`
                        case SUMMARY_WITHDRAWAL_STATUS.DECLINED:
                          return `${capitalize(status)}: ${wr.reason}`
                        default:
                          return capitalize(WITHDRAWAL_STATUS(wr))
                      }
                    }
                    return (
                      <Text className='text-m-regular'>
                        {Status(WITHDRAWAL_STATUS(wr))}
                      </Text>
                    )
                  }
                }
              ])
        ]}
        dataSource={data?.list || []}
        rowKey={(wr: ICashWithdrawalRequest) => wr.id}
        pagination={{
          hideOnSinglePage: true,
          showLessItems: true,
          current: data?.paging?.currentPage,
          pageSize: data?.paging?.limit || 0,
          total: data?.paging?.totalData,
          onChange: onChangePage,
          showSizeChanger: false
        }}
      />
      <DefaultForm
        visible={visible}
        formName='rejet-withdraw'
        fields={fields}
        initialValues={{}}
        additionalHeader={
          <>
            <Row className='mb-3'>
              <Text className='text-size-20 text-weight-bold'>
                Reject Withdrawal
              </Text>
            </Row>
            <Row className='mb-3'>
              <Text className='text-size-16 text-color-neutral-80'>
                If the data is invalid, please fill the form below, so the
                applicant can review and fix their application data.
              </Text>
            </Row>
          </>
        }
        onCancel={() => setVisible(false)}
        onFinish={(value) => {
          const data = {
            ...tempWithdraw,
            reason: value.reason,
            status: CASH_WITHDRAWAL_REQUEST_STATUS.DECLINED
          } as ICashWithdrawalRequest
          onVerify(data)
          setVisible(false)
        }}
        okText='Submit'
        cancelButtonProps={{
          className:
            'bg-primary-surface border-primary-surface text-color-primary-main'
        }}
      />
    </>
  )
}

export default WithdrawalRequest
