import React from 'react'
import { Typography, TableColumnProps } from 'antd'

import General from '@attn/general'
import { IChallengeTier } from '@cr/general/dist/interfaces'

const { Text } = Typography
const { Numberize, NumberUnit } = General.Utils.Format

const HypesTierColumn = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    render: (_text: any, _c, i) => <Text>{i + 1}</Text>
  },
  {
    title: 'Minimum Views',
    dataIndex: 'minimumView',
    key: 'minimumView',
    render: (_text: any, c: IChallengeTier) => (
      <Text>{NumberUnit(c?.minimumView || 0)}</Text>
    ),
    editable: true
  },
  {
    title: 'Reward',
    dataIndex: 'reward',
    key: 'reward',
    render: (_text: any, c: IChallengeTier) => (
      <Text className='text-size-16'>
        {Numberize(c?.reward || 0, '.', 'Rp ')}
      </Text>
    ),
    editable: true
  }
] as Array<{ editable: boolean } & TableColumnProps<any>>

export default HypesTierColumn
