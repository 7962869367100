import React, { useEffect } from 'react'
import INDEX_TIKTOK_MEDIA from '../../constants/index-tiktok-media'
import { tiktokMediaSplitter } from '../../utils'

interface IProps {
  mediaValue: string
}

const EmbedTiktok = (props: IProps) => {
  const { mediaValue } = props

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://www.tiktok.com/embed.js'
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <blockquote
      className='tiktok-embed'
      cite={mediaValue}
      data-video-id={
        tiktokMediaSplitter(mediaValue)[INDEX_TIKTOK_MEDIA.VIDEO_ID]
      }
      style={{ maxWidth: 560, minWidth: 325 }}
    >
      <section>
        <a
          target='_blank'
          rel='noreferrer'
          title={tiktokMediaSplitter(mediaValue)[INDEX_TIKTOK_MEDIA.USERNAME]}
          href={`https://www.tiktok.com/${
            tiktokMediaSplitter(mediaValue)[INDEX_TIKTOK_MEDIA.USERNAME]
          }`}
        >
          {tiktokMediaSplitter(mediaValue)[INDEX_TIKTOK_MEDIA.USERNAME]}
        </a>
      </section>
    </blockquote>
  )
}

export default EmbedTiktok
