/* eslint-disable @typescript-eslint/no-inferrable-types */

import GeneralParticle from '@attn/general'
import { IResponse } from '@attn/general/dist/interfaces/api'
import { RcFile } from 'antd/lib/upload'
import DOMPurify from 'dompurify'

import STORAGE from '../constants/storage'
import UPLOAD_KIND from '../constants/upload-kind'
import UPLOAD_SERVICE from '../constants/upload_service'
import IUploadFile from '../interfaces/models/upload-file'
import ENV from '../utils/environment'

const { Utils } = GeneralParticle

const isAuthenticated = () => {
  const token = Utils.Storage.Get(STORAGE.TOKEN + ENV.STORAGE_SUFFIX)
  if (token) return true
  return false
}

const tiktokMediaSplitter = (value) => {
  const s = value.split('?')[0]
  return s.split('/')
}

const protocolRemover = (host: string) => {
  return host.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
}

const createObjectUrl = (src: string | RcFile | File): string => {
  if (typeof src === 'string') return src
  return URL.createObjectURL(src)
}

const uploadPromise: (
  file: Blob | string,
  serviceId: UPLOAD_SERVICE,
  kindId: UPLOAD_KIND,
  UploadFile: (data) => Promise<any>
) => Promise<Array<IUploadFile>> = (file, serviceId, kindId, UploadFile) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('files', file)
    formData.append('service', serviceId.toString())
    formData.append('serviceKind', kindId.toString())

    UploadFile(formData)
      .then((res: IResponse<Array<IUploadFile>>) => {
        resolve(
          res.data?.data?.map((x) => {
            x.serviceKindId = kindId
            return x
          }) || []
        )
      })
      .catch(() => {
        reject()
      })
  })
}

const isJsonString = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true && typeof JSON.parse(str) === 'object'
}

const Sanitize = (str: string, html: boolean = true) => {
  return (
    (DOMPurify.sanitize &&
      DOMPurify.sanitize(str, { USE_PROFILES: { html } })) ||
    ''
  )
}

const makeId = (length) => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const isUrl = (string) => {
  try {
    return Boolean(new URL(string))
  } catch (e) {
    return false
  }
}

export {
  isAuthenticated,
  tiktokMediaSplitter,
  protocolRemover,
  createObjectUrl,
  uploadPromise,
  isJsonString,
  Sanitize,
  makeId,
  isUrl
}
