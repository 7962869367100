/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import {
  Col,
  Row,
  Typography,
  Button,
  Table,
  TableColumnProps,
  Form,
  InputNumber,
  Popconfirm,
  message,
  Modal,
  Tooltip
} from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { DeleteOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons'
import General from '@attn/general'

import Layout from '../../components/layout'
import IBreadcrumbItem from '../../interfaces/breadcrumb-item'
import IChallengeState from '../../interfaces/states/challenge'
import IChallengeTierState from '../../interfaces/states/challenge-tier'
import Actions from '../../store/actions'
import { ReduxState } from '../../store/reducers'
import HypesTierColumn from '../../constants/table/hypes-tier'
import { IChallengeTier } from '@cr/general/dist/interfaces'
import { PlusOutlined } from '@ant-design/icons'
import { orderBy } from 'lodash'
import { CREye } from '../../components/icons'

const { Text } = Typography
const { Numberize, NumberUnit } = General.Utils.Format

interface IProps {
  GetDetailChallenge: (id: string) => void
  GetListChallengeTier: (challengeId: string) => void
  Create: (data: IChallengeTier) => Promise<any>
  Update: (id: string, data: IChallengeTier) => Promise<any>
  Delete: (id: string) => void
  challenge: IChallengeState
  challengeTier: IChallengeTierState
}

interface IItemRow extends IChallengeTier {
  key: number
  // not related to API
  cumulativeReward?: number
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: 'number' | 'currency'
  record: IItemRow
  index: number
  children: React.ReactNode
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}`
            }
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value?: any) =>
              `${
                value
                  ? Numberize(
                      value,
                      '.',
                      inputType === 'currency' ? 'Rp ' : undefined
                    )
                  : 0
              }`
            }
            parser={(value) =>
              value
                ? inputType === 'currency'
                  ? parseInt(value.replace('Rp ', '').replaceAll('.', ''), 10)
                  : parseInt(value.replaceAll('.', ''), 10)
                : 0
            }
            onKeyPress={(e) => {
              if (isNaN(parseInt(e.key))) {
                e.preventDefault()
              }
            }}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

const HypesMilestones = (props: IProps) => {
  const [form] = Form.useForm()
  const {
    GetDetailChallenge,
    GetListChallengeTier,
    Create,
    Update,
    Delete,
    challenge,
    challengeTier
  } = props
  const params: { hypeId: string } = useParams()
  const [list, setList] = useState<Array<IItemRow>>([])
  const [editingKey, setEditingKey] = useState<number | undefined>()
  const scale = [0]
  const scaleGap = 0.2

  let cumulativeTierReward = 0

  for (let i = 0; i < list.length; i++) {
    const usedTier = list[i]
    cumulativeTierReward += usedTier.reward
    usedTier.cumulativeReward = usedTier.reward
      ? cumulativeTierReward
      : usedTier.reward
  }

  const tierIndex =
    list.findIndex(
      (x, i) =>
        x.minimumView <= 0 &&
        (i < list.length - 1 && list[i + 1]
          ? 0 < list[i + 1].minimumView
          : true)
    ) + 1

  let lastScaleGap = 0.2
  for (let i = 0; i < list.length; i++) {
    scale.push(lastScaleGap)
    lastScaleGap += scaleGap
  }

  const GetListTier = (hypeId: string) => {
    GetListChallengeTier(hypeId)
  }

  useEffect(() => {
    GetDetailChallenge(params.hypeId)
    GetListTier(params.hypeId)
  }, [])

  useEffect(() => {
    setList(
      (orderBy(challengeTier.list, (x) => x.minimumView)?.map((c: any, i) => {
        c.key = i + 1
        return c
      }) as Array<IItemRow>) || []
    )
  }, [challengeTier.list])

  const isEditing = (record: IItemRow) => record.key === editingKey

  const onEdit = (record: Partial<IItemRow> & { key: React.Key }) => {
    form.setFieldsValue({ name: '', age: '', address: '', ...record })
    setEditingKey(record.key)
  }

  const onCancel = () => {
    setEditingKey(undefined)
  }

  const save = async (key: React.Key) => {
    try {
      const currData = (await form.validateFields()) as IItemRow
      const prevData = list.find((x: IItemRow) => x.key === key)
      if (prevData?.id) {
        const data = {
          ...prevData,
          ...currData,
          challengeId: parseInt(params.hypeId, 10)
        } as IChallengeTier
        Update(prevData.id.toString(), data).then((res) => {
          if (res?.data?.success) {
            GetListTier(params.hypeId)
            onCancel()
          }
        })
      } else {
        const data = {
          ...currData,
          challengeId: parseInt(params.hypeId, 10)
        } as IChallengeTier
        Create(data).then((res) => {
          if (res?.data?.success) {
            GetListTier(params.hypeId)
            onCancel()
          }
        })
      }
    } catch (errInfo) {
      message.error(`Validate Failed: ${errInfo}`)
    }
  }

  const onSubmit = (key: React.Key) => {
    Modal.confirm({
      content: 'Are you sure want to save?',
      onOk: () => save(key),
      onCancel: () => null
    })
  }

  const onDelete = (record: IItemRow) => {
    Modal.confirm({
      content: 'Are you sure want to delete this tier?',
      onOk: () => {
        if (record.id) {
          Delete(record.id.toString())
        } else {
          setList(list.filter((c) => c.id !== record.id))
        }
      },
      onCancel: () => null
    })
  }

  const columns = [
    ...HypesTierColumn,
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: any, record: IItemRow) => {
        const editable = isEditing(record)
        return editable ? (
          <>
            <Popconfirm title='Sure to cancel?' onConfirm={onCancel}>
              <Button type='text' className='text-color-second-dark-purple'>
                Cancel
              </Button>
            </Popconfirm>
            <Button
              className='ml-1 text-color-primary-purple text-weight-medium'
              type='text'
              loading={challengeTier.requesting}
              onClick={() => onSubmit(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Button>
          </>
        ) : (
          <>
            <Button
              type='text'
              disabled={
                !!editingKey ||
                challenge.data?.remainingBudget !== challenge.data?.budget
              }
              onClick={() => onEdit(record)}
            >
              <EditOutlined />
            </Button>
            <Button
              type='text'
              disabled={
                !!editingKey ||
                challenge.data?.remainingBudget !== challenge.data?.budget
              }
              onClick={() => onDelete(record)}
              className='text-color-status-red'
            >
              <DeleteOutlined />
            </Button>
          </>
        )
      }
    }
  ] as Array<{ editable: boolean } & TableColumnProps<any>>

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: IItemRow) => ({
        record,
        inputType: col.dataIndex === 'reward' ? 'currency' : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    }
  }) as any

  const breadcrumbs: Array<IBreadcrumbItem> = [
    { label: 'Hypes', href: '/hypes' },
    { label: challenge.data?.name || '', href: `/hypes/${challenge.data?.id}` },
    { label: 'Milestones' }
  ]

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Row className='hype-form' justify='center'>
        <Col span={18}>
          <Text className='text-size-24 text-weight-medium'>
            {challenge.data?.name}
          </Text>
          <div className='challenge-tier-container mb-6'>
            <div className='challenge-tier-prefix'>
              <CREye />
            </div>
            <div className='challenge-tier-wrapper'>
              {list.map((t, i) => {
                const scaleForTooltip = scale.slice(1)
                const scaleIndex = scaleForTooltip[i]
                return (
                  <div
                    key={t.id}
                    style={{
                      flex: scaleIndex
                    }}
                    className={`challenge-tier-item`}
                  >
                    <Tooltip
                      arrowPointAtCenter
                      placement='topRight'
                      title={
                        <div className='text-color-primary-purple'>
                          {i + 1 <= tierIndex ? (
                            <div className='challenge-tier-item-tooltip-badge'>
                              <CheckOutlined style={{ fontSize: 10 }} />
                            </div>
                          ) : (
                            <></>
                          )}
                          {`Rp `}{' '}
                          <strong>{NumberUnit(t.cumulativeReward || 0)}</strong>
                        </div>
                      }
                      visible={true}
                      overlayClassName={`challenge-tier-item-tooltip ${
                        i + 1 == tierIndex ? 'active' : ''
                      }`}
                      getPopupContainer={(triggerNode): HTMLElement =>
                        triggerNode.parentNode as HTMLElement
                      }
                    >
                      <Text
                        style={{ minWidth: '16px', textAlign: 'right' }}
                        className={`text-weight-medium text-color-${
                          i + 1 <= tierIndex ? 'white' : 'primary-purple'
                        }`}
                      >
                        {NumberUnit(t.minimumView)}
                      </Text>
                    </Tooltip>
                  </div>
                )
              })}
            </div>
          </div>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell
                }
              }}
              dataSource={list}
              columns={mergedColumns}
              pagination={{
                hideOnSinglePage: true,
                onChange: onCancel
              }}
            />
          </Form>
          <Button
            type='text'
            icon={<PlusOutlined />}
            className='text-color-primary-purple mt-2'
            disabled={
              !!editingKey ||
              challenge.data?.remainingBudget !== challenge.data?.budget
            }
            onClick={() => {
              if (list.find((c) => !c.reward || !c.minimumView)) {
                message.error(`minimum views and reward cant'be zero`)
              } else {
                setList([
                  ...list,
                  {
                    ...({
                      reward: 0,
                      minimumView: 0,
                      cumulativeReward: 0,
                      key: list?.length + 1
                    } as IItemRow)
                  }
                ])
              }
            }}
          >
            Add new milestone
          </Button>
        </Col>
      </Row>
    </Layout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  challenge: state.challenge,
  challengeTier: state.challengeTier
})

const mapDispatchToProps = (dispatch: any) => ({
  GetDetailChallenge: (id: string) => dispatch(Actions.Challenge.GetDetail(id)),
  GetListChallengeTier: (challengeId: string) =>
    dispatch(Actions.ChallengeTier.GetList(challengeId)),
  Create: (data: IChallengeTier) =>
    dispatch(Actions.ChallengeTier.Create(data)),
  Update: (id: string, data: IChallengeTier) =>
    dispatch(Actions.ChallengeTier.Update(id, data)),
  Delete: (id: string) => dispatch(Actions.ChallengeTier.Delete(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(HypesMilestones)
