const USER_ACTION_REQUEST = 'USER_ACTION_REQUEST'
const USER_ACTION_SUCCESS = 'USER_ACTION_SUCCESS'
const USER_ACTION_FAILED = 'USER_ACTION_FAILED'

const USER_GET_LIST_REQUEST = 'USER_GET_LIST_REQUEST'
const USER_GET_LIST_SUCCESS = 'USER_GET_LIST_SUCCESS'
const USER_GET_LIST_FAILED = 'USER_GET_LIST_FAILED'

const USER_GET_LIST_BALANCE_LOG_REQUEST = 'USER_GET_LIST_BALANCE_LOG_REQUEST'
const USER_GET_LIST_BALANCE_LOG_SUCCESS = 'USER_GET_LIST_BALANCE_LOG_SUCCESS'
const USER_GET_LIST_BALANCE_LOG_FAILED = 'USER_GET_LIST_BALANCE_LOG_FAILED'

const USER_GET_DETAIL_REQUEST = 'USER_GET_DETAIL_REQUEST'
const USER_GET_DETAIL_SUCCESS = 'USER_GET_DETAIL_SUCCESS'
const USER_GET_DETAIL_FAILED = 'USER_GET_DETAIL_FAILED'

export default {
  USER_ACTION_REQUEST,
  USER_ACTION_SUCCESS,
  USER_ACTION_FAILED,

  USER_GET_LIST_REQUEST,
  USER_GET_LIST_SUCCESS,
  USER_GET_LIST_FAILED,

  USER_GET_LIST_BALANCE_LOG_REQUEST,
  USER_GET_LIST_BALANCE_LOG_SUCCESS,
  USER_GET_LIST_BALANCE_LOG_FAILED,

  USER_GET_DETAIL_REQUEST,
  USER_GET_DETAIL_SUCCESS,
  USER_GET_DETAIL_FAILED
}
