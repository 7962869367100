import React from 'react'

interface IProps {
  size?: number
  fill?: string
}

const SvgIcon = (props: IProps) => {
  const { fill, size } = props

  return (
    <svg
      width={size || 31}
      height={size! + 7 || 38}
      viewBox='0 0 31 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 4.32666C0 1.162 3.50098 -0.749369 6.16303 0.961946L28.5993 15.3853C31.0486 16.9598 31.0486 20.5402 28.5993 22.1147L6.16304 36.5381C3.50099 38.2494 0 36.338 0 33.1733V4.32666Z'
        fill={fill || 'white'}
      />
    </svg>
  )
}

export default SvgIcon
