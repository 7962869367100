import { IResult } from '@attn/general/dist/interfaces/api'
import { IMetadata } from '@cr/general/dist/interfaces'
import { message } from 'antd'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../constants/action-types'
import Api from '../apis'
import Base from './base'

const GetList =
  (types?: string, page?: number, limit?: number) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Metadata.GetList(types, page, limit),
      () => {
        dispatch(action(ActionTypes.Metadata.METADATA_GET_LIST_REQUEST))
      },
      (res: IResult<Array<IMetadata>>) => {
        dispatch(
          action(ActionTypes.Metadata.METADATA_GET_LIST_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.Metadata.METADATA_GET_LIST_FAILED, {
            error
          })
        )
    )
  }

const Create = (data: IMetadata) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Metadata.Create(data),
    () => {
      dispatch(action(ActionTypes.Metadata.METADATA_CREATE_REQUEST))
    },
    (res: IResult<IMetadata>) => {
      dispatch(
        action(ActionTypes.Metadata.METADATA_CREATE_SUCCESS, {
          data: res.data
        })
      )
      message.success('Metadata successfully created')
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Metadata.METADATA_CREATE_FAILED, {
          error
        })
      )
  )
}

const Update = (data: IMetadata) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Metadata.Update(data),
    () => {
      dispatch(action(ActionTypes.Metadata.METADATA_UPDATE_REQUEST))
    },
    (res: IResult<IMetadata>) => {
      dispatch(
        action(ActionTypes.Metadata.METADATA_UPDATE_SUCCESS, {
          data: res.data
        })
      )
      message.success('Metadata successfully updated')
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Metadata.METADATA_UPDATE_FAILED, {
          error
        })
      )
  )
}

const Delete = (id: number) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.Metadata.Delete(id),
    () => {
      dispatch(action(ActionTypes.Metadata.METADATA_DELETE_REQUEST))
    },
    () => {
      dispatch(
        action(ActionTypes.Metadata.METADATA_DELETE_SUCCESS, {
          id
        })
      )
      message.success('metadata was deleted')
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.Metadata.METADATA_DELETE_FAILED, {
          error
        })
      )
  )
}
export default {
  GetList,
  Create,
  Update,
  Delete
}
