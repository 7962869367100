import { IChallengeTier } from '@cr/general/dist/interfaces'
import Base from './base'

export default {
  GetList: (
    challengeId?: string,
    page?: number,
    limit?: number
  ): Promise<any> => {
    return Base('/challenge/v1').get(
      `admin/challenge-tiers/${challengeId || ''}${
        page ? `/?page=${page}` : ''
      }${limit ? `&limit=${limit}` : ''}`
    )
  },
  Create: (data: IChallengeTier): Promise<any> => {
    return Base('/challenge/v1').post(`admin/challenge-tiers`, data)
  },
  Update: (id: string, data: IChallengeTier): Promise<any> => {
    return Base('/challenge/v1').put(`admin/challenge-tiers/${id}`, data)
  },
  Delete: (id: string): Promise<any> => {
    return Base('/challenge/v1').delete(`admin/challenge-tiers/${id}`)
  }
}
