import {
  DollarOutlined,
  IdcardOutlined,
  ThunderboltOutlined,
  UserOutlined,
  MoneyCollectOutlined,
  TransactionOutlined
} from '@ant-design/icons'
import React from 'react'
import IMenuItem from '../interfaces/menu-item'
import Verification from '../pages/verification'
import Withdrawal from '../pages/withdrawal'
import QcHypes from '../pages/qc-hypes'
import Hypes from '../pages/hypes'
import HypesMilestones from '../pages/hypes-milestones'
import Users from '../pages/users'
import AgencyFee from '../pages/agency-fee'
import Transaction from '../pages/transactions'

import { IS_SUPER_ADMIN } from './index'

const Menus: Array<IMenuItem> = [
  {
    title: 'Users',
    icon: <UserOutlined />,
    link: '/users',
    path: '/users/:id?',
    view: Users,
    auth: IS_SUPER_ADMIN
  },
  {
    title: 'Verification',
    icon: <IdcardOutlined />,
    link: '/verifications',
    path: '/verifications/:view?/:id?',
    view: Verification,
    auth: IS_SUPER_ADMIN
  },
  {
    title: 'Agency Fee',
    icon: <MoneyCollectOutlined />,
    link: '/agency-fee?tab=add',
    path: '/agency-fee/:view?/:id?',
    view: AgencyFee,
    auth: IS_SUPER_ADMIN
  },
  {
    title: 'Transaction',
    icon: <TransactionOutlined />,
    link: '/transactions',
    path: '/transactions/:type?/:receiptId?',
    view: Transaction,
    auth: IS_SUPER_ADMIN
  },
  {
    title: 'Withdrawal',
    icon: <DollarOutlined />,
    link: '/withdrawals',
    path: '/withdrawals/:id?',
    view: Withdrawal,
    auth: IS_SUPER_ADMIN
  },
  {
    title: 'QC Hypes',
    icon: <ThunderboltOutlined />,
    link: '/qc-hypes',
    path: '/qc-hypes/:id?',
    view: QcHypes,
    auth: IS_SUPER_ADMIN
  },
  {
    title: 'Hypes Milestones',
    icon: <ThunderboltOutlined />,
    path: '/hypes/:hypeId/milestones',
    view: HypesMilestones,
    auth: IS_SUPER_ADMIN,
    hidden: true
  },
  {
    title: 'Hypes',
    icon: <ThunderboltOutlined />,
    link: '/hypes',
    path: '/hypes/:id?',
    view: Hypes,
    auth: IS_SUPER_ADMIN
  }
]

export default Menus
