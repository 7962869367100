const PARTNERSHIP_DETAIL_REQUEST = 'PARTNERSHIP_DETAIL_REQUEST'
const PARTNERSHIP_DETAIL_SUCCESS = 'PARTNERSHIP_DETAIL_SUCCESS'
const PARTNERSHIP_DETAIL_FAILED = 'PARTNERSHIP_DETAIL_FAILED'

const AGENCY_FEE_CREATE_REQUEST = 'AGENCY_FEE_CREATE_REQUEST'
const AGENCY_FEE_CREATE_SUCCESS = 'AGENCY_FEE_CREATE_SUCCESS'
const AGENCY_FEE_CREATE_FAILED = 'AGENCY_FEE_CREATE_FAILED'

const AGENCY_FEE_DELETE_REQUEST = 'AGENCY_FEE_DELETE_REQUEST'
const AGENCY_FEE_DELETE_SUCCESS = 'AGENCY_FEE_DELETE_SUCCESS'
const AGENCY_FEE_DELETE_FAILED = 'AGENCY_FEE_DELETE_FAILED'

const PARTNERSHIP_UPDATE_REQUEST = 'PARTNERSHIP_UPDATE_REQUEST'
const PARTNERSHIP_UPDATE_SUCCESS = 'PARTNERSHIP_UPDATE_SUCCESS'
const PARTNERSHIP_UPDATE_FAILED = 'PARTNERSHIP_UPDATE_FAILED'

const PARTNERSHIP_GET_LIST_REQUEST = 'PARTNERSHIP_GET_LIST_REQUEST'
const PARTNERSHIP_GET_LIST_SUCCESS = 'PARTNERSHIP_GET_LIST_SUCCESS'
const PARTNERSHIP_GET_LIST_FAILED = 'PARTNERSHIP_GET_LIST_FAILED'

const CREATE_PARTNER_SHIP_REQUEST = 'CREATE_PARTNER_SHIP_REQUEST'
const CREATE_PARTNER_SHIP_SUCCESS = 'CREATE_PARTNER_SHIP_SUCCESS'
const CREATE_PARTNER_SHIP_FAILED = 'CREATE_PARTNER_SHIP_FAILED'

const CREATE_PARTNER_SHIP_CONFIG_REQUEST = 'CREATE_PARTNER_SHIP_CONFIG_REQUEST'
const CREATE_PARTNER_SHIP_CONFIG_SUCCESS = 'CREATE_PARTNER_SHIP_CONFIG_SUCCESS'
const CREATE_PARTNER_SHIP_CONFIG_FAILED = 'CREATE_PARTNER_SHIP_CONFIG_FAILED'

const PARTNERSHIP_DELETE_REQUEST = 'PARTNERSHIP_DELETE_REQUEST'
const PARTNERSHIP_DELETE_SUCCESS = 'PARTNERSHIP_DELETE_SUCCESS'
const PARTNERSHIP_DELETE_FAILED = 'PARTNERSHIP_DELETE_FAILED'

const PARTNERSHIP_CREATE_EMAIL_REQUEST = 'PARTNERSHIP_CREATE_EMAIL_REQUEST'
const PARTNERSHIP_CREATE_EMAIL_SUCCESS = 'PARTNERSHIP_DELETE_EMAIL_SUCCESS'
const PARTNERSHIP_CREATE_EMAIL_FAILED = 'PARTNERSHIP_DELETE_EMAIL_FAILED'

export default {
  PARTNERSHIP_DELETE_REQUEST,
  PARTNERSHIP_DELETE_SUCCESS,
  PARTNERSHIP_DELETE_FAILED,

  PARTNERSHIP_CREATE_EMAIL_REQUEST,
  PARTNERSHIP_CREATE_EMAIL_SUCCESS,
  PARTNERSHIP_CREATE_EMAIL_FAILED,

  PARTNERSHIP_GET_LIST_REQUEST,
  PARTNERSHIP_GET_LIST_SUCCESS,
  PARTNERSHIP_GET_LIST_FAILED,

  PARTNERSHIP_DETAIL_REQUEST,
  PARTNERSHIP_DETAIL_SUCCESS,
  PARTNERSHIP_DETAIL_FAILED,

  AGENCY_FEE_CREATE_REQUEST,
  AGENCY_FEE_CREATE_SUCCESS,
  AGENCY_FEE_CREATE_FAILED,

  CREATE_PARTNER_SHIP_REQUEST,
  CREATE_PARTNER_SHIP_SUCCESS,
  CREATE_PARTNER_SHIP_FAILED,

  CREATE_PARTNER_SHIP_CONFIG_REQUEST,
  CREATE_PARTNER_SHIP_CONFIG_SUCCESS,
  CREATE_PARTNER_SHIP_CONFIG_FAILED,

  PARTNERSHIP_UPDATE_REQUEST,
  PARTNERSHIP_UPDATE_SUCCESS,
  PARTNERSHIP_UPDATE_FAILED,

  AGENCY_FEE_DELETE_REQUEST,
  AGENCY_FEE_DELETE_SUCCESS,
  AGENCY_FEE_DELETE_FAILED
}
