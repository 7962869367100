import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import IAuthState from '../../interfaces/states/auth'


const initialState: IAuthState = {
  activeSub: [],
  requesting: false
}

const reducer: Reducer<any> = (state = initialState, action) => {
  switch (action.type) {
    /**
     * This contain request from get list partnership and also
     * create partnership data and partnership config also
     * delete and edit data for partnership
     */
    case ActionTypes.AgencyFee.PARTNERSHIP_DETAIL_REQUEST:
    case ActionTypes.AgencyFee.PARTNERSHIP_GET_LIST_REQUEST:
    case ActionTypes.AgencyFee.PARTNERSHIP_DELETE_REQUEST:
    case ActionTypes.AgencyFee.CREATE_PARTNER_SHIP_REQUEST:
    case ActionTypes.AgencyFee.CREATE_PARTNER_SHIP_CONFIG_REQUEST:
    {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }

    case ActionTypes.AgencyFee.PARTNERSHIP_DETAIL_SUCCESS:
    case ActionTypes.AgencyFee.CREATE_PARTNER_SHIP_SUCCESS:
    case ActionTypes.AgencyFee.CREATE_PARTNER_SHIP_CONFIG_SUCCESS:
    case ActionTypes.AgencyFee.PARTNERSHIP_DELETE_SUCCESS:
    {
      return {
        ...state,
      }
    }

    case ActionTypes.AgencyFee.PARTNERSHIP_GET_LIST_SUCCESS: {
      const { list, paging } = action.payload
      return {
        ...state,
        requesting: false,
        error: undefined,
        list,
        paging,
      }
    }
    case ActionTypes.AgencyFee.PARTNERSHIP_DETAIL_FAILED:
    case ActionTypes.AgencyFee.PARTNERSHIP_GET_LIST_FAILED:
    case ActionTypes.AgencyFee.CREATE_PARTNER_SHIP_FAILED:
    case ActionTypes.AgencyFee.CREATE_PARTNER_SHIP_CONFIG_FAILED:
    case ActionTypes.AgencyFee.PARTNERSHIP_DELETE_FAILED:
    {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
