/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Typography, Tag, Divider, Avatar, Image } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import General from '@attn/general'
import PAYMENT_STATUS from '@cr/general/dist/constants/payment-status'
import AUTH_PROVIDER_TYPE from '@cr/general/dist/constants/auth-provider-type'

import ITransactionState from '../../interfaces/states/transaction'
import InnerHTML from '../../components/inner-html'
import ENV from '../../utils/environment'

const { Text, Link } = Typography
const { Numberize } = General.Utils.Format
interface IProps {
  transaction: ITransactionState
}

const TransactionDetail = (props: IProps) => {
  const { transaction } = props
  const data = transaction.data
  const [countdown, setCountdown] = useState<string>()

  useEffect(() => {
    const futureTime = data?.expiredAt || 0
    const currentTime = moment().unix()
    const diffTime = futureTime - currentTime
    let duration: any = moment.duration(diffTime * 1000, 'milliseconds')
    const interval = 1000

    setInterval(() => {
      duration = moment.duration(duration - interval, 'milliseconds')
      setCountdown(
        `${duration.days() ? `${duration.days()} Day` : ''} ${
          duration.hours() + ':' + duration.minutes() + ':' + duration.seconds()
        }`
      )
    }, interval)
  }, [])

  const Status = (data) => {
    switch (data?.status) {
      case PAYMENT_STATUS.PENDING:
        return (
          <Tag className='text-color-primary-main bg-primary-surface border-primary-main text-s-medium'>
            Pending
          </Tag>
        )
      case PAYMENT_STATUS.PAID:
        return (
          <Tag className='text-color-success-pressed bg-success-surface border-success-main text-s-medium'>
            Success
          </Tag>
        )
      case PAYMENT_STATUS.FAILED:
      case PAYMENT_STATUS.EXPIRED:
        return (
          <Tag className='text-color-danger-main bg-danger-surface border-danger-main text-s-medium'>
            {_.capitalize(data.status)}
          </Tag>
        )
      default:
        return <></>
    }
  }

  const PAYMENT_INFO = [
    {
      label: 'Tanggal Transaksi',
      value: moment.unix(data?.createdAt || 0).format('DD MMM YYYY, HH:mm')
    },
    {
      label: 'Type',
      value: data?.subscriptionTier ? 'Subscription' : 'Tipping'
    },
    {
      label: 'Payment Method',
      value: data?.paymentMethod.name
    },
    {
      label: 'Transaction Status',
      value: Status(data)
    },
    {
      label: data?.invoice,
      href: data?.invoiceURL
    }
  ]

  const FANS_INFO = [
    {
      label: 'Name',
      value: data?.subscriptionTier ? data?.user?.fullName : data?.senderName
    },
    {
      label: 'Email',
      value: data?.subscriptionTier
        ? data?.user?.userAuthProviders?.find(
            (x) => x.authProviderId === AUTH_PROVIDER_TYPE.EMAIL
          )?.authAccount
        : data?.senderEmail
    },
    {
      label: 'Account Type',
      value: data?.isAnonymous ? 'Guest' : 'Registered'
    }
  ]

  const ContentSubscription = () => {
    const CONTENT_INFO_SUBS = [
      {
        label: 'Level',
        value: data?.subscriptionTier?.content?.name
      },
      {
        label: 'Total Harga',
        value: `IDR ${Numberize(data?.total || 0, '.')}`
      },
      {
        label: 'Start From',
        value: data?.userSubscription?.subscribedAt
          ? moment
              .unix(data?.userSubscription?.subscribedAt)
              .format('DD MMMM YYYY')
          : '-'
      },
      {
        label: 'End At',
        value: data?.userSubscription?.expiredAt
          ? moment
              .unix(data?.userSubscription?.expiredAt)
              .format('DD MMMM YYYY')
          : '-'
      }
    ]
    return (
      <Col
        className='p-2 border-primary-border border-radius-4'
        style={{ background: 'rgba(245, 236, 254, 0.2)', border: '1px solid' }}
      >
        <Row gutter={[24, 16]} className='mb-3'>
          <Col>
            <Row gutter={[16, 16]}>
              <Col>
                <Avatar
                  src={data?.subscriptionTier?.user?.images?.profilePicture}
                  size={60}
                />
              </Col>
              <Col>
                <Text className='text-m-bold'>
                  {data?.subscriptionTier?.user?.fullName}
                </Text>
                <br />
                <Link
                  className='text-s-medium text-color-neutral-100'
                  target='_blank'
                  href={`${ENV.CR_FRAGMENT_URL}${data?.subscriptionTier?.user?.username}`}
                >
                  @{data?.subscriptionTier?.user?.username}
                </Link>
              </Col>
            </Row>
          </Col>
          <Col span={16}>
            <Row gutter={[16, 16]}>
              {CONTENT_INFO_SUBS.map((c) => {
                return (
                  <Col key={c.label} xs={24} md={12}>
                    <Text className='text-m-medium text-color-neutral-70'>
                      {c.label}
                    </Text>
                    <Row className='mt--5'>
                      <Text className='text-m-bold'>{c.value}</Text>
                    </Row>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
        <Text className='text-m-bold'>Level Information</Text>
        <Row className='mt-2' gutter={[16, 16]}>
          <Col xs={24} md={6}>
            <Image
              src={data?.subscriptionTier?.content?.image}
              preview={false}
              width='100%'
              height={150}
              style={{ objectFit: 'cover' }}
              className='border-radius-4'
            />
          </Col>
          <Col xs={24} md={8}>
            <Row className='mb-2'>
              <Text className='text-m-bold'>Description</Text>
            </Row>
            <InnerHTML
              style={{ wordBreak: 'break-word' }}
              className='text-color-neutral-80 text-s-regular'
              content={data?.subscriptionTier?.content?.description || ''}
            />
          </Col>
          <Col xs={24} md={8}>
            <Row className='mb-2'>
              <Text className='text-m-bold'>Benefit</Text>
            </Row>
            {data?.subscriptionTier?.subscriptionTierBenefits?.map((b) => {
              return (
                <Row key={b.id} className='mb-1' align='middle'>
                  <Image
                    src={`${ENV.CR_FRAGMENT_URL}icons/benefit/active/${
                      b.benefit?.content?.icon || 'font'
                    }.svg`}
                    preview={false}
                  />
                  <Text className='ml-1 text-s-medium'>
                    {b.benefit?.content?.name}
                  </Text>
                </Row>
              )
            })}
          </Col>
        </Row>
      </Col>
    )
  }

  const ContentTipping = () => {
    return (
      <Col
        className='p-2 border-primary-border border-radius-4'
        style={{ background: 'rgba(245, 236, 254, 0.2)', border: '1px solid' }}
      >
        <Row gutter={[24, 16]} className='mb-3'>
          <Col xs={24} md={6}>
            <Row gutter={[16, 16]}>
              <Col>
                <Avatar
                  src={data?.receiver?.images?.profilePicture}
                  size={60}
                />
              </Col>
              <Col>
                <Text className='text-m-bold'>{data?.receiver?.fullName}</Text>
                <br />
                <Link
                  className='text-s-medium text-color-neutral-100'
                  target='_blank'
                  href={`${ENV.CR_FRAGMENT_URL}${data?.receiver?.username}`}
                >
                  @{data?.receiver?.username}
                </Link>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <Text className='text-s-medium text-color-neutral-70'>
                Tipping Message
              </Text>
            </Row>
            <Text className='text-m-bold'>{data?.message}</Text>
          </Col>
          <Col span={8}>
            <Row>
              <Text className='text-s-medium text-color-neutral-70'>Total</Text>
            </Row>
            <Text className='text-m-bold'>
              IDR {Numberize(data?.total || 0, '.')}
            </Text>
          </Col>
        </Row>
      </Col>
    )
  }

  return (
    <Row justify='center'>
      <Col xs={24} md={20}>
        <Row className='mb-3'>
          <Text className='heading-m-medium'>Transaction Detail</Text>
        </Row>
        {data?.status === PAYMENT_STATUS.PENDING ? (
          <Row
            className='bg-warning-surface p-1 border-radius-4 mb-3'
            align='middle'
            justify='space-between'
          >
            <Col>
              <Text className='text-m-regular text-color-neutral-70'>
                Waiting for Payment before:{' '}
                <Text className='text-m-bold text-color-neutral-100'>
                  {moment
                    .unix(data?.expiredAt || 0)
                    .format('dddd, DD MMMM YYYY, hh:mm')}{' '}
                  WIB
                </Text>
              </Text>
            </Col>
            <Col>
              <Text className='text-s-bold text-color-danger-main mh-2'>
                {countdown}
              </Text>
            </Col>
          </Row>
        ) : undefined}
        <Row gutter={[16, 16]} justify='space-between'>
          {PAYMENT_INFO.map((p) => {
            return (
              <Col key={p.label}>
                <Text className='text-m-medium text-color-neutral-70'>
                  {p.label}
                </Text>
                <Row className='mt-1'>
                  {p.href ? (
                    <a
                      className='text-m-bold text-color-primary-main'
                      href={p.href}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Download Invoice
                    </a>
                  ) : typeof p.value === 'string' ? (
                    <Text className='text-m-bold'>{p.value}</Text>
                  ) : (
                    p.value
                  )}
                </Row>
              </Col>
            )
          })}
        </Row>
        <Divider />
        <Row className='mb-2' align='middle'>
          <div
            style={{ height: 24, width: 8 }}
            className='bg-secondary-cyan mr-1'
          />
          <Text className='text-l-bold'>Fans Information</Text>
        </Row>
        <Row gutter={[8, 8]}>
          {FANS_INFO.map((f) => {
            return (
              <Col key={f.label} xs={24} md={6}>
                <Text className='text-m-medium text-color-neutral-70'>
                  {f.label}
                </Text>
                <Row className='mt-1'>
                  <Text className='text-m-bold'>{f.value}</Text>
                </Row>
              </Col>
            )
          })}
        </Row>
        <Row className='mb-2 mt-5' align='middle'>
          <div
            style={{ height: 24, width: 8 }}
            className='bg-secondary-cyan mr-1'
          />
          <Text className='text-l-bold'>
            {data?.subscriptionTier ? 'Subscription' : 'Tipping'} Information
          </Text>
        </Row>
        {data?.subscriptionTier ? <ContentSubscription /> : <ContentTipping />}
      </Col>
    </Row>
  )
}

export default TransactionDetail
