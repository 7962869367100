import { Table } from 'antd'
import { SorterResult } from 'antd/lib/table/interface'
import React from 'react'
import TransactionColumn from '../../constants/table/transaction'
import ITransactionState from '../../interfaces/states/transaction'

interface IProps {
  transaction: ITransactionState
  onChangePage: (page: number) => void
  onChangeSort?: (sorter: SorterResult<any>) => void
  sortValue?: {
    field: string
    order?: 'asc' | 'desc'
  }
}

const TransactionList = (props: IProps) => {
  const { transaction, onChangePage, onChangeSort, sortValue } = props

  return (
    <Table
      loading={transaction?.requesting}
      onChange={(_p, _f, s) =>
        onChangeSort && onChangeSort(s as SorterResult<any>)
      }
      dataSource={transaction?.list! || []}
      columns={TransactionColumn.map((tc) => {
        if (tc.dataIndex === sortValue?.field)
          return {
            ...tc,
            defaultSortOrder: sortValue?.order === 'asc' ? 'ascend' : 'descend'
          }
        return tc
      })}
      pagination={{
        hideOnSinglePage: true,
        showLessItems: true,
        current: transaction?.paging?.currentPage,
        pageSize: transaction?.paging?.limit || 0,
        total: transaction?.paging?.totalData,
        onChange: onChangePage,
        showSizeChanger: false
      }}
    />
  )
}

export default TransactionList
