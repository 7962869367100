import { Row, Col, Typography, Image, Button, Modal, Alert } from 'antd'
import GeneralParticle from '@attn/general'
import Avatar from 'antd/lib/avatar/avatar'
import React, { useState } from 'react'
import moment from 'moment'
import IWithdrawalState from '../../interfaces/states/withdrawal'
import DefaultForm from '../../components/form'
import { IField } from '../../interfaces/models/form'
import { ICashWithdrawalRequest } from '@cr/general/dist/interfaces'
import CASH_WIDRAWAL_REQUEST_STATUS from '@cr/general/dist/constants/cash-withdrawal-request-status'
import AUTH_PROVIDER_TYPE from '@cr/general/dist/constants/auth-provider-type'
import SUMMARY_WITHDRAWAL_STATUS from '../../constants/summary-withdrawal-status'
import { WITHDRAWAL_STATUS } from '../../constants/table/withdrawal-request'

const { Text } = Typography
const { Numberize } = GeneralParticle.Utils.Format

interface IProps {
  withdrawal: IWithdrawalState
  onVerify: (data: ICashWithdrawalRequest) => void
}

const fields = [
  {
    name: 'reason',
    label: 'Message',
    type: 'textarea',
    rules: [
      { required: true, message: 'Reason is required' },
      {
        transform: (val) => val && val.trim(),
        max: 160,
        message: 'Maximum 160 character'
      }
    ]
  }
] as Array<IField>

const WithdrawalRequestDetail = (props: IProps) => {
  const { withdrawal, onVerify } = props
  const [visible, setVisible] = useState<boolean>(false)
  const summaryStatus = WITHDRAWAL_STATUS(
    withdrawal.data || { ...({} as ICashWithdrawalRequest) }
  )

  const StatusText = (status) => {
    switch (status) {
      case SUMMARY_WITHDRAWAL_STATUS.FAILED:
        return (
          <Alert
            type='error'
            message={`Transfer is error : ${withdrawal.data?.disburseFailureCodeFromGateway}`}
          />
        )
      case SUMMARY_WITHDRAWAL_STATUS.COMPLETED:
        return <Alert type='success' message={`Transfer is completed`} />
      case SUMMARY_WITHDRAWAL_STATUS.TRANSFERRING:
        return <Alert type='warning' message={`Transfer is still pending`} />
      default:
        return <Alert type='error' message={`Undefined`} />
    }
  }

  return (
    <Row justify='center' className='mt-8'>
      <Col md={18} lg={12}>
        <Row>
          <Text className='text-size-24 text-weight-medium text-color-primary-purple'>
            Withdrawal Details
          </Text>
        </Row>
        {withdrawal.data &&
        summaryStatus !== SUMMARY_WITHDRAWAL_STATUS.PENDING ? (
          withdrawal.data.status === CASH_WIDRAWAL_REQUEST_STATUS.DECLINED ? (
            <Alert
              type='error'
              message={`Request is declined: ${withdrawal.data?.reason}`}
            />
          ) : withdrawal.data.status === CASH_WIDRAWAL_REQUEST_STATUS.SUCCESS &&
            !withdrawal.data.disburseStatus ? (
            <Alert
              type='success'
              message={`This withdrawal request is ${withdrawal.data.status}`}
            />
          ) : (
            StatusText(summaryStatus)
          )
        ) : (
          <></>
        )}
        <Row gutter={16} className='mt-5'>
          <Col flex='1 1'>
            <Row className='mb-1'>
              <Text className='text-color-second-dark-purple'>Talent Name</Text>
            </Row>
            <Row gutter={16} align='middle' className='mb-4'>
              <Col>
                <Avatar
                  size={68}
                  src={withdrawal.data?.user?.images?.profilePicture}
                />
              </Col>
              <Col flex='1 1'>
                <Text className='text-size-16 text-weight-medium text-color-dark-purple'>
                  {withdrawal.data?.user?.fullName}
                </Text>
              </Col>
            </Row>
            <Row className='mb-1'>
              <Text className='text-color-second-dark-purple'>Email</Text>
            </Row>
            <Row className='mb-4'>
              <Text className='text-size-16 text-color-dark-purple'>
                {
                  withdrawal.data?.user?.userAuthProviders?.find(
                    (ap) => ap.authProvider?.id === AUTH_PROVIDER_TYPE.EMAIL
                  )?.authAccount
                }
              </Text>
            </Row>
          </Col>
          <Col flex='1 1'>
            <Row className='mb-1'>
              <Text className='text-color-second-dark-purple'>ID Card</Text>
            </Row>
            <Row className='mb-1'>
              <Image
                className='object-fit-contain'
                width={'100%'}
                height={240}
                src={withdrawal.data?.userIdCardInfo?.images}
              />
            </Row>
          </Col>
        </Row>
        <Row className='mb-1'>
          <Text className='text-color-second-dark-purple'>Requested At</Text>
        </Row>
        <Row className='mb-4'>
          <Text className='text-size-16 text-color-dark-purple'>
            {moment.unix(withdrawal.data?.createdAt!).fromNow()}
          </Text>
        </Row>
        <Row>
          <Col flex='1 1'>
            <Row className='mb-1'>
              <Text className='text-color-second-dark-purple'>Bank Info</Text>
            </Row>
            <Row className='mb-1'>
              <Text className='text-size-16 text-weight-medium text-color-dark-purple'>
                {withdrawal.data?.userBankInfo?.accountName}
              </Text>
            </Row>
            <Row>
              <Text className='text-color-dark-purple'>{`${withdrawal.data?.userBankInfo?.bank.name} - ${withdrawal.data?.userBankInfo?.accountNumber}`}</Text>
            </Row>
          </Col>
          <Col flex='1 1'>
            <Row className='mb-1'>
              <Text className='text-color-second-dark-purple'>
                Amount to be transferred
              </Text>
            </Row>
            <Row className='mb-1'>
              <Text className='text-size-16 text-weight-medium text-color-primary-purple'>
                {Numberize(withdrawal.data?.amountNetto || 0, '.', 'Rp ')}
              </Text>
            </Row>
          </Col>
        </Row>
        <Row className='mt-3'>
          {withdrawal.data?.reason ? (
            <Text className='text-color-second-dark-purple text-size-16'>
              <strong className='text-color-dark-purple'>Note :</strong>{' '}
              {withdrawal.data.reason}
            </Text>
          ) : (
            <></>
          )}
        </Row>
        {withdrawal.data?.status === CASH_WIDRAWAL_REQUEST_STATUS.PENDING ? (
          <>
            <Row>
              <Text className='text-size-16 text-color-second-dark-purple'>
                <span className='text-color-dark-purple'>Note</span>: By
                approving this request, we are going to automatically transfer
                the requested amount to the creator
              </Text>
            </Row>
            <Row gutter={16} className='mt-7'>
              <Col flex='1 1'>
                <Button
                  size='large'
                  block
                  type='ghost'
                  onClick={() => setVisible(true)}
                >
                  This Request Is Invalid
                </Button>
              </Col>
              <Col flex='1 1'>
                <Button
                  size='large'
                  block
                  type='primary'
                  onClick={() => {
                    Modal.confirm({
                      width: 560,
                      icon: '',
                      okText: 'Confirmation',
                      content: (
                        <>
                          <Row className='mb-3'>
                            <Text className='text-size-20 text-weight-bold'>
                              Are you sure?
                            </Text>
                          </Row>
                          <Row className='mb-3'>
                            <Text className='text-size-16'>
                              We will transfer the amount of money to the bank
                              account below.
                            </Text>
                          </Row>
                          <Row gutter={16}>
                            <Col flex='1 1'>
                              <Row className='mb-1'>
                                <Text className='text-color-second-dark-purple'>
                                  Talent Name
                                </Text>
                              </Row>
                              <Row gutter={16} align='middle' className='mb-4'>
                                <Col>
                                  <Avatar
                                    size={24}
                                    src={
                                      withdrawal.data?.user?.images
                                        ?.profilePicture
                                    }
                                  />
                                </Col>
                                <Col flex='1 1'>
                                  <Text className='text-size-16 text-weight-medium text-color-dark-purple'>
                                    {withdrawal.data?.user?.fullName}
                                  </Text>
                                </Col>
                              </Row>
                            </Col>
                            <Col flex='1 1'>
                              <Row className='mb-1'>
                                <Text className='text-color-second-dark-purple'>
                                  Bank Info
                                </Text>
                              </Row>
                              <Row className='mb-1'>
                                <Text className='text-size-16 text-weight-medium text-color-dark-purple'>
                                  {withdrawal.data?.userBankInfo?.accountName}
                                </Text>
                              </Row>
                              <Row>
                                <Text className='text-color-dark-purple'>{`${withdrawal.data?.userBankInfo?.bank.name} - ${withdrawal.data?.userBankInfo?.accountNumber}`}</Text>
                              </Row>
                            </Col>
                          </Row>
                          <Row className='mb-1'>
                            <Text className='text-color-second-dark-purple'>
                              Amount to be transferred
                            </Text>
                          </Row>
                          <Row className='mb-1'>
                            <Text className='text-size-16 text-weight-medium text-color-primary-purple'>
                              {Numberize(
                                withdrawal.data?.amountNetto || 0,
                                '.',
                                'Rp '
                              )}
                            </Text>
                          </Row>
                        </>
                      ),
                      onOk: () => {
                        const data = {
                          ...withdrawal.data,
                          status: CASH_WIDRAWAL_REQUEST_STATUS.APPROVED
                        } as ICashWithdrawalRequest
                        Modal.confirm({
                          title:
                            'Are you sure you want to proceed this request?',
                          onOk: () => onVerify(data),
                          onCancel: () => null
                        })
                      },
                      onCancel: () => null
                    })
                  }}
                >
                  Approve and Transfer
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </Col>
      <DefaultForm
        visible={visible}
        formName='rejet-withdraw'
        fields={fields}
        initialValues={{}}
        additionalHeader={
          <>
            <Row className='mb-3'>
              <Text className='text-size-20 text-weight-bold'>{`Mark as Invalid?`}</Text>
            </Row>
            <Row className='mb-3'>
              <Text className='text-size-16'>
                If the data is invalid, please fill the form below, so the
                applicant can review and fix their application data.
              </Text>
            </Row>
          </>
        }
        onCancel={() => setVisible(false)}
        onFinish={(value) => {
          const data = {
            ...withdrawal.data,
            reason: value.reason,
            status: CASH_WIDRAWAL_REQUEST_STATUS.DECLINED
          } as ICashWithdrawalRequest
          onVerify(data)
          setVisible(false)
        }}
        okText='Submit'
      />
    </Row>
  )
}

export default WithdrawalRequestDetail
