import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import BaseStateDefault from '../../constants/state'
import ITransactionState from '../../interfaces/states/transaction'

const initialState: ITransactionState = {
  ...BaseStateDefault
}

const reducer: Reducer<any> = (
  state: ITransactionState = initialState,
  action: any
): ITransactionState => {
  switch (action.type) {
    case ActionTypes.Transaction.TRANSACTION_GET_DETAIL_REQUEST:
    case ActionTypes.Transaction.TRANSACTION_GET_LIST_REQUEST:
    case ActionTypes.Transaction.TRANSACTION_GET_STATUS_COUNT_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.Transaction.TRANSACTION_GET_DETAIL_FAILED:
    case ActionTypes.Transaction.TRANSACTION_GET_LIST_FAILED:
    case ActionTypes.Transaction.TRANSACTION_GET_STATUS_COUNT_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    case ActionTypes.Transaction.TRANSACTION_GET_LIST_SUCCESS: {
      const { list, paging } = action.payload
      return {
        ...state,
        requesting: false,
        list,
        paging
      }
    }
    case ActionTypes.Transaction.TRANSACTION_GET_STATUS_COUNT_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        statusCount: data
      }
    }
    case ActionTypes.Transaction.TRANSACTION_GET_DETAIL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
