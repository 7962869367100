enum SUMMARY_WITHDRAWAL_STATUS {
  PENDING = 'pending',
  TRANSFERRING = 'transferring',
  COMPLETED = 'completed',
  DECLINED = 'declined',
  FAILED = 'failed',
  SUCCESS = 'success'
}

export default SUMMARY_WITHDRAWAL_STATUS
