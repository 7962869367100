import { Button, Col, Form, Input, Layout, Row } from 'antd'
import React from 'react'
import { connect } from 'react-redux'

import ILogin from '../../interfaces/request/login'
import IAuthState from '../../interfaces/states/auth'
import Actions from '../../store/actions'
import { ReduxState } from '../../store/reducers'

interface LoginProps {
  authenticateUser: (data: ILogin) => void
  form: any
  auth: IAuthState
}

const Login = (props: LoginProps) => {
  const { authenticateUser, auth } = props
  const { Content } = Layout
  const [form] = Form.useForm()

  const handleSubmit = (value: any) => {
    authenticateUser(value)
  }

  return (
    <Layout className='layout login'>
      <Content>
        <Row justify='space-around' align='middle'>
          <Col
            xs={{ span: 24 }}
            xl={{ span: 8 }}
            className='login-form-wrapper'
          >
            <Form
              onFinish={handleSubmit}
              className='login-form'
              form={form}
              name='Login'
            >
              <Form.Item
                name='email'
                rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'Email is not valid' }
                ]}
              >
                <Input placeholder='email' size='large' />
              </Form.Item>
              <Form.Item
                name='password'
                rules={[
                  { required: true, message: 'Please input your Password!' }
                ]}
              >
                <Input.Password
                  type='password'
                  placeholder='Password'
                  size='large'
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  block
                  size='large'
                  loading={auth.requesting}
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth
})

const mapDispatchToProps = (dispatch: any) => ({
  authenticateUser: (data: ILogin) =>
    dispatch(Actions.Auth.SignInWithEmail(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
