/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { parse, stringify } from 'query-string'
import { Button, Col, DatePicker, Input, Row, Select, Typography } from 'antd'
import SERVICE_ID from '@cr/general/dist/constants/service'
import PAYMENT_STATUS from '@cr/general/dist/constants/payment-status'

import Layout from '../../components/layout'
import Actions from '../../store/actions'
import { ReduxState } from '../../store/reducers'
import TransactionList from './transaction-list'
import IPaymentMethodState from '../../interfaces/states/payment-method'
import moment from 'moment'
import ITransactionState from '../../interfaces/states/transaction'
import TransactionDetail from './transaction-detail'
import API_TRANSACTION_TYPE from '../../constants/transaction-type'
import NotFound from '../../components/not-found'

const { Text } = Typography

interface IProps {
  GetListTransaction: (page?: number, limit?: number, filter?: IFilters) => void
  GetCountStatus: (filter?: IFilters) => void
  GetPaymentMethodList: () => void
  GetDetailTransaction: (type: API_TRANSACTION_TYPE, receiptId: string) => void
  paymentMethod: IPaymentMethodState
  transaction: ITransactionState
}
export interface IFilters {
  fansName?: string
  creatorName?: string
  receiptID?: string
  createdAtFrom?: any
  createdAtUntil?: any
  status?: string
  paymentMethod?: string
  service?: string
  orderBy?: string
  sort?: 'ASC' | 'DESC'
}

const Transaction = (props: IProps) => {
  const {
    GetListTransaction,
    GetCountStatus,
    GetPaymentMethodList,
    GetDetailTransaction,
    transaction,
    paymentMethod
  } = props
  const params: { receiptId: string; type: string } = useParams()
  const qs: any = parse(useLocation().search.toString())
  const [filters, setFilters] = useState<IFilters>(
    Object.keys(qs).length !== 0
      ? qs
      : {
          service: SERVICE_ID.SUBSCRIPTION.toString()
        }
  )
  const statusCount = transaction.statusCount

  const SERVICE_FILTER = [
    {
      label: 'Subscription',
      value: SERVICE_ID.SUBSCRIPTION
    },
    {
      label: 'Tipping',
      value: SERVICE_ID.DONATION
    }
  ]

  const STATUS_FILTER = [
    {
      label: 'All',
      value: undefined,
      total:
        (statusCount?.paid || 0) +
        (statusCount?.pending || 0) +
        (statusCount?.expired || 0) +
        (statusCount?.failed || 0)
    },
    {
      label: 'Pending',
      value: PAYMENT_STATUS.PENDING,
      total: statusCount?.pending || 0
    },
    {
      label: 'Success',
      value: PAYMENT_STATUS.PAID,
      total: statusCount?.paid || 0
    },
    {
      label: 'Expired',
      value: PAYMENT_STATUS.EXPIRED,
      total: statusCount?.expired || 0
    },
    {
      label: 'Failed',
      value: PAYMENT_STATUS.FAILED,
      total: statusCount?.failed || 0
    }
  ]

  useEffect(() => {
    if (!params.receiptId) {
      GetPaymentMethodList()
    }
    if (params.receiptId && params.type)
      GetDetailTransaction(params.type as any, params.receiptId)
  }, [])

  const fetchTransaction = (dataFilter: IFilters) => {
    applyFiltersUri(dataFilter)
    if (dataFilter) {
      GetListTransaction(1, 10, dataFilter)
      GetCountStatus({ ...dataFilter, status: undefined })
    }
  }

  useEffect(() => {
    //running this func by onClick field (date, payment, etc / non input text)
    if (!params.receiptId) {
      fetchTransaction(filters)
    }
  }, [
    filters.orderBy,
    filters.sort,
    filters.service,
    filters.createdAtFrom,
    filters.createdAtUntil,
    filters.paymentMethod,
    filters.status
  ])

  const applyFiltersUri = (dataFilter: IFilters) => {
    const qsFilter = Object.entries(dataFilter).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    history.pushState(
      {},
      '',
      `/transactions?${qsFilter ? stringify(qsFilter) : ''}`
    )
  }

  if (params.receiptId)
    return (
      <Layout
        breadcrumbs={[
          { label: 'Transaction', href: '/transactions' },
          { label: params.receiptId }
        ]}
      >
        {transaction.data ? (
          <TransactionDetail transaction={transaction} />
        ) : (
          <NotFound isLoading={transaction.requesting} />
        )}
      </Layout>
    )

  return (
    <Layout header='Transaction'>
      <Row justify='space-between' className='mb-2'>
        <Text className='heading-s-regular'>Transaction</Text>
        <Row
          className='bg-neutral-30 p--5'
          style={{ borderRadius: 38 }}
          gutter={8}
        >
          {SERVICE_FILTER.map((sf) => {
            return (
              <Col key={sf.value}>
                <Button
                  type='text'
                  size='small'
                  shape='round'
                  className={
                    sf.value.toString() === filters.service
                      ? 'bg-primary-main text-color-white'
                      : 'text-color-neutral-70'
                  }
                  onClick={() => {
                    setFilters({
                      ...filters,
                      service: sf.value.toString()
                    })
                  }}
                >
                  {sf.label}
                </Button>
              </Col>
            )
          })}
        </Row>
      </Row>
      <Text className='text-m-regular'>Filter</Text>
      <Row className='mt-3 mb-3' gutter={[32, 16]}>
        <Col span={8}>
          <Row align='middle' gutter={[8, 24]}>
            <Col span={8}>
              <Text className='text-m-regular'>Creator Name</Text>
            </Col>
            <Col span={16}>
              <Input
                defaultValue={filters.creatorName}
                allowClear
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    creatorName: e.target.value ? e.target.value : undefined
                  })
                  if (!e.target.value)
                    fetchTransaction({
                      ...filters,
                      creatorName: e.target.value ? e.target.value : undefined
                    })
                }}
                onPressEnter={() => fetchTransaction(filters)}
              />
            </Col>
            <Col span={8}>
              <Text className='text-m-regular'>Fans Name</Text>
            </Col>
            <Col span={16}>
              <Input
                defaultValue={filters.fansName}
                allowClear
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    fansName: e.target.value ? e.target.value : undefined
                  })
                  if (!e.target.value)
                    fetchTransaction({
                      ...filters,
                      fansName: e.target.value ? e.target.value : undefined
                    })
                }}
                onPressEnter={() => fetchTransaction(filters)}
              />
            </Col>
            <Col span={8}>
              <Text className='text-m-regular'>Receipt ID</Text>
            </Col>
            <Col span={16}>
              <Input
                defaultValue={filters.receiptID}
                allowClear
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    receiptID: e.target.value ? e.target.value : undefined
                  })
                  if (!e.target.value)
                    fetchTransaction({
                      ...filters,
                      receiptID: e.target.value ? e.target.value : undefined
                    })
                }}
                onPressEnter={() => fetchTransaction(filters)}
              />
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row className='mb-1'>
            <Text className='text-m-regular'>Transaction Date</Text>
          </Row>
          <DatePicker.RangePicker
            defaultValue={
              filters.createdAtFrom && filters.createdAtUntil
                ? [
                    moment.unix(filters.createdAtFrom),
                    moment.unix(filters.createdAtUntil)
                  ]
                : undefined
            }
            className='full-width'
            onChange={(e) => {
              setFilters({
                ...filters,
                createdAtFrom: e
                  ? moment(moment(e[0]).startOf('day')).unix()
                  : undefined,
                createdAtUntil: e
                  ? moment(moment(e[1]).endOf('day')).unix()
                  : undefined
              })
            }}
          />
          <Row className='mt-2 mb-1'>
            <Text className='text-m-regular'>Transaction Status</Text>
          </Row>
          <Row align='middle' gutter={[8, 8]}>
            {STATUS_FILTER.map((sf) => {
              return (
                <Col key={sf.value}>
                  <Button
                    size='small'
                    className={`${
                      sf.value === filters.status
                        ? 'border-primary-border bg-primary-surface text-color-primary-main'
                        : 'bg-neutral-20 text-color-neutral-90 border-neutral-20'
                    } text-m-medium border-radius-4`}
                    onClick={() => {
                      setFilters({ ...filters, status: sf.value?.toString() })
                    }}
                  >
                    {sf.label} ({sf.total})
                  </Button>
                </Col>
              )
            })}
          </Row>
        </Col>
        <Col span={4}>
          <Row className='mb-1'>
            <Text className='text-m-regular'>Payment Method</Text>
          </Row>
          <Select
            className='full-width'
            placeholder='Select Payment Method'
            allowClear
            defaultValue={filters.paymentMethod}
            onChange={(e) => {
              setFilters({ ...filters, paymentMethod: e?.toString() as any })
            }}
          >
            {paymentMethod.list?.map((pm) => {
              return (
                <Select.Option value={pm.id.toString()} key={pm.id}>
                  {pm.name}
                </Select.Option>
              )
            })}
          </Select>
        </Col>
      </Row>
      <TransactionList
        transaction={transaction}
        onChangePage={(pn) =>
          GetListTransaction(pn, transaction.paging?.limit, filters)
        }
        onChangeSort={(s) =>
          setFilters({
            ...filters,
            orderBy: s.columnKey as any,
            sort: s.order === 'ascend' ? 'ASC' : 'DESC'
          })
        }
        sortValue={
          qs.orderBy && qs.sort
            ? {
                field: qs.orderBy as any,
                order: qs.sort
              }
            : undefined
        }
      />
    </Layout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  paymentMethod: state.paymentMethod,
  transaction: state.transaction
})

const mapDispatchToProps = (dispatch: any) => ({
  GetListTransaction: (page?: number, limit?: number, filter?: IFilters) =>
    dispatch(Actions.Transaction.GetList(page, limit, filter)),
  GetCountStatus: (filter?: IFilters) =>
    dispatch(Actions.Transaction.GetCountStatus(filter)),
  GetPaymentMethodList: () => dispatch(Actions.PaymentMethod.GetList()),
  GetDetailTransaction: (type: API_TRANSACTION_TYPE, receiptId: string) =>
    dispatch(Actions.Transaction.GetDetail(type, receiptId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Transaction)
