import CASH_WITHDRAWAL_REQUEST_STATUS from '@cr/general/dist/constants/cash-withdrawal-request-status'
import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import BaseStateDefault from '../../constants/state'
import IWithdrawalState from '../../interfaces/states/withdrawal'

const initialState: IWithdrawalState = {
  ...BaseStateDefault,
  groupedList: {}
}

const reducer: Reducer<any> = (
  state: IWithdrawalState = initialState,
  action
): IWithdrawalState => {
  switch (action.type) {
    case ActionTypes.Withdrawal.WITHDRAWAL_GET_LIST_REQUEST: {
      const { status } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: true }
      }
      state.groupedList[status] = {
        ...state.groupedList[status],
        requesting: true,
        error: undefined
      }
      return {
        ...state
      }
    }
    case ActionTypes.Withdrawal.WITHDRAWAL_ACTION_REQUEST:
    case ActionTypes.Withdrawal.WITHDRAWAL_GET_DETAIL_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.Withdrawal.WITHDRAWAL_ACTION_SUCCESS: {
      const { id, status } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: false }
      }

      state.groupedList[CASH_WITHDRAWAL_REQUEST_STATUS.PENDING] = {
        ...state.groupedList[CASH_WITHDRAWAL_REQUEST_STATUS.PENDING],
        list: state.groupedList[
          CASH_WITHDRAWAL_REQUEST_STATUS.PENDING
        ].list?.filter((x) => x.id !== id)
      }

      return {
        ...state,
        requesting: false
      }
    }
    case ActionTypes.Withdrawal.WITHDRAWAL_GET_DETAIL_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        data,
        requesting: false
      }
    }
    case ActionTypes.Withdrawal.WITHDRAWAL_GET_LIST_SUCCESS: {
      const { list, paging, status } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: false }
      }
      state.groupedList[status] = {
        ...state.groupedList[status],
        requesting: false,
        list,
        paging
      }

      return {
        ...state
      }
    }
    case ActionTypes.Withdrawal.WITHDRAWAL_GET_LIST_FAILED: {
      const { status, error } = action.payload
      if (!state.groupedList[status]) {
        state.groupedList[status] = { list: [], requesting: false }
      }
      state.groupedList[status] = {
        ...state.groupedList[status],
        requesting: false,
        error: error
      }
      return {
        ...state
      }
    }
    case ActionTypes.Withdrawal.WITHDRAWAL_ACTION_FAILED:
    case ActionTypes.Withdrawal.WITHDRAWAL_GET_DETAIL_FAILED: {
      return {
        ...state,
        list: [],
        data: undefined,
        requesting: false,
        error: action.payload.error
      }
    }

    default: {
      return state
    }
  }
}

export default reducer
