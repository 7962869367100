import React from 'react'
import { Table } from 'antd'
import { IUser } from '@cr/general/dist/interfaces'

import UserTableColumns from '../../constants/table/user'
import IUserState from '../../interfaces/states/user'
import { USER_MODE } from '../../constants/user-mode'

interface IProps {
  user: IUserState
  onChangePage: (pn: number) => void
}

const Users = (props: IProps) => {
  const { user, onChangePage } = props

  return (
    <Table
      style={{ width: '100%' }}
      columns={UserTableColumns}
      dataSource={
        user.groupedList[`${USER_MODE.UNDEFINED}|${USER_MODE.UNDEFINED}`]
          ?.list || []
      }
      loading={
        user.groupedList[`${USER_MODE.UNDEFINED}|${USER_MODE.UNDEFINED}`]
          ?.requesting
      }
      rowKey={(user: IUser) => user.id}
      pagination={{
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSize:
          user.groupedList[`${USER_MODE.UNDEFINED}|${USER_MODE.UNDEFINED}`]
            ?.paging?.limit,
        showLessItems: true,
        current:
          user.groupedList[`${USER_MODE.UNDEFINED}|${USER_MODE.UNDEFINED}`]
            ?.paging?.currentPage,
        total:
          user.groupedList[`${USER_MODE.UNDEFINED}|${USER_MODE.UNDEFINED}`]
            ?.paging?.totalData,
        onChange: onChangePage
      }}
    />
  )
}

export default Users
