import { IResult } from '@attn/general/dist/interfaces/api'
import { IChallengeTier } from '@cr/general/dist/interfaces'

import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'
import ActionTypes from '../../constants/action-types'
import Api from '../apis'
import Base from './base'

const GetList =
  (challengeId?: string, page?: number, limit?: number) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.ChallengeTier.GetList(challengeId, page, limit),
      () => {
        dispatch(
          action(ActionTypes.ChallengeTier.CHALLENGE_TIER_GET_LIST_REQUEST)
        )
      },
      (res: IResult<Array<IChallengeTier>>) => {
        dispatch(
          action(ActionTypes.ChallengeTier.CHALLENGE_TIER_GET_LIST_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.ChallengeTier.CHALLENGE_TIER_GET_LIST_FAILED, {
            error,
            status
          })
        )
    )
  }

const Create = (data: IChallengeTier) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.ChallengeTier.Create(data),
    () => {
      dispatch(action(ActionTypes.ChallengeTier.CHALLENGE_TIER_CREATE_REQUEST))
    },
    (res: IResult<IChallengeTier>) => {
      dispatch(
        action(ActionTypes.ChallengeTier.CHALLENGE_TIER_CREATE_SUCCESS, {
          data: res.data
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.ChallengeTier.CHALLENGE_TIER_CREATE_FAILED, {
          error
        })
      )
  )
}

const Update =
  (id: string, data: IChallengeTier) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.ChallengeTier.Update(id, data),
      () => {
        dispatch(
          action(ActionTypes.ChallengeTier.CHALLENGE_TIER_UPDATE_REQUEST)
        )
      },
      (res: IResult<IChallengeTier>) => {
        dispatch(
          action(ActionTypes.ChallengeTier.CHALLENGE_TIER_UPDATE_SUCCESS, {
            data: res.data
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.ChallengeTier.CHALLENGE_TIER_UPDATE_FAILED, {
            error
          })
        )
    )
  }

const Delete = (id: string) => (dispatch: Dispatch<AnyAction>) => {
  return Base.CallAction(
    Api.ChallengeTier.Delete(id),
    () => {
      dispatch(action(ActionTypes.ChallengeTier.CHALLENGE_TIER_DELETE_REQUEST))
    },
    () => {
      dispatch(
        action(ActionTypes.ChallengeTier.CHALLENGE_TIER_DELETE_SUCCESS, {
          id
        })
      )
    },
    (error: string) =>
      dispatch(
        action(ActionTypes.ChallengeTier.CHALLENGE_TIER_DELETE_FAILED, {
          error
        })
      )
  )
}

export default {
  GetList,
  Create,
  Update,
  Delete
}
