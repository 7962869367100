import { Reducer } from 'redux'

import ActionTypes from '../../constants/action-types'
import BaseStateDefault from '../../constants/state'
import IPaymentMethodState from '../../interfaces/states/payment-method'

const initialState: IPaymentMethodState = {
  ...BaseStateDefault
}

const reducer: Reducer<any> = (
  state: IPaymentMethodState = initialState,
  action: any
): IPaymentMethodState => {
  switch (action.type) {
    case ActionTypes.PaymentMethod.PAYMENT_METHOD_GET_LIST_REQUEST: {
      return {
        ...state,
        requesting: true,
        error: undefined
      }
    }
    case ActionTypes.PaymentMethod.PAYMENT_METHOD_GET_LIST_FAILED: {
      return {
        ...state,
        requesting: false,
        error: action.payload.error
      }
    }
    case ActionTypes.PaymentMethod.PAYMENT_METHOD_GET_LIST_SUCCESS: {
      const { list } = action.payload
      return {
        ...state,
        requesting: false,
        list
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
