import { IResult } from '@attn/general/dist/interfaces/api'
import {
  IAgencyFee,
  IPartnershipCreatorEmailPayload,
  IPartnership,
  IPartnershipConfig
} from '@cr/general/dist/interfaces'
import { message } from 'antd'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'
import ActionTypes from '../../constants/action-types'
import Api from '../apis'
import Base from './base'

const GetListPartnership =
  (page?: number, limit?: number) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.AgencyFee.GetListPartnership(page, limit),
      () => {
        dispatch(action(ActionTypes.AgencyFee.PARTNERSHIP_GET_LIST_REQUEST))
      },
      (res: IResult<Array<IAgencyFee>>) => {
        dispatch(
          action(ActionTypes.AgencyFee.PARTNERSHIP_GET_LIST_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.AgencyFee.PARTNERSHIP_GET_LIST_FAILED, {
            error
          })
        )
    )
  }

const GetDetailPartnership =
  (id: number, onSuccess) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.AgencyFee.GetDetailPartnership(id),
      () => {
        dispatch(action(ActionTypes.AgencyFee.PARTNERSHIP_GET_LIST_REQUEST))
      },
      (res: IResult<IPartnership>) => {
        onSuccess(res)
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.AgencyFee.PARTNERSHIP_GET_LIST_FAILED, {
            error
          })
        )
    )
  }

const UpdatePartnership =
  (id: number, data: IPartnership) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.AgencyFee.UpdatePartnership(id, data),
      () => {
        dispatch(action(ActionTypes.AgencyFee.PARTNERSHIP_UPDATE_REQUEST))
      },
      () => {
        dispatch(action(ActionTypes.AgencyFee.PARTNERSHIP_UPDATE_SUCCESS))
        message.success('Partnership was successfully updated')
        setTimeout(() => {
          window.location.href = '/agency-fee?tab=view'
        }, 1000)
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.AgencyFee.PARTNERSHIP_UPDATE_FAILED, {
            error
          })
        )
    )
  }

const CreatePartnerShip =
  (data: IPartnership, callbackSuccess) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.AgencyFee.CreatePartnerShip(data),
      () => {
        dispatch(action(ActionTypes.AgencyFee.CREATE_PARTNER_SHIP_REQUEST))
      },
      (response) => {
        callbackSuccess(response.data)
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.AgencyFee.CREATE_PARTNER_SHIP_FAILED, {
            error
          })
        )
    )
  }

const CreatePartnerShipConfig =
  (data: Array<IPartnershipConfig>, idPartnership: string, onSuccesss: any) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.AgencyFee.CreatePartnerShipConfig(data, idPartnership),
      () => {
        dispatch(action(ActionTypes.AgencyFee.AGENCY_FEE_CREATE_REQUEST))
      },
      () => {
        onSuccesss()
        message.success('Partnership was successfully created')
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.AgencyFee.AGENCY_FEE_CREATE_FAILED, {
            error
          })
        )
    )
  }

const DeletePartnership =
  (id: number | undefined) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.AgencyFee.DeletePartnership(id),
      () => {
        dispatch(action(ActionTypes.AgencyFee.PARTNERSHIP_DELETE_REQUEST))
      },
      () => {
        dispatch(
          action(ActionTypes.AgencyFee.PARTNERSHIP_DELETE_SUCCESS, {
            id
          })
        )
        message.success('Partnership was deleted')
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.AgencyFee.PARTNERSHIP_DELETE_FAILED, {
            error
          })
        )
    )
  }

const CreateCreatorEmail =
  (data: IPartnershipCreatorEmailPayload, idPartnership: number | undefined) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.AgencyFee.CreateCreatorEmail(data, idPartnership),
      () => {
        dispatch(action(ActionTypes.AgencyFee.PARTNERSHIP_CREATE_EMAIL_REQUEST))
      },
      () => {
        dispatch(action(ActionTypes.AgencyFee.PARTNERSHIP_CREATE_EMAIL_SUCCESS))
        message.success('Creator email was successfully added')
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.AgencyFee.PARTNERSHIP_CREATE_EMAIL_FAILED, {
            error
          })
        )
    )
  }

const Create =
  (data: IAgencyFee, callbackSuccess) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.AgencyFee.Create(data),
      () => {
        dispatch(action(ActionTypes.AgencyFee.AGENCY_FEE_CREATE_REQUEST))
      },
      (res: IResult<Array<IAgencyFee>>) => {
        dispatch(
          action(ActionTypes.AgencyFee.AGENCY_FEE_CREATE_SUCCESS, {
            data: res.data
          })
        )
        callbackSuccess(res.data)
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.AgencyFee.AGENCY_FEE_CREATE_FAILED, {
            error
          })
        )
    )
  }

export default {
  GetListPartnership,
  Create,
  CreatePartnerShip,
  CreatePartnerShipConfig,
  CreateCreatorEmail,
  DeletePartnership,
  GetDetailPartnership,
  UpdatePartnership
}
