/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Table, Row, Modal } from 'antd'
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import { IMetadata } from '@cr/general/dist/interfaces'
import { ReduxState } from '../../store/reducers'
import Actions from '../../store/actions'
import Layout from '../../components/layout'
import IMetadataState from '../../interfaces/states/metadata'
import MetadataColumn from '../../constants/table/metadata'
import DefaultForm from '../../components/form'
import MetadataFormColumn from '../../constants/form/metadata'
import { isJsonString } from '../../utils'

interface IProps {
  GetList: (types?: string, page?: number) => void
  Create: (data: IMetadata) => Promise<any>
  Update: (data: IMetadata) => Promise<any>
  Delete: (id: number) => void
  metadata: IMetadataState
}

const Metadata = (props: IProps) => {
  const { GetList, Create, Update, Delete, metadata } = props
  const [initValues, setInitialValues] = React.useState<IMetadata>()
  const [showForm, setShowForm] = React.useState<boolean>(false)

  useEffect(() => {
    GetList()
  }, [])

  const onReset = () => {
    setInitialValues({ ...({} as IMetadata) })
  }

  const onCloseForm = () => {
    onReset()
    setShowForm(false)
  }

  return (
    <Layout header={'Metadata'}>
      <Row justify='end' className='mb-2'>
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => {
            onReset()
            setShowForm(true)
          }}
        >
          Add Metadata
        </Button>
      </Row>
      <Table
        loading={metadata?.requesting}
        columns={[
          ...MetadataColumn,
          {
            title: 'Action',
            key: 'action',
            width: 250,
            render: (record: IMetadata) => (
              <Row>
                <Button
                  icon={<EditOutlined />}
                  className='mr-1'
                  onClick={() => {
                    setShowForm(true)
                    setInitialValues(record)
                  }}
                >
                  Edit
                </Button>
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      content: 'Are you sure want to delete this metadata?',
                      onOk: () => Delete(record.id),
                      onCancel: () => null
                    })
                  }}
                >
                  Delete
                </Button>
              </Row>
            )
          }
        ]}
        dataSource={metadata?.list || []}
        rowKey={(wr: IMetadata) => wr.id}
        pagination={{
          hideOnSinglePage: true,
          showLessItems: true,
          pageSize: metadata?.paging?.limit || 0,
          total: metadata?.paging?.totalData
        }}
      />
      <DefaultForm
        title='Metadata Form'
        formName='metadata-form'
        visible={showForm}
        width={720}
        confirmLoading={metadata.requesting}
        okText='Save'
        fields={[
          ...MetadataFormColumn,
          {
            label: 'Value',
            name: 'value',
            type: isJsonString(initValues?.value || '{}')
              ? 'json-editor'
              : 'text'
          }
        ]}
        initialValues={{
          ...initValues,
          value: isJsonString(initValues?.value || '{}')
            ? JSON.parse(initValues?.value || '{}')
            : initValues?.value
        }}
        onCancel={onCloseForm}
        onFinish={(values) => {
          const data = {
            ...values,
            value: JSON.stringify(values.value)
          }
          const promise = initValues?.id ? Update(data) : Create(data)
          promise.then((res) => {
            if (res?.data?.success) {
              onCloseForm()
            }
          })
        }}
      />
    </Layout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  metadata: state.metadata
})

const mapDispatchToProps = (dispatch: any) => ({
  GetList: (types?: string, page?: number) =>
    dispatch(Actions.Metadata.GetList(types, page)),
  Create: (data: IMetadata) => dispatch(Actions.Metadata.Create(data)),
  Update: (data: IMetadata) => dispatch(Actions.Metadata.Update(data)),
  Delete: (id: number) => dispatch(Actions.Metadata.Delete(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Metadata)
