import React from 'react'
import { Col, Row, Table, Typography } from 'antd'
import { IUser } from '@cr/general/dist/interfaces'
import moment from 'moment'

import IUserState from '../../interfaces/states/user'
import UserBalanceLogTableColumns from '../../constants/table/user-balance-log'
import Avatar from 'antd/lib/avatar/avatar'

const { Text } = Typography

interface IProps {
  user: IUserState
  onChangePage: (pn) => void
}

const BalanceLog = (props: IProps) => {
  const { user, onChangePage } = props

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Row gutter={16}>
              <Col flex='1 1'>
                <Row className='mb-1'>
                  <Text className='text-color-second-dark-purple'>
                    Talent Name
                  </Text>
                </Row>
                <Row gutter={16} align='middle' className='mb-4'>
                  <Col>
                    <Avatar size={68} src={user.data?.images?.profilePicture} />
                  </Col>
                  <Col flex='1 1'>
                    <Text className='text-size-16 text-weight-medium text-color-dark-purple'>
                      {user.data?.fullName}
                    </Text>
                  </Col>
                </Row>
                <Row className='mb-1'>
                  <Text className='text-color-second-dark-purple'>
                    Joined At
                  </Text>
                </Row>
                <Row className='mb-4'>
                  <Text className='text-size-16 text-color-dark-purple'>
                    {moment
                      .unix(user.data?.createdAt || 0)
                      .format('DD MMM, YYYY')}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Table
          columns={UserBalanceLogTableColumns}
          dataSource={user.balanceLog}
          loading={user.requesting}
          rowKey={(user: IUser) => user.id}
          pagination={{
            hideOnSinglePage: true,
            showSizeChanger: false,
            pageSize: user.paging?.limit,
            showLessItems: true,
            current: user.paging?.currentPage,
            total: user.paging?.totalData,
            onChange: onChangePage
          }}
        />
      </Col>
    </Row>
  )
}

export default BalanceLog
