/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { parse } from 'query-string'

import Layout from '../../components/layout'
import Actions from '../../store/actions'
import { ReduxState } from '../../store/reducers'
import HypesList from './qc-hypes-list'
import IChallengeState from '../../interfaces/states/challenge'
import CHALLENGE_STATUS from '../../constants/challenge-status'
import OptionStatus from '../../components/option-status'
import HypesDetail from './qc-hypes-detail'
import IBreadcrumbItem from '../../interfaces/breadcrumb-item'
import IUserChallengeSubmissionState from '../../interfaces/states/user-challenge-submission'
import CHALLENGE_QC_STATUS from '@cr/general/dist/constants/challenge-qc-status'
import { IUserChallengeSubmission } from '@cr/general/dist/interfaces'
import { Col, Row, Select } from 'antd'
import moment from 'moment'

const { Option } = Select
interface IProps {
  GetListChallenge: (
    status: string,
    platformIds?: string,
    page?: number,
    limit?: number
  ) => void
  GetDetailChallenge: (id: string) => void
  GetListSubmission: (
    challengeId: string,
    status: string,
    page?: number,
    sort?: string,
    order?: 'asc' | 'desc' | undefined,
    createdAtFrom?: number,
    createdAtUntil?: number
  ) => void
  VerifySubmission: (
    challengeId: string,
    submissionId: number,
    data: IUserChallengeSubmission
  ) => void
  challenge: IChallengeState
  userChallengeSubmission: IUserChallengeSubmissionState
}

const Hypes = (props: IProps) => {
  const history = useHistory()
  const qs: any = parse(useLocation().search.toString())
  const params: { id: string } = useParams()
  const {
    GetListChallenge,
    challenge,
    GetListSubmission,
    userChallengeSubmission,
    GetDetailChallenge,
    VerifySubmission
  } = props
  const [status, setStatus] = useState<string | undefined>(undefined)
  const [platform, setPlatform] = useState<string | undefined>(undefined)
  const [order, setOrder] = useState<'asc' | 'desc' | undefined>()
  const [dateRange, setDateRange] = useState<Array<any> | undefined>()

  const FILTERS = [
    {
      name: 'All',
      value: '1,2'
    },
    {
      name: 'Tiktok',
      value: '1'
    },
    {
      name: 'Instagram Reels',
      value: '2'
    }
  ]

  React.useEffect(() => {
    setStatus(undefined)
    if (params.id) GetDetailChallenge(params.id)
  }, [])

  React.useEffect(() => {
    setStatus(qs.status)
    setPlatform(qs.platformIds)
  }, [qs])

  React.useEffect(() => {
    if (!params.id)
      GetListChallenge(status || CHALLENGE_STATUS.ON_GOING.toString(), platform)
    if (params.id) {
      GetListSubmission(params.id, status || CHALLENGE_QC_STATUS.PENDING)
    }
  }, [status, platform])

  const breadcrumbs: Array<IBreadcrumbItem> = [
    {
      label: 'QC Hypes',
      href: '/qc-hypes'
    },
    {
      label: 'Hype Detail'
    }
  ]

  useEffect(() => {
    if (params.id)
      GetListSubmission(
        params.id,
        status || CHALLENGE_QC_STATUS.PENDING,
        undefined,
        order && dateRange ? 'createdAt' : undefined,
        order,
        dateRange
          ? moment(moment(dateRange[0]).startOf('day')).unix()
          : undefined,
        dateRange ? moment(moment(dateRange[1]).endOf('day')).unix() : undefined
      )
  }, [order, dateRange])

  if (params.id) {
    return (
      <Layout breadcrumbs={breadcrumbs}>
        <HypesDetail
          challenge={challenge}
          userChallengeSubmission={userChallengeSubmission}
          status={status!}
          onVerify={(value) => VerifySubmission(params.id, value.id, value)}
          onChangePage={(pn) =>
            GetListSubmission(
              params.id,
              status || CHALLENGE_QC_STATUS.PENDING,
              pn
            )
          }
          onChangeOrder={(e: any) => setOrder(e)}
          onChangeDate={(e) => setDateRange(e)}
        />
      </Layout>
    )
  }

  return (
    <Layout header='QC Hypes'>
      <Row justify='space-between'>
        <Col flex={'1 1'}>
          <OptionStatus
            count={
              challenge.groupedList[CHALLENGE_STATUS.ON_GOING]?.paging
                ?.totalData!
            }
            isPending={
              (status || CHALLENGE_STATUS.ON_GOING.toString()) ===
              CHALLENGE_STATUS.ON_GOING.toString()
            }
            onPending={() =>
              history.push(`/qc-hypes/?status=${CHALLENGE_STATUS.ON_GOING}`)
            }
            onComplete={() =>
              history.push(`/qc-hypes/?status=${CHALLENGE_STATUS.COMPLETED}`)
            }
          />
        </Col>
        <Col>
          <Select
            value={platform}
            onChange={(e) =>
              history.push(
                `/qc-hypes/?status=${
                  status || CHALLENGE_STATUS.ON_GOING
                }&platformIds=${e}`
              )
            }
            placeholder='Select Platform'
            style={{ minWidth: 150 }}
          >
            {FILTERS.map((f) => {
              return (
                <Option key={f.name} value={f.value}>
                  {f.name}
                </Option>
              )
            })}
          </Select>
        </Col>
      </Row>
      <HypesList
        challenge={challenge}
        status={status!}
        onChangePage={(pn) =>
          GetListChallenge(
            status || CHALLENGE_STATUS.ON_GOING.toString(),
            platform,
            pn
          )
        }
      />
    </Layout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  challenge: state.challenge,
  userChallengeSubmission: state.userChallengeSubmission
})

const mapDispatchToProps = (dispatch: any) => ({
  GetListChallenge: (
    status: string,
    platformIds?: string,
    page?: number,
    limit?: number
  ) => dispatch(Actions.Challenge.GetList(status, platformIds, page, limit)),
  GetDetailChallenge: (id: string) => dispatch(Actions.Challenge.GetDetail(id)),
  GetListSubmission: (
    challengeId: string,
    status: string,
    page?: number,
    sort?: string,
    order?: 'asc' | 'desc',
    createdAtFrom?: number,
    createdAtUntil?: number
  ) =>
    dispatch(
      Actions.UserChallengeSubmission.GetListByChallengeId(
        challengeId,
        status,
        page,
        undefined,
        sort,
        order,
        createdAtFrom,
        createdAtUntil
      )
    ),
  VerifySubmission: (
    challengeId: string,
    submissionId: number,
    data: IUserChallengeSubmission
  ) =>
    dispatch(
      Actions.UserChallengeSubmission.Verify(challengeId, submissionId, data)
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(Hypes)
