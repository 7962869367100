import Base from './base'
import { ICashWithdrawalRequest } from '@cr/general/dist/interfaces'

export default {
  GetList: (status: string, page?: number, limit?: number): Promise<any> => {
    return Base('/account/v1').get(
      `admin/withdrawal/?page=${page || 1}&limit=${
        limit || 25
      }&status=${status}`
    )
  },
  Action: (id: number, data: ICashWithdrawalRequest): Promise<any> => {
    return Base('/account/v1').put(`admin/withdrawal/${id}`, data)
  },
  GetDetail: (id: string): Promise<any> => {
    return Base('/account/v1').get(`admin/withdrawal/${id}`)
  }
}
