/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-empty-function: 0 */
import React, { useEffect } from 'react'
import SERVICE_ID from '@cr/general/dist/constants/service'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Col, Row, Form } from 'antd'
import Layout from '../../components/layout'
import Actions from '../../store/actions'
import AgencyFeeList from './agency-fee-list'
import CommercialDetail from './commercial-detail'
import AgencyDetail from './agency-detail'
import { parse } from 'query-string'
import { parseInt } from 'lodash'
import {
  IAgencyFee,
  IPartnership,
  IPartnershipConfig
} from '@cr/general/dist/interfaces'

const AgencyFee = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  /** Get tab query string*/
  const qs: any = parse(useLocation().search.toString())
  const onFinish = (values: any) => {
    if (qs.id) {
      /**
       * prepare agency data but without id inside
       */
      const agencyData = {
        bankAccount: values?.bankAccount,
        bankName: values?.bankName,
        defaultFeePctg: -1,
        email: values?.email,
        name: values?.name
      } as IAgencyFee

      const partnershipId = parseInt(qs.id, 10)
      const agencyId = parseInt(qs.agency_id, 10)
      dispatch(
        Actions.AgencyFee.UpdatePartnership(qs.id, {
          /** Pass agency data constant but without id we cast it to override mandatory id*/
          agency: agencyData as IAgencyFee,
          name: values?.partnership_name,
          partnershipConfigs: [
            {
              agency: agencyData,
              agencyId: agencyId,
              partnershipFeePctg: values?.partnership_fee_1,
              partnershipId: partnershipId,
              serviceFeePctg: values?.service_fee_1,
              serviceId: SERVICE_ID.DONATION
            },
            {
              agency: agencyData,
              agencyId: agencyId,
              partnershipFeePctg: values?.partnership_fee_2,
              partnershipId: partnershipId,
              serviceFeePctg: values?.service_fee_2,
              serviceId: SERVICE_ID.CHALLENGE
            },
            {
              agency: agencyData,
              agencyId: agencyId,
              partnershipFeePctg: values?.partnership_fee_3,
              partnershipId: partnershipId,
              serviceFeePctg: values?.service_fee_3,
              serviceId: SERVICE_ID.SUBSCRIPTION
            }
          ]
          /**
           * cast as IPartnership to override id dependency for payload partnership update
           */
        } as IPartnership)
      )
    } else {
      /**
       * make condition if we call create or edit action
       */
      dispatch(
        Actions.AgencyFee.Create(
          {
            bankAccount: values?.bankAccount,
            bankName: values?.bankName,
            defaultFeePctg: -1,
            email: values?.email,
            name: values?.name
          } as IAgencyFee,
          (dataResponse) => {
            const { bankAccount, bankName, defaultFeePctg, email, id, name } =
              dataResponse
            const data = {
              name: values.partnership_name,
              agency: {
                id: id,
                bankAccount: bankAccount,
                bankName: bankName,
                defaultFeePctg: defaultFeePctg,
                email: email,
                name: name
              }
            } as IPartnership
            dispatch(
              Actions.AgencyFee.CreatePartnerShip(
                data,
                (dataResponsePartnership) => {
                  const {
                    id,
                    agency: { id: idAgency }
                  } = dataResponsePartnership
                  const dataConfig = [
                    {
                      partnership: {
                        id: id
                      } as IPartnership,
                      agency: {
                        id: idAgency
                      } as IAgencyFee,
                      serviceId: SERVICE_ID.DONATION,
                      partnershipFeePctg: parseFloat(values.partnership_fee_1),
                      serviceFeePctg: parseFloat(values.service_fee_1)
                    } as IPartnershipConfig,
                    {
                      partnership: {
                        id: id
                      } as IPartnership,
                      agency: {
                        id: idAgency
                      } as IAgencyFee,
                      serviceId: SERVICE_ID.CHALLENGE,
                      partnershipFeePctg: parseFloat(values.partnership_fee_2),
                      serviceFeePctg: parseFloat(values.service_fee_2)
                    } as IPartnershipConfig,
                    {
                      partnership: {
                        id: id
                      } as IPartnership,
                      agency: {
                        id: idAgency
                      } as IAgencyFee,
                      serviceId: SERVICE_ID.SUBSCRIPTION,
                      partnershipFeePctg: parseFloat(values.partnership_fee_3),
                      serviceFeePctg: parseFloat(values.service_fee_3)
                    } as IPartnershipConfig
                  ]
                  dispatch(
                    Actions.AgencyFee.CreatePartnerShipConfig(
                      dataConfig,
                      id,
                      () => {
                        clearField()
                        setTimeout(() => {
                          history.push('/agency-fee?tab=view')
                        }, 1000)
                      }
                    )
                  )
                }
              )
            )
          }
        )
      )
    }
  }

  const clearField = (): void => {
    /**Clear the selection*/
    form.setFieldsValue({
      bankAccount: '',
      bankName: '',
      defaultFeePctg: '',
      email: '',
      name: '',
      partnership_name: '',
      partnership_fee_1: '',
      partnership_fee_2: '',
      partnership_fee_3: '',
      service_fee_1: '',
      service_fee_2: '',
      service_fee_3: ''
    })
  }
  useEffect(() => {
    if (qs.action === 'edit') {
      dispatch(
        Actions.AgencyFee.GetDetailPartnership(qs.id, (success) => {
          const serviceOne = success.data.partnershipConfigs.filter(
            (valueServiceOne) => {
              return valueServiceOne.serviceId === SERVICE_ID.DONATION
            }
          )
          const serviceTwo = success.data.partnershipConfigs.filter(
            (valueServiceTwo) => {
              return valueServiceTwo.serviceId === SERVICE_ID.CHALLENGE
            }
          )
          const serviceThree = success.data.partnershipConfigs.filter(
            (valueServiceThree) => {
              return valueServiceThree.serviceId === SERVICE_ID.SUBSCRIPTION
            }
          )

          form.setFieldsValue({
            bankAccount: success.data.agency.bankAccount,
            bankName: success.data.agency.bankName,
            defaultFeePctg: success.data.agency.defaultFeePctg,
            email: success.data.agency.email,
            name: success.data.agency.name,
            partnership_name: success.data.name,
            partnership_fee_1: serviceOne[0].partnershipFeePctg,
            partnership_fee_2: serviceTwo[0].partnershipFeePctg,
            partnership_fee_3: serviceThree[0].partnershipFeePctg,
            service_fee_1: serviceOne[0].serviceFeePctg,
            service_fee_2: serviceTwo[0].serviceFeePctg,
            service_fee_3: serviceThree[0].serviceFeePctg
          })
        })
      )
    } else {
      clearField()
    }
  }, [qs, form])

  return (
    <Layout header='Agency Fee'>
      <Row>
        <Col>
          <Row>
            <Button
              className='mr-1'
              type={qs.tab === 'add' ? 'primary' : 'ghost'}
              onClick={() => history.push(`/agency-fee?tab=add`)}
            >
              {qs.action === 'edit' ? 'Edit Agency Fee' : 'Add Agency Fee'}
            </Button>
            <Button
              className='mr-1'
              type={qs.tab === 'view' ? 'primary' : 'ghost'}
              onClick={() => history.push(`/agency-fee?tab=view`)}
            >
              View Agency List
            </Button>
          </Row>
        </Col>
      </Row>
      {qs.tab === 'view' && <AgencyFeeList />}
      {qs.tab === 'add' && (
        <Form
          form={form}
          layout={'vertical'}
          onFinish={onFinish}
          initialValues={{
            product_type_1: 'Tipping',
            product_type_2: 'Hypes',
            product_type_3: 'Subscription'
          }}
        >
          <AgencyDetail />
          <CommercialDetail />
          <Button
            value={'save'}
            className={'mt-2'}
            type='primary'
            htmlType='submit'
          >
            {qs.action === 'edit' ? 'Update' : 'Add'}
          </Button>
        </Form>
      )}
    </Layout>
  )
}

export default AgencyFee
