import React from 'react'

interface IProps {
  size?: number
  fill?: string
  style?: any
}

const SvgIcon = (props: IProps) => {
  const { fill, size, style } = props

  return (
    <svg
      style={style}
      width={size || '16'}
      height={size || '16'}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.00002 9.33366C8.7364 9.33366 9.33335 8.73671 9.33335 8.00033C9.33335 7.26395 8.7364 6.66699 8.00002 6.66699C7.26364 6.66699 6.66669 7.26395 6.66669 8.00033C6.66669 8.73671 7.26364 9.33366 8.00002 9.33366Z'
        stroke={fill || '#5833E5'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6667 8.00016C12.8887 11.1115 10.6667 12.6668 8.00001 12.6668C5.33334 12.6668 3.11134 11.1115 1.33334 8.00016C3.11134 4.88883 5.33334 3.3335 8.00001 3.3335C10.6667 3.3335 12.8887 4.88883 14.6667 8.00016Z'
        stroke={fill || '#5833E5'}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SvgIcon
