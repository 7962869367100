import React from 'react'
import { Button, Row, TableColumnProps, Tag, Typography } from 'antd'
import General from '@attn/general'

import moment from 'moment'
import { ITransaction } from '@cr/general/dist/interfaces'
import PAYMENT_STATUS from '@cr/general/dist/constants/payment-status'
import _ from 'lodash'
import API_TRANSACTION_TYPE from '../transaction-type'

const { Text } = Typography
const { Numberize } = General.Utils.Format

const TransactionColumn = [
  {
    title: 'Tanggal Transaksi',
    key: 'created_at',
    dataIndex: 'createdAt',
    render: (_d: any, t: ITransaction) => (
      <Text className='text-size-16' key={t.id}>
        {moment.unix(t?.createdAt || 0).format('DD MMM YYYY hh:ss')}
      </Text>
    ),
    sorter: true
  },
  {
    title: 'Receipt ID',
    key: 'receipt_id',
    dataIndex: 'receiptID',
    sorter: true
  },
  {
    title: 'Fans',
    key: 'fansName',
    dataIndex: 'fansName'
  },
  {
    title: 'Creator',
    key: 'creatorName',
    dataIndex: 'creatorName'
  },
  {
    title: 'Total Price',
    key: 'total',
    dataIndex: 'total',
    sorter: true,
    render: (value) => (
      <Text className=''>IDR {Numberize(value || 0, '.')}</Text>
    )
  },
  {
    title: 'Payment Method',
    key: 'payment_method_id',
    dataIndex: 'paymentMethodName',
    sorter: true
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    sorter: true,
    render: (_d, t: ITransaction) => {
      let status = <></>
      switch (t.status) {
        case PAYMENT_STATUS.PENDING:
          status = (
            <Tag className='text-color-primary-main bg-primary-surface border-primary-main text-s-medium'>
              Pending
            </Tag>
          )
          break
        case PAYMENT_STATUS.PAID:
          status = (
            <Tag className='text-color-success-pressed bg-success-surface border-success-main text-s-medium'>
              Success
            </Tag>
          )
          break
        case PAYMENT_STATUS.FAILED:
        case PAYMENT_STATUS.EXPIRED:
          status = (
            <Tag className='text-color-danger-main bg-danger-surface border-danger-main text-s-medium'>
              {_.capitalize(t.status)}
            </Tag>
          )
          break
        default:
          break
      }
      return <Text className='text-size-16 text-height-20'>{status}</Text>
    }
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'action',
    align: 'center',
    render: (_d, t: ITransaction) => {
      return (
        <Row justify='center'>
          <Button
            type='text'
            className='text-color-primary-main'
            href={`/transactions/${
              t.creatorSubscriptionTierId
                ? API_TRANSACTION_TYPE.SUBSCRIPTION
                : API_TRANSACTION_TYPE.DONATION
            }/${t.receiptID}`}
          >
            See Detail
          </Button>
        </Row>
      )
    }
  }
] as Array<TableColumnProps<any>>

export default TransactionColumn
