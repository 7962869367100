/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Modal, ModalProps, Select } from 'antd'
import { FormProps } from 'antd/lib/form'
import React, { useEffect } from 'react'

import { IField } from '../../interfaces/models/form'
import JsonEditor from '../json-editor'

export interface IForm extends FormProps, ModalProps {
  fields: Array<IField>
  formName: string
  onFailed?: (data: any) => void
  initialValues?: any
  visible?: boolean
  onCancel: () => void
  title?: string
  additionalHeader?: React.ReactNode
  additionalElementForm?: React.ReactNode
  okText?: string
  cancelText?: string
  width?: number
  confirmLoading?: boolean
}

const { Option } = Select
const { confirm } = Modal

const DefaultForm = ({
  fields,
  formName,
  onFinish,
  onFailed,
  labelCol,
  labelAlign,
  wrapperCol,
  className,
  initialValues,
  visible,
  title,
  onCancel,
  additionalHeader,
  additionalElementForm,
  okText,
  cancelText,
  width,
  confirmLoading,
  okButtonProps,
  cancelButtonProps
}: IForm) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [initialValues])

  const onFinishForm = (values: any) => {
    confirm({
      title: 'Are you sure you want to proceed this form?',
      onOk: () => onFinish && onFinish(values),
      onCancel: () => null
    })
  }

  const onFinishFailed = (values: any) => {
    onFailed && onFailed(values)
  }

  const renderInput = (f: IField) => {
    switch (f.type) {
      case 'textarea':
        return (
          <Input.TextArea
            bordered={false}
            placeholder={f.placeholder || ''}
            autoSize={{ minRows: 4 }}
            disabled={f.disabled}
          />
        )
      case 'password':
        return (
          <Input.Password
            bordered={false}
            placeholder={f.placeholder || ''}
            disabled={f.disabled}
          />
        )
      case 'select':
        // if value nested object, name: ['gender','id']
        return (
          <Select
            bordered={false}
            placeholder={f.placeholder}
            disabled={f.disabled}
          >
            {f.options?.map((x, key: number) => (
              <Option key={key} value={x.id}>
                {x.name}
              </Option>
            ))}
          </Select>
        )
      case 'json-editor':
        return <JsonEditor mode='code' />
      default:
        return (
          <Input
            bordered={false}
            type={f.type}
            placeholder={f.placeholder || ''}
            disabled={f.disabled}
          />
        )
    }
  }

  const onSubmit = () => {
    form.submit()
  }

  return (
    <Modal
      title={title}
      visible={visible}
      className={`form-modal ${className || ''}`}
      onCancel={onCancel}
      onOk={onSubmit}
      cancelText={cancelText}
      okText={okText}
      width={width}
      confirmLoading={confirmLoading}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
    >
      {additionalHeader}
      <Form
        form={form}
        name={formName}
        onFinish={onFinishForm}
        onFinishFailed={onFinishFailed}
        labelAlign={labelAlign || 'left'}
        labelCol={labelCol || { span: 24 }}
        wrapperCol={wrapperCol || { span: 24 }}
        initialValues={initialValues}
        requiredMark={false}
      >
        {fields.map((f: IField, key: number) => {
          return (
            <div
              key={key.toString()}
              className={`${
                f.type === 'json-editor' ? 'field-json' : 'cr-form-item'
              }`}
            >
              <Form.Item className='form-item' {...f}>
                {renderInput(f)}
              </Form.Item>
            </div>
          )
        })}
        {additionalElementForm && (
          <Form.Item>{additionalElementForm}</Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default DefaultForm
