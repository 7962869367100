import { Row, Typography, Button, Col } from 'antd'
import * as React from 'react'
import General from '@attn/general'
import { IBalanceLog } from '@cr/general/dist/interfaces'
import _ from 'lodash'
import SERVICE_ID from '@cr/general/dist/constants/service'
import moment from 'moment'

import SERVICE_ID_MAP from '../service'
import ENV from '../../utils/environment'

const { Text } = Typography
const { Numberize } = General.Utils.Format

const UserBalanceLogTableColumns: Array<any> = [
  {
    title: 'Date',
    key: 'date',
    render: (record: IBalanceLog) => {
      return (
        <Row key={record.id}>
          <Text className='text-size-16 text-height-20 '>
            {moment.unix(record?.createdAt || 0).format('DD MMM, YYYY HH:mm')}
          </Text>
        </Row>
      )
    }
  },
  {
    title: 'Nominal',
    key: 'nominal',
    render: (record: IBalanceLog) => {
      return (
        <Row key={record.id}>
          <Text
            className={`text-size-16 text-height-20 text-weight-medium ${
              record.amountRequested < 0 ? 'text-color-status-red' : ''
            }`}
          >
            {`${record.amountRequested < 0 ? '-' : ''}${Numberize(
              Math.abs(record.amountRequested),
              '.',
              'Rp '
            )}`}
          </Text>
        </Row>
      )
    }
  },
  {
    title: 'Service',
    key: 'service',
    render: (record: IBalanceLog) => {
      return (
        <Col key={record.id}>
          <Row>
            <Text className='text-size-16 text-height-20'>
              {record.serviceId === 0 && record.amountRequested < 0
                ? 'Others'
                : SERVICE_ID_MAP[record.serviceId]?.label}
            </Text>
          </Row>
          {record.challengeSubmission && (
            <Row>
              <Text className='text-color-neutral-70'>
                {record.challengeSubmission?.challenge?.name}
              </Text>
            </Row>
          )}
        </Col>
      )
    }
  },
  {
    title: 'Reason',
    key: 'reason',
    render: (record: IBalanceLog) => {
      return (
        <Row key={record.id}>
          <Text className='text-size-16 text-height-20'>
            {_.capitalize(record.reason)}
          </Text>
        </Row>
      )
    }
  },
  {
    title: 'Detail',
    key: 'action',
    render: (record: IBalanceLog) => {
      const renderDetail = () => {
        switch (record.serviceId) {
          case SERVICE_ID.CHALLENGE:
            return (
              <Button
                type='link'
                target='_blank'
                href={`${ENV.CR_FRAGMENT_URL}hypes/${record.challengeSubmission?.challenge?.slug}`}
              >
                <Text
                  underline
                  className='text-size-16 text-weight-medium text-color-primary-purple'
                >
                  Detail
                </Text>
              </Button>
            )
          case SERVICE_ID.SUBSCRIPTION:
            return (
              <Row>
                <Col span={24}>
                  <Text className='text-color-second-dark-purple text-size-12'>
                    Subscription Name:
                  </Text>
                </Col>
                <Col span={24}>
                  <Text>
                    {record.userSubscription?.subscriptionTier?.content?.name}
                  </Text>
                </Col>
              </Row>
            )
          case SERVICE_ID.DONATION:
            return (
              <Row>
                <Col span={24}>
                  <Text className='text-color-second-dark-purple text-size-12'>
                    Sender Name:
                  </Text>
                </Col>
                <Col span={24}>
                  <Text>
                    {record.donation?.isAnonymous
                      ? 'Anonymous'
                      : record.donation?.senderName}
                  </Text>
                </Col>
                <Col span={24}>
                  <Text className='text-color-second-dark-purple text-size-12'>
                    Sender Email:
                  </Text>
                </Col>
                <Col span={24}>
                  <Text>{record.donation?.senderEmail}</Text>
                </Col>
              </Row>
            )
          case SERVICE_ID.UNDEFINED:
            if (record.amountRequested < 0)
              return (
                <Row>
                  <Col span={24}>
                    <Text className='text-color-second-dark-purple text-size-12'>
                      Amount Before:
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>{Numberize(record.amountBefore, '.', 'Rp ')}</Text>
                  </Col>
                  <Col span={24}>
                    <Text className='text-color-second-dark-purple text-size-12'>
                      Amount After:
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text className='text-color-primary-purple'>
                      {Numberize(record.amountAfter, '.', 'Rp ')}
                    </Text>
                  </Col>
                </Row>
              )
            return <></>
          default:
            ;<></>
            break
        }
      }
      return <Row key={record.id}>{renderDetail()}</Row>
    }
  }
]

export default UserBalanceLogTableColumns
