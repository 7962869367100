/* eslint-disable react-hooks/exhaustive-deps */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { IUser } from '@cr/general/dist/interfaces'
import VERIFY_STATUS from '@cr/general/dist/constants/verify-status'
import { IIdCardInfo } from '@cr/general/dist/interfaces/user'
import {
  List,
  Row,
  Typography,
  Col,
  Button,
  Avatar,
  Modal,
  Image,
  Spin,
  Divider
} from 'antd'
import React, { useState } from 'react'
import IUserState from '../../interfaces/states/user'
import Form from '../../components/form'
import { IField } from '../../interfaces/models/form'
import { USER_MODE } from '../../constants/user-mode'
import moment from 'moment'
import AUTH_PROVIDER_TYPE from '@cr/general/dist/constants/auth-provider-type'
const { Text } = Typography

interface IProps {
  user: IUserState
  onAction: (id: number, data: IIdCardInfo) => void
  status: string
  onChangePage: (page: number) => void
}

const fields = [
  {
    name: 'reason',
    type: 'textarea',
    label: 'Reason',
    rules: [
      { required: true, message: 'Reason is required' },
      {
        transform: (val) => val && val.trim(),
        min: 10,
        message: 'Minimum 10 character'
      },
      {
        transform: (val) => val && val.trim(),
        max: 255,
        message: 'Maximum 255 character'
      }
    ]
  }
] as Array<IField>

const VERIFICATION_STATUS = (status) => {
  switch (status) {
    case VERIFY_STATUS.VERIFIED:
      return (
        <Text className='text-m-regular text-color-success-pressed'>
          Verified
        </Text>
      )
    case VERIFY_STATUS.PENDING:
      return <Text className='text-m-regular'>Requesting</Text>
    case VERIFY_STATUS.DECLINED:
      return (
        <Text className='text-m-regular text-color-danger-hover'>
          Not Verified
        </Text>
      )
    default:
      return <Text className='text-m-regular'>-</Text>
  }
}

const IdCardVerification = (props: IProps) => {
  const { user, onAction, status, onChangePage } = props
  const [visible, setVisible] = useState<boolean>(false)
  const [data, setData] = useState<IUser | undefined>(undefined)
  const [isPreview, setIsPreview] = useState<boolean>(false)

  const userGrouped =
    user.groupedList[`${USER_MODE.ID_VERIF}|${status || VERIFY_STATUS.PENDING}`]

  const onApprove = (id) => {
    Modal.confirm({
      icon: <></>,
      title: `Confirm Approval`,
      content: `Are you sure to approve this id card application`,
      okText: 'Approve',
      onOk: () => {
        onAction(id, {
          status: VERIFY_STATUS.VERIFIED,
          reason: ''
        } as IIdCardInfo)
        setVisible(false)
      },
      okButtonProps: {
        className: 'bg-primary-main'
      },
      cancelButtonProps: {
        className:
          'bg-primary-surface text-color-primary-main border-primary-surface'
      }
    })
  }

  return (
    <>
      <List
        pagination={{
          hideOnSinglePage: true,
          showLessItems: true,
          current:
            user.groupedList[`${USER_MODE.ID_VERIF}|${status}`]?.paging
              ?.currentPage,
          pageSize:
            user.groupedList[`${USER_MODE.ID_VERIF}|${status}`]?.paging
              ?.limit || 12,
          total:
            user.groupedList[`${USER_MODE.ID_VERIF}|${status}`]?.paging
              ?.totalData,
          onChange: onChangePage
        }}
        grid={{
          column: 5,
          lg: 3,
          xl: 4,
          gutter: 16
        }}
        dataSource={userGrouped?.list || []}
        loading={userGrouped?.requesting}
        renderItem={(x) => {
          const USER_INFO = [
            {
              label: 'Email',
              value: VERIFICATION_STATUS(
                x.userAuthProviders?.find(
                  (uap) => uap.authProviderId === AUTH_PROVIDER_TYPE.EMAIL
                )?.isVerified
                  ? VERIFY_STATUS.VERIFIED
                  : VERIFY_STATUS.DECLINED
              )
            },
            {
              label: 'Bank Account',
              value: VERIFICATION_STATUS(x?.bankAccountInfo?.status)
            }
          ]
          return (
            <List.Item key={x.id}>
              <Row className='box-shadow border-radius-8'>
                <Col span={24}>
                  <Row className='mb-1'>
                    {!userGrouped?.requesting ? (
                      <Image
                        width={'100%'}
                        className='border-radius-4 object-fit-contain use-pointer'
                        src={x.idCardInfo?.images}
                        height={224}
                        onClick={() => {
                          setIsPreview(true)
                          setData(x)
                        }}
                        preview={false}
                      />
                    ) : (
                      <Row
                        justify='center'
                        style={{ height: 215, width: '100%' }}
                        align='middle'
                      >
                        <Spin />
                      </Row>
                    )}
                  </Row>
                  <Col className='p-1'>
                    <Row
                      gutter={8}
                      className='mh-0'
                      align='middle'
                      wrap={false}
                    >
                      <Col>
                        <Avatar src={x.images?.profilePicture} />
                      </Col>
                      <Col flex='auto'>
                        <Typography className='text-size-16 text-weight-medium line-clamp-1'>
                          {x.fullName}
                        </Typography>
                        <Typography className='text-s-medium text-color-neutral-70'>
                          @{x.username}
                        </Typography>
                      </Col>
                    </Row>
                    <Divider className='mv-1' />
                    <Row align='middle' justify='space-between'>
                      <Text className='text-m-regular text-color-neutral-70'>
                        Requested At
                      </Text>
                      <Text className='text-m-regular'>
                        {moment
                          .unix(x.idCardInfo?.submittedAt || 0)
                          .format('DD MMM YY, HH:mm')}
                      </Text>
                    </Row>
                    <Divider className='mv-1' />
                    {USER_INFO.map((ui) => {
                      return (
                        <Row
                          key={ui.label}
                          justify='space-between'
                          align='middle'
                        >
                          <Col>
                            <Text className='text-color-neutral-70 text-m-regular'>
                              {ui.label}
                            </Text>
                          </Col>
                          <Col>
                            <Row align='middle'>{ui.value}</Row>
                          </Col>
                        </Row>
                      )
                    })}
                    <Divider className='mv-1' />
                    {x.idCardInfo?.status === VERIFY_STATUS.PENDING ? (
                      <Row gutter={[8, 8]}>
                        <Col span={12}>
                          <Button
                            block
                            type='text'
                            icon={<CloseOutlined />}
                            className='text-center text-color-gray text-weight-medium text-color-danger-hover border-danger-hover'
                            onClick={() => {
                              setVisible(true)
                              setData(x)
                            }}
                          >
                            Reject
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Button
                            block
                            type='text'
                            icon={<CheckOutlined />}
                            className='text-center text-color-primary-main text-weight-medium border-primary-main'
                            onClick={() => onApprove(x.id)}
                          >
                            Approve
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <>
                        {VERIFY_STATUS.VERIFIED === x.idCardInfo?.status ? (
                          <Row align='middle' justify='center'>
                            <CheckOutlined className='text-color-primary-main mr-1' />
                            <Text className='text-color-primary-main text-m-bold'>
                              Approved
                            </Text>
                          </Row>
                        ) : (
                          <Row align='middle' justify='center'>
                            <CloseOutlined className='text-color-danger-hover mr-1' />
                            <Text className='text-color-danger-hover text-m-bold'>
                              Marked as Invalid
                            </Text>
                          </Row>
                        )}
                      </>
                    )}
                  </Col>
                </Col>
              </Row>
            </List.Item>
          )
        }}
      />
      <Form
        additionalHeader={
          <>
            <Row className='mb-3'>
              <Text className='text-weight-bold text-size-20'>
                Why this request is not valid?
              </Text>
            </Row>
            <Row className='mb-3'>
              <Text className='text-size-14'>
                If the ID Card is invalid. please fill the form below, so the
                application can review and fix their application data.
              </Text>
            </Row>
          </>
        }
        formName='form-id-card-reject'
        fields={fields}
        initialValues={{}}
        visible={visible}
        onCancel={() => setVisible(false)}
        okText='Submit'
        onFinish={(values) => {
          onAction(data?.id!, {
            reason: values?.reason,
            status: VERIFY_STATUS.DECLINED
          } as IIdCardInfo)
          setVisible(false)
        }}
        okButtonProps={{
          className: 'bg-primary-main'
        }}
        cancelButtonProps={{
          className:
            'bg-primary-surface text-color-primary-main border-primary-surface'
        }}
      />
      <Modal
        title={'Preview ID Card'}
        visible={isPreview}
        onCancel={() => setIsPreview(false)}
        okText='Approve'
        cancelText='Reject'
        okButtonProps={{
          type: 'ghost',
          className: 'text-color-primary-main border-primary-main',
          icon: <CheckOutlined />
        }}
        cancelButtonProps={{
          type: 'ghost',
          className: 'text-color-danger-hover border-danger-hover',
          icon: <CloseOutlined />,
          onClick: () => setVisible(true)
        }}
        onOk={() => onApprove(data?.id)}
        footer={
          data?.idCardInfo?.status !== VERIFY_STATUS.PENDING ? null : undefined
        }
      >
        <Row justify='center'>
          <Image src={data?.idCardInfo?.images} />
        </Row>
      </Modal>
    </>
  )
}

export default IdCardVerification
