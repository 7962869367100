import { stringify } from 'query-string'
import API_TRANSACTION_TYPE from '../../constants/transaction-type'
import { IFilters } from '../../pages/transactions'
import Base from './base'

export default {
  GetList: (
    type: API_TRANSACTION_TYPE,
    page?: number,
    limit?: number,
    filter?: IFilters
  ): Promise<any> => {
    return Base('/donation/v1').get(
      `admin/transactions/${type}/?${page ? `&page=${page}` : ''}${
        limit ? `&limit=${limit}` : ''
      }${filter ? `&${stringify(filter)}` : ''}`
    )
  },
  GetCountStatus: (
    type?: API_TRANSACTION_TYPE,
    filter?: IFilters
  ): Promise<any> => {
    return Base('/donation/v1').get(
      `admin/transactions/${
        type || API_TRANSACTION_TYPE.SUBSCRIPTION
      }/count/status/?${filter ? `${stringify(filter)}` : ''}`
    )
  },
  GetDetail: (type: API_TRANSACTION_TYPE, receiptId: string): Promise<any> => {
    return Base('/donation/v1').get(`admin/transactions/${type}/${receiptId}`)
  }
}
